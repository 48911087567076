import { useBreakpointValue } from '@chakra-ui/react';

import DesktopUltraWideLayout from '@/ui/layout/DesktopUltraWideLayout';
import { DesktopWideLayout } from '@/ui/layout/DesktopWideLayout';
import { MobileLayout } from '@/ui/layout/MobileLayout';

export default function useBreakpointLayout() {
  const Wrapper = useBreakpointValue({
    base: MobileLayout,
    md: DesktopWideLayout,
    xl: DesktopUltraWideLayout,
  }, "xl");

  return Wrapper;
}
