/* eslint-disable no-param-reassign */
/* eslint-disable import/order */
// disabling rule due to simple-import-sort conflict

import {
  createAction,
  createAsyncThunk,
  createReducer,
  createSelector,
} from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import { sanitize } from 'isomorphic-dompurify';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import type { RootState } from '@/reducers';
import type UpcomingBooking from '@/types/UpcomingBooking';
import { authorizedApi } from '@/utils/apiUtils';
import { User } from '@/types/UpcomingBooking';

export interface UpcomingBookingState {
  areBookingsLoading: boolean;
  bookings: UpcomingBooking[];
  currentBookingId: number | undefined;
  currentPage: number;
  hasLoadedFirstTime?: boolean;
  isCancellationModalOpen: boolean;
  isCancellingBooking: boolean;
  isDetailsModalOpen: boolean;
  nextPageUrl: string | null;
  optionalCancellationMessage: string;
  previousPageUrl: string | null;
}

const initialState: UpcomingBookingState = {
  areBookingsLoading: false,
  currentBookingId: undefined,
  currentPage: 0,
  bookings: [],
  hasLoadedFirstTime: false,
  isCancellationModalOpen: false,
  isCancellingBooking: false,
  isDetailsModalOpen: false,
  nextPageUrl: null,
  optionalCancellationMessage: '',
  previousPageUrl: null,
};

// CONSTANTS

const firstPageIndex = 1;
const entriesPerPage = 999;

// ACTIONS

export const openCancellationModal = createAction(
  'upcomingBookings/openCancellationModal',
);
export const closeCancellationModal = createAction(
  'upcomingBookings/closeCancellationModal',
);
export const openDetailsModal = createAction(
  'upcomingBookings/openDetailsModal',
);
export const closeDetailsModal = createAction(
  'upcomingBookings/closeDetailsModal',
);
export const setCurrentBookingId = createAction<
  UpcomingBookingState['currentBookingId']
>('upcomingBookings/setCurrentBooking');
export const resetCurrentBookingId = createAction(
  'upcomingBookings/resetCurrentBooking',
);
export const setOptionalCancellationMessage = createAction<
  UpcomingBookingState['optionalCancellationMessage']
>('upcomingBookings/setOptionalCancellationMessage');
export const resetOptionalCancellationMessage = createAction(
  'upcomingBookings/resetOptionalCancellationMessage',
);
export const removeBookingWithCatchupId = createAction<{
  catchup_id?: UpcomingBooking['catchup_id'];
  event_id?: UpcomingBooking['event_id'];
  attendee_id?: User['id'];
}>('upcomingBookings/removeBookingWithCatchupId');
export const setBookings = createAction<{
  response: {
    success: UpcomingBooking[];
    meta?: { nextPageUrl?: string | null; previousPageUrl?: string | null };
  };
}>('upcomingBookings/setBookings');
export const prepareBookingsFetch = createAction(
  'upcomingBookings/prepareBookingsFetch',
);

// ASYNC THUNKS

export const fetchUpcomingBookings = createAsyncThunk(
  'upcomingBookings/fetchUpcomingBookings',
  async (parameters: { link: string | undefined } | undefined) => {
    const { link } = parameters ?? {};

    const userId = (authorizedApi.defaults.headers?.id as string) ?? 0;

    const { data } = await authorizedApi.get<{
      success: UpcomingBooking[];
      meta?: { nextPageUrl?: string | null; previousPageUrl?: string | null };
    }>(link ?? `/users/${userId}/catchups/confirmed?page=${firstPageIndex}`);

    return {
      bookings: data.success,
      nextPageUrl: data.meta?.nextPageUrl,
      previousPageUrl: data.meta?.previousPageUrl,
    };
  },
);

export const cancelUpcomingBooking = createAsyncThunk<
  boolean,
  Pick<
    UpcomingBookingState,
    'currentBookingId' | 'optionalCancellationMessage'
  > & { currentBookingEventId: UpcomingBooking['event_id'] },
  {
    rejectValue: AxiosError | Error;
  }
>(
  'upcomingBookings/cancelUpcomingBooking',
  async (
    { currentBookingId, optionalCancellationMessage, currentBookingEventId },
    thunkApi,
  ) => {
    if (typeof currentBookingId === 'undefined') return false;

    try {
      const { data } = await authorizedApi.delete<{ success: boolean }>(
        `/catchup/delete/${currentBookingId}/${currentBookingEventId}`,
        {
          data: {
            message: sanitize(optionalCancellationMessage),
          },
        },
      );

      return data?.success || false;
    } catch (error) {
      return thunkApi.rejectWithValue(error as AxiosError);
    }
  },
);

// REDUCER

export const upcomingBookingsReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(openCancellationModal, (self) => {
        self.isCancellationModalOpen = true;
      })
      .addCase(closeCancellationModal, (self) => {
        self.isCancellationModalOpen = false;
      })
      .addCase(openDetailsModal, (self) => {
        self.isDetailsModalOpen = true;
      })
      .addCase(closeDetailsModal, (self) => {
        self.isDetailsModalOpen = false;
      })
      .addCase(setCurrentBookingId, (self, action) => {
        self.currentBookingId = action.payload;
      })
      .addCase(resetCurrentBookingId, (self) => {
        self.currentBookingId = undefined;
      })

      .addCase(setOptionalCancellationMessage, (self, action) => {
        self.optionalCancellationMessage = action.payload;
      })
      .addCase(resetOptionalCancellationMessage, (self) => {
        self.optionalCancellationMessage = '';
      })
      .addCase(fetchUpcomingBookings.pending, (self, action) => {
        let newCurrentPage;

        try {
          const linkUrl = new URL(action.meta.arg?.link ?? '');

          newCurrentPage = Number.parseInt(
            linkUrl.searchParams.get('page') as string,
            10,
          );
        } catch {
          newCurrentPage = firstPageIndex;
        }

        newCurrentPage = Number.isNaN(newCurrentPage)
          ? firstPageIndex
          : newCurrentPage;

        newCurrentPage = Math.max(0, newCurrentPage - 1);

        self.areBookingsLoading = true;
        self.currentPage = newCurrentPage;
        self.nextPageUrl = null;
        self.previousPageUrl = null;
      })
      .addCase(fetchUpcomingBookings.fulfilled, (self, action) => {
        self.areBookingsLoading = false;
        self.bookings = action.payload.bookings;
        self.nextPageUrl = action.payload.nextPageUrl ?? null;
        self.previousPageUrl = action.payload.previousPageUrl ?? null;
      })
      .addCase(fetchUpcomingBookings.rejected, (self) => {
        self.areBookingsLoading = false;
        self.bookings = [];
        self.nextPageUrl = null;
        self.previousPageUrl = null;
      })
      .addCase(cancelUpcomingBooking.pending, (self) => {
        self.isCancellingBooking = true;
      })
      .addCase(cancelUpcomingBooking.fulfilled, (self, action) => {
        self.isCancellingBooking = false;

        const cancelledBookingIndex = self.bookings.findIndex(
          ({ catchup_id }) => catchup_id === action.meta.arg.currentBookingId,
        );

        if (cancelledBookingIndex >= 0) {
          self.bookings.splice(cancelledBookingIndex, 1);
        }
      })
      .addCase(cancelUpcomingBooking.rejected, (self) => {
        self.isCancellingBooking = false;
      })
      .addCase(removeBookingWithCatchupId, (self, action) => {
        if (action.payload.attendee_id) {
          const modifiedBookingIndex = self.bookings.findIndex(
            (booking) => booking.catchup_id === action.payload.catchup_id,
          );

          if (modifiedBookingIndex < 0) {
            return;
          }

          const attendeeIndex = self.bookings[
            modifiedBookingIndex
          ].attending.findIndex(
            (attendee) =>
              String(attendee.id) === String(action.payload.attendee_id),
          );

          if (attendeeIndex < 0) {
            return;
          }

          self.bookings[modifiedBookingIndex].attending.splice(
            attendeeIndex,
            1,
          );

          if (self.bookings[modifiedBookingIndex].attending.length === 0) {
            self.bookings.splice(modifiedBookingIndex, 1);
          }
        } else {
          const removedBookingIndex = self.bookings.findIndex(
            (booking) => booking.catchup_id === action.payload.catchup_id,
          );

          if (removedBookingIndex >= 0) {
            self.bookings.splice(removedBookingIndex, 1);
          }
        }
      })
      .addCase(prepareBookingsFetch, (self) => {
        if (!self.hasLoadedFirstTime) {
          self.hasLoadedFirstTime = true;
          self.areBookingsLoading = true;
          self.nextPageUrl = null;
          self.previousPageUrl = null;
        }
      })
      .addCase(setBookings, (self, action) => {
        self.areBookingsLoading = false;
        self.bookings = action.payload.response.success;
        self.nextPageUrl = action.payload.response.meta?.nextPageUrl ?? null;
        self.previousPageUrl =
          action.payload.response.meta?.previousPageUrl ?? null;
      });
  },
);

// SELECTORS

export const useBookings = () =>
  useSelector((state: RootState) => state.bookings.upcoming.bookings);

export const useAreBookingsLoading = () =>
  useSelector((state: RootState) => state.bookings.upcoming.areBookingsLoading);

export const useIsDetailsModalOpen = () =>
  useSelector((state: RootState) => state.bookings.upcoming.isDetailsModalOpen);

export const useIsCancellationModalOpen = () =>
  useSelector(
    (state: RootState) => state.bookings.upcoming.isCancellationModalOpen,
  );

export const usePagedBookingsGroupedByDate = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.upcoming.bookings,
      (state: RootState) => state.bookings.upcoming.currentPage,
      (bookings, currentPage) =>
        bookings
          .slice(
            currentPage * entriesPerPage,
            currentPage * entriesPerPage + entriesPerPage,
          )
          .reduce((bookingsMap, booking) => {
            const [firstEvent] = booking.events ?? [];

            if (!firstEvent) {
              return bookingsMap;
            }

            const date = DateTime.fromSeconds(firstEvent.from).setZone(
              booking.initiator?.tzIdentifier,
            );
            const isoDateKey = date.toISODate();

            return bookingsMap.set(isoDateKey, [
              ...(bookingsMap.get(isoDateKey) ?? []),
              booking,
            ]);
          }, new Map<string, UpcomingBooking[]>()),
    ),
  );

export const useCurrentPageData = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.upcoming.bookings,
      (state: RootState) => state.bookings.upcoming.currentPage,
      (bookings, currentPage) => {
        const currentPageLength = bookings.slice(
          currentPage * entriesPerPage,
          currentPage * entriesPerPage + entriesPerPage,
        ).length;

        const start = currentPage * entriesPerPage + 1;
        const end = start + currentPageLength - 1;
        const numPages = bookings.length;

        return {
          start,
          end,
          numPages,
        };
      },
    ),
  );

export const useCurrentBookingId = () =>
  useSelector((state: RootState) => state.bookings.upcoming.currentBookingId);

export const useCurrentBooking = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.upcoming.bookings,
      (state: RootState) => state.bookings.upcoming.currentBookingId,
      (bookings, currentBookingId) =>
        bookings.find(({ catchup_id }) => catchup_id === currentBookingId),
    ),
  );

export const useCurrentBookingTimeSlot = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.upcoming.bookings,
      (state: RootState) => state.bookings.upcoming.currentBookingId,
      (bookings, currentBookingId) => {
        if (typeof currentBookingId === 'undefined') return undefined;

        const currentBooking = bookings.find(
          ({ catchup_id }) => catchup_id === currentBookingId,
        );

        if (!currentBooking) return undefined;

        const [firstEvent] = currentBooking.events ?? [];

        if (!firstEvent) return undefined;

        return {
          from: firstEvent.from,
          to: firstEvent.to,
        };
      },
    ),
  );

export const useCurrentBookingDuration = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.upcoming.bookings,
      (state: RootState) => state.bookings.upcoming.currentBookingId,
      (bookings, currentBookingId) => {
        if (typeof currentBookingId === 'undefined') return 0;

        const currentBooking = bookings.find(
          ({ catchup_id }) => catchup_id === currentBookingId,
        );

        if (!currentBooking) return 0;

        const [firstEvent] = currentBooking.events ?? [];

        const eventDuration = (firstEvent?.to ?? 0) - (firstEvent?.from ?? 0);

        return Number.isNaN(eventDuration) ? 0 : eventDuration;
      },
    ),
  );

export const useCurrentBookingLocation = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.upcoming.bookings,
      (state: RootState) => state.bookings.upcoming.currentBookingId,
      (bookings, currentBookingId) => {
        if (typeof currentBookingId === 'undefined') return '';

        const currentBooking = bookings.find(
          ({ catchup_id }) => catchup_id === currentBookingId,
        );

        if (!currentBooking) return '';

        const [locationHeader, ...locationDetails] =
          currentBooking.catchup_location_name?.split(/:\s*/) ?? [];

        if (/^Custom|^Location/.test(locationHeader)) {
          return currentBooking.catchup_location_name;
        }

        return locationDetails.length > 0
          ? locationDetails.join(': ')
          : locationHeader;
      },
    ),
  );

export const useCurrentBookingEventsGroupedByDate = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.upcoming.bookings,
      (state: RootState) => state.bookings.upcoming.currentBookingId,
      (bookings, currentBookingId) => {
        const initialMap = new Map<string, UpcomingBooking['events']>();

        if (typeof currentBookingId === 'undefined') return initialMap;

        const currentBooking = bookings.find(
          ({ catchup_id }) => catchup_id === currentBookingId,
        );

        if (!currentBooking || !currentBooking?.events) return initialMap;

        return currentBooking.events.reduce((eventMap, event) => {
          const date = DateTime.fromSeconds(event.from).setZone(
            currentBooking.initiator?.tzIdentifier,
          );
          const isoDateKey = date.toISODate();

          return eventMap.set(isoDateKey, [
            ...(eventMap.get(isoDateKey) ?? []),
            event,
          ]);
        }, initialMap);
      },
    ),
  );

export const useOptionalCancellationMessage = () =>
  useSelector(
    (state: RootState) => state.bookings.upcoming.optionalCancellationMessage,
  );

export const useIsCancellingBooking = () =>
  useSelector(
    (state: RootState) => state.bookings.upcoming.isCancellingBooking,
  );

export const useNextPageUrl = () =>
  useSelector((state: RootState) => state.bookings.upcoming.nextPageUrl);
export const usePreviousPageUrl = () =>
  useSelector((state: RootState) => state.bookings.upcoming.previousPageUrl);
