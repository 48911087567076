import { Avatar, Box, Divider, Flex, Grid, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useToggle from 'react-use/esm/useToggle';

import ExpandIcon from '@/components/atoms/ExpandIcon';
import HideIcon from '@/components/atoms/HideIcon';
import LogoIcon from '@/components/atoms/LogoIcon';
import useMobileMenuItems from '@/components/organisms/MobileMenuItems';
import MobileNavigation from '@/components/organisms/MobileNavigation';
import { useProfileStore } from '@/stores/RootStore';

function MobileHeader() {
  const { t } = useTranslation();

  const profileStore = useProfileStore();
  const [isMenuVisible, toggleMenuVisibility] = useToggle(false);
  const { name, profilePic_url: image } = profileStore.profile ?? {};
  const location = useLocation();

  const menuItems = useMobileMenuItems();

  return (
    <Box h="auto" w="100%" shadow="mobileBottom" maxH="100vh" overflowY="auto">
      <Grid
        minH="120px"
        alignItems="center"
        templateColumns="auto 1fr auto"
        py="1.875rem"
        px={6}
      >
        <Avatar
          bg="gray.100"
          size="md"
          name={name ?? undefined}
          src={image || ''}
          alignSelf="left"
          mr="1rem"
        />
        <Flex alignItems="center" onClick={toggleMenuVisibility}>
          <Text variant="semibold" color="gray.600" fontSize="xl" pr={2}>
            <Box display="inline-block" pr={2}>
              {menuItems.find((item) => item.path === location.pathname)
                ?.title ?? t('catchapp_bookings')}
            </Box>
            <Box display="inline-block">
              {isMenuVisible ? <HideIcon /> : <ExpandIcon />}
            </Box>
          </Text>
        </Flex>
        <Flex alignItems="center">
          <LogoIcon width="1.875rem" height="1.875rem" />
        </Flex>
      </Grid>
      <Divider
        display={isMenuVisible ? 'block' : 'none'}
        borderColor="gray.200"
        mx="1.875rem"
        w="auto"
      />
      <Flex display={isMenuVisible ? 'block' : 'none'} pb="1.875rem">
        <MobileNavigation onClick={toggleMenuVisibility} />
      </Flex>
    </Box>
  );
}

export default observer(MobileHeader);
