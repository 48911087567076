import { types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';

import { autoFlow } from '@/stores/storeUtils';
import { NewUserSeat } from '@/stores/UsersStore/UsersStore';
import i18n from '@/utils/i18n';
import { paymentMethod } from '@/utils/stripe/paymentMethod';

const AddUsersModalStore = types
  .model('AddUsersModalStore', {
    isOpen: types.boolean,
    isSubmitting: types.boolean,
    newSeats: types.array(NewUserSeat),
    error: types.model({
      message: types.string,
    }),
  })
  .actions((self) =>
    autoFlow({
      toggle() {
        self.isOpen = !self.isOpen;
      },

      open() {
        self.isOpen = true;
      },

      close() {
        self.isOpen = false;
      },

      *onSubmit({ elements, stripe, usersStore, profile }) {
        self.isSubmitting = true;
        self.error.message = '';

        if (!stripe || !elements) {
          self.isSubmitting = false;

          throw new Error(i18n.t('failure_stripe_not_loaded'));
        }

        const response = yield paymentMethod({
          stripe,
          elements,
          onError: self.setError,
        });

        if (!response?.success) {
          self.isSubmitting = false;

          throw new Error(
            `Payment method error${
              response?.error?.message?.message
                ? `: ${response?.error?.message?.message}`
                : ''
            }`,
          );
        }

        if (self.error.message) {
          self.isSubmitting = false;

          throw new Error(`Error: ${self.error.message}`);
        }

        yield usersStore.addUsersSeats({
          profile,
          newUsers: self.newSeats,
        });

        self.isSubmitting = false;
      },

      setNewSeats(users) {
        self.newSeats = users.map(({ email }) => ({
          id: nanoid(),
          email,
        }));
      },

      setError(message) {
        self.error.message = message;
      },
    }),
  );

const addUsersModalStore = AddUsersModalStore.create({
  isOpen: false,
  isSubmitting: false,
  newSeats: [],
  error: {
    message: '',
  },
});

export default addUsersModalStore;
