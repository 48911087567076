export const USER_SEAT_STATUS = {
  ACTIVE: 'Active',
  IDLE: 'Idle',
  PENDING: 'Pending',
};

export const USER_SEAT_TYPE = {
  USER: 'User',
  OWNER: 'Owner',
  ADMIN: 'Admin',
};
