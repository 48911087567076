import './CardSection.css';

import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { CardElement } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import paymentStore from '@/stores/PaymentStore';
import { useProfileStore } from '@/stores/RootStore';

import CardEditModal from '../pages/Settings/organisms/CardEditModal';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: '1.429',
      color: '#32325d',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

export const CardSection = observer(({ scrollToBottom }) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const profileStore = useProfileStore();

  return (
    <>
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <CardEditModal onClose={onClose} isOpen={isOpen} />
        <Text textAlign="left">
          {' '}
          {profileStore.profile?.paymentMethod ? (
            <b>
              {t('last_four_digits_message_1')}{' '}
              {profileStore.profile?.paymentMethod?.last4}{' '}
              {t('last_four_digits_message_3')}
            </b>
          ) : (
            t('enter_your_card_details')
          )}
        </Text>
        {profileStore.profile?.paymentMethod && (
          <Text
            color="orange.500"
            textAlign="right"
            fontWeight="bold"
            _hover={{
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={onOpen}
            textTransform="uppercase"
          >
            {t('edit_card_details')}
          </Text>
        )}
      </Box>
      {!profileStore.profile?.paymentMethod && (
        <CardElement
          options={CARD_ELEMENT_OPTIONS}
          onChange={(element) => {
            paymentStore.completeForm(element.complete);
          }}
          onFocus={scrollToBottom}
        />
      )}
    </>
  );
});

CardSection.propTypes = {
  scrollToBottom: func,
};

CardSection.defaultProps = {
  scrollToBottom: () => null,
};
