import { lazy, PropsWithChildren } from 'react';

import ClientBubbles from '@/components/AuthFlow/ClientBubbles';
import WelcomeBackBubble from '@/components/AuthFlow/WelcomeBackBubble';
import AuthLayout from '@/ui/layout/AuthLayout';

import RedirectOnMissingSearchParameter from './RedirectOnMissingSearchParameter';
import RedirectOnNonAdminUser from './RedirectOnNonAdminUser';
import routePaths from './routePaths';

export const publicRoutes = [
  {
    path: routePaths.authenticate,
    page: lazy(() => import('@/components/AccountVerified/AccountVerified')),
  },
  {
    path: routePaths.verificationSuccess,
    page: lazy(
      () =>
        import('@/components/pages/SuccessVerification/SuccessVerification'),
    ),
  },
  {
    path: routePaths.verificationDoubled,
    page: lazy(
      () =>
        import('@/components/pages/DoubledVerification/DoubledVerification'),
    ),
  },
  {
    path: routePaths.verificationResend,
    page: lazy(
      () => import('@/components/pages/ResendVerification/ResendVerification'),
    ),
  },
  {
    path: routePaths.affiliate,
    page: lazy(() => import('@/components/pages/Affiliate/AffiliatePage')),
  },
  {
    path: routePaths.businessInvite,
    page: lazy(
      () => import('@/components/pages/BusinessInvite/BusinessInvite'),
    ),
  },
  {
    path: routePaths.connectMonday,
    page: lazy(
      () => import('@/components/pages/Integrations/organisms/MondayLoginPage'),
    ),
    guard: ({ children }: PropsWithChildren) => (
      <RedirectOnMissingSearchParameter parameter="redirect_url">
        {children}
      </RedirectOnMissingSearchParameter>
    ),
  },
];

export const nonLoggedInRoutes = [
  {
    path: routePaths.onboard,
    page: lazy(() => import('@/pages/SignUp')),
    layout: AuthLayout,
    layoutProps: { RightAdornment: ClientBubbles },
  },
  {
    path: routePaths.signUp,
    page: lazy(() => import('@/pages/SignUp')),
    layout: AuthLayout,
    layoutProps: { RightAdornment: ClientBubbles },
  },
  {
    path: routePaths.signUpProfile,
    page: lazy(() => import('@/pages/SignUpProfile')),
    layout: AuthLayout,
    layoutProps: { RightAdornment: ClientBubbles },
  },
  {
    path: routePaths.login,
    page: lazy(() => import('@/pages/SignIn')),
    layout: AuthLayout,
    layoutProps: { RightAdornment: WelcomeBackBubble } as const,
  },
  {
    path: routePaths.root,
    page: lazy(() => import('@/pages/SignIn')),
    layout: AuthLayout,
    layoutProps: { RightAdornment: WelcomeBackBubble } as const,
  },
  {
    path: routePaths.loginEmail,
    page: lazy(() => import('@/pages/SignInEmail')),
    layout: AuthLayout,
    layoutProps: { RightAdornment: WelcomeBackBubble },
  },
  {
    path: routePaths.forgotPassword,
    page: lazy(() => import('@/pages/ForgotPassword')),
    layout: AuthLayout,
    layoutProps: {
      RightAdornment: WelcomeBackBubble,
      animationLayout: 'reset',
    } as const,
  },
  {
    path: routePaths.forgotPasswordConfirmation,
    page: lazy(() => import('@/pages/ForgotPasswordConfirmation')),
  },
  {
    path: routePaths.resetPassword,
    page: lazy(() => import('@/pages/ResetPassword')),
    layout: AuthLayout,
    layoutProps: {
      RightAdornment: WelcomeBackBubble,
      animationLayout: 'reset',
    } as const,
  },
  {
    path: routePaths.addPassword,
    page: lazy(() => import('@/pages/ResetPassword')),
    layout: AuthLayout,
    layoutProps: {
      RightAdornment: WelcomeBackBubble,
      animationLayout: 'reset',
    } as const,
  },
];

export const onboardingRoutes = [
  {
    path: routePaths.calendars,
    page: lazy(() => import('@/pages/OnboardingCalendars')),
  },
  {
    path: routePaths.conferencing,
    page: lazy(() => import('@/pages/OnboardingVideoConferencing')),
  },
  {
    path: routePaths.billing,
    page: lazy(() => import('@/pages/OnboardingBilling')),
  },
  {
    path: routePaths.transactionCompleted,
    page: lazy(() => import('@/pages/OnboardingTransactionCompleted')),
  },
];

export const upgradeRoutes = [
  {
    path: routePaths.upgrade,
    page: lazy(() => import('@/pages/OnboardingBilling')),
  },
  {
    path: routePaths.upgradeSuccess,
    page: lazy(() => import('@/pages/OnboardingTransactionCompleted')),
  },
];

export const subscribedUsersRoutes = [
  {
    path: routePaths.manageUsers,
    page: lazy(() => import('@/components/pages/Users/Users')),
    guard: ({ children }: PropsWithChildren) => (
      <RedirectOnNonAdminUser>{children}</RedirectOnNonAdminUser>
    ),
  },
  {
    path: routePaths.myAvailability,
    page: lazy(
      () => import('@/components/pages/MyAvailability/MyAvailability'),
    ),
  },
  {
    path: routePaths.myBookings,
    page: lazy(() => import('@/pages/MyBookings')),
  },
  {
    path: routePaths.myEvents,
    page: lazy(() => import('@/components/pages/MyEvents/MyEvents')),
  },
  {
    path: routePaths.links,
    page: lazy(() => import('@/components/pages/Sharing/Sharing')),
  },
  {
    path: routePaths.myEventsAdd,
    hideMobileHeader: true,
    page: lazy(
      () => import('@/components/pages/AddEditMyEvent/AddEditMyEvent'),
    ),
  },
  {
    path: routePaths.myEventsEdit,
    hideMobileHeader: true,
    page: lazy(
      () => import('@/components/pages/AddEditMyEvent/AddEditMyEvent'),
    ),
  },
];

export const anyUsersRoutes = [
  {
    path: routePaths.settings,
    page: lazy(() => import('@/components/pages/Settings/Settings')),
  },
  {
    path: routePaths.integrations,
    page: lazy(() => import('@/components/pages/Integrations/Integrations')),
  },
  {
    path: routePaths.manageSubscription,
    page: lazy(
      () => import('@/components/pages/Settings/organisms/ManageSubscription'),
    ),
  },
];
