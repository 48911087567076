import { USER_SEAT_STATUS } from '@/stores/UsersStore/constants';

export const transformUserToUserSeat = ({
  seatId,
  user,
  status,
  email,
  type,
}) => {
  // no seat status provided
  if (!status) return null;

  // no seat user provided (possible when seat status equals IDLE or PENDING
  if (!user) {
    // seat status is not PENDING return idle seat
    if (status !== USER_SEAT_STATUS.PENDING)
      return {
        id: seatId,
        status: USER_SEAT_STATUS.IDLE,
      };

    // seat status is PENDING
    return {
      email,
      id: seatId,
      status,
    };
  }

  const { id, firstName, homeEmail, lastName, name } = user;

  return {
    email: homeEmail,
    id: seatId,
    name: `${firstName ?? ''} ${lastName ?? ''}`.trim() || name,
    status,
    type,
    user: id,
  };
};
