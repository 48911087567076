import ct from 'countries-and-timezones';

import i18n from '@/utils/i18n';

export const countries = Object.values(ct.getAllCountries())
  .map(({ id, name }) => ({
    value: `${id}`,
    label: `${name}`,
  }))
  .sort((a, b) => {
    if (a?.label < b?.label) return -1;
    if (a?.label > b?.label) return 1;

    return 0;
  });

export const getCountryId = (countryName) =>
  countries.find((it) => it.label === countryName)?.value;

const combinations = (isDeprecated) =>
  Object.values(ct.getAllCountries()).map(({ id }) => {
    const zones = ct.getTimezonesForCountry(id, { deprecated: isDeprecated });

    return zones.map(
      ({
        name,
        utcOffset,
        utcOffsetStr,
        dstOffset,
        dstOffsetStr,
        deprecated,
      }) => ({
        deprecated,
        id,
        name,
        dstOffset,
        utcOffset,
        utcOffsetStr:
          dstOffsetStr && dstOffsetStr !== utcOffsetStr
            ? `${utcOffsetStr}/${dstOffsetStr}`
            : utcOffsetStr,
      }),
    );
  });

/**
 * @typedef {{
 *   deprecated: boolean,
 *   value: string,
 *   label: string,
 *   name: string,
 *   utcOffset: number,
 *   dstOffset: number
 * }} MappedTimezone
 */

/**
 * @returns {Array<MappedTimezone>}
 */
export const timeZonesArray = (isDeprecated = false) =>
  [
    ...combinations(isDeprecated)
      .flat()
      .flatMap(
        ({ deprecated, id, name, dstOffset, utcOffset, utcOffsetStr }) => [
          {
            deprecated,
            value: id,
            label: `${name} (${utcOffsetStr})`,
            name,
            utcOffset,
            dstOffset,
          },
        ],
      )

      .reduce(
        (valueMap, { deprecated, value, label, utcOffset, dstOffset }) =>
          valueMap.has(label)
            ? valueMap
            : valueMap.set(label, {
                deprecated,
                value,
                label,
                utcOffset,
                dstOffset,
              }),
        new Map(),
      )
      .values(),
  ].filter((it) => /^\w+\/[a-zA-Z_]+/.test(it.label));

export const timeZones = timeZonesArray(true);

export const notDeprecatedTimeZones = timeZonesArray(false);

/**
 * @param {Array<{ label: string }>} rawTimeZones
 * @returns {Array<{ label: string, options: Array<{ label: string }> }>}
 */
export const groupTimezonesByRegion = (rawTimeZones) => [
  ...Object.entries(
    rawTimeZones
      .filter(({ deprecated, place_id: placeId }) => !deprecated && !placeId)
      .reduce((accumulatedRegionOptions, option) => {
        const { label } = option;

        const [region] = label.split('/');

        return {
          ...accumulatedRegionOptions,
          [region]: [...(accumulatedRegionOptions[region] ?? []), option],
        };
      }, {}),
  )
    .sort(([regionA], [regionB]) => regionA.localeCompare(regionB))
    .map(([region, options]) => ({
      label: region,
      options,
    })),
  rawTimeZones
    .filter(({ deprecated, place_id: placeId }) => deprecated && !placeId)
    .reduce(
      (accumulatedDeprecatedOptions, option) => ({
        ...accumulatedDeprecatedOptions,
        options: [...accumulatedDeprecatedOptions.options, option],
      }),
      {
        label: i18n.t('deprecated_time_zones'),
        options: [],
      },
    ),
  rawTimeZones
    .filter(({ place_id: placeId }) => placeId)
    .reduce(
      (accumulatedFoundPlacesOptions, option) => ({
        ...accumulatedFoundPlacesOptions,
        options: [...accumulatedFoundPlacesOptions.options, option],
      }),
      {
        label: i18n.t('found_places'),
        options: [],
      },
    ),
];

export const dateFormats = [
  {
    value: 'DD/MM/YYYY',
    label: 'DD/MM/YYYY',
  },
];

export const timeFormats = [
  {
    value: true,
    label: '12h (am/pm)',
  },
  {
    value: false,
    label: '24h',
  },
];
