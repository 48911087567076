const INIT_STATE = {
  loading: false,
  email: '',
  firstName: '',
  lastName: '',
  businessName: '',
  password: '',
  passwordConfirmation: '',
  signUpError: false,
  isStepOne: true,
  tzIdentifier: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const signUpReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_EMAIL':
      return {
        ...state,
        email: action.payload,
        signUpError: false,
      };
    case 'UPDATE_PASSWORD':
      return {
        ...state,
        password: action.payload,
      };
    case 'UPDATE_TIMEZONE':
      return {
        ...state,
        tzIdentifier: action.payload,
      };
    case 'UPDATE_PASSWORD_CONFIRMATION':
      return {
        ...state,
        passwordConfirmation: action.payload,
      };
    case 'UPDATE_FIRST_NAME':
      return {
        ...state,
        firstName: action.payload,
      };
    case 'UPDATE_LAST_NAME':
      return {
        ...state,
        lastName: action.payload,
      };
    case 'UPDATE_BUSINESS_NAME':
      return {
        ...state,
        businessName: action.payload,
      };
    case 'ATTEMPT_SUBMIT_EMAIL':
      return {
        ...state,
        loading: true,
      };
    case 'CANCEL_LOADING':
      return {
        ...state,
        loading: false,
      };
    case 'DUPLICATE_EMAIL_SIGN_UP_ERROR':
      return {
        ...state,
        loading: false,
        signUpError: true,
      };
    case 'CHANGE_STEP':
      return {
        ...state,
        isStepOne: false,
      };

    default:
      return state;
  }
};

export default signUpReducer;
