import React from 'react';

export default function HelpIcon() {
  return (
    <svg
      width="28px"
      height="28px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 22.2"
      fill="currentColor"
      xmlSpace="preserve"
    >
      <g id="Symbols">
        <g
          id="CatchApp-Bookings_x2F_Symbols_x2F_MenuCorporate-Copy"
          transform="translate(-47.000000, -435.000000)"
        >
          <g
            id="Icons_x2F_MainMenu_x2F_Settings_x2F_Grey3Fill-Copy-5"
            transform="translate(34.000000, 434.000000)"
          >
            <path
              id="Combined-Shape"
              className="st0"
              d="M25,1c6.6,0,12,4.4,12,9.8c0,3.2-1.9,6.1-4.9,7.8c2.1,2.5,4.1,3.5,4.1,3.5s-3,3-8.7-1.8
				c-0.8,0.1-1.7,0.2-2.5,0.2c-6.6,0-12-4.4-12-9.8S18.4,1,25,1z M25,14.6c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2
				c0.7,0,1.2-0.5,1.2-1.2C26.2,15.1,25.7,14.6,25,14.6z M25.1,5c-1.2,0-2.2,0.6-2.9,1.5c-0.3,0.5-0.3,0.8,0,1.1
				c0.3,0.3,0.6,0.3,1.1-0.1c0.5-0.5,1-0.9,1.6-0.9c0.8,0,1.3,0.5,1.3,1.3c0,0.5-0.3,0.9-0.6,1.4l0,0l-0.4,0.5l-0.3,0.3
				c-0.5,0.7-1,1.6-0.9,2.7c0.1,0.4,0.3,0.6,0.7,0.6c0.4,0,0.7-0.2,0.9-0.6c0,0,0-0.1,0-0.2l0,0l0-0.6c0-0.8,0.4-1.3,0.9-1.8l0,0
				L27,9.8c0.5-0.6,1-1.1,1-2C28,6,26.8,5,25.1,5z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
