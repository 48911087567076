import { Button, Grid, Image, Text } from '@chakra-ui/react';
import type { AxiosError } from 'axios';
import { PropsWithChildren, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount, useMountedState } from 'react-use';

import errorImg from '@/assets/error-occured.png';
import useBreakpointLayout from '@/hooks/useBreakpointLayout';
import { authorizedApi } from '@/utils/apiUtils';

export default function RequestErrorBoundary({ children }: PropsWithChildren) {
  const { t } = useTranslation();

  const [hasTooManyRequestsError, setTooManyRequestsError] = useState(false);

  const isMounted = useMountedState();

  useMount(() => {
    authorizedApi.interceptors.response.use(
      (response) => ({
        ...response,
        ok: true,
      }),
      (error) => {
        const responseError = error as AxiosError;

        if (responseError?.response?.status === 429) {
          setTooManyRequestsError(true);
        }

        return Promise.reject(error);
      },
    );
  });

  const onReload = useCallback(() => {
    window.location.reload();
  }, []);

  const Wrapper = useBreakpointLayout();

  if (!isMounted()) {
    return <div />;
  }

  if (hasTooManyRequestsError && Wrapper) {
    return (
      <Wrapper data-testid="error-occured">
        <Grid w="100%" justifyItems="center" rowGap={8}>
          <Image w="45%" minW="300px" src={errorImg} />
          <Text align="center" fontWeight="600" fontSize="3xl">
            {t('sorry_something_gone_wrong')}
          </Text>
          <Text fontWeight="600" fontSize="xl" align="center">
            <div>{t('too_many_requests_error')}</div>
          </Text>
          <Text align="center">
            <div>{t('button_below_try_again')}</div>
          </Text>
          <Button variant="submitDark" type="button" onClick={onReload}>
            <Text fontSize="sm" variant="semibold">
              {t('try_again')}
            </Text>
          </Button>
        </Grid>
      </Wrapper>
    );
  }

  return children;
}
