/* eslint-disable import/prefer-default-export */

import { flow } from 'mobx-state-tree';

/**
 * @deprecated This function should not be used in new stores
 */
export function autoFlow<
  A extends Array<any>,
  V extends Record<
    string,
    ((...args: A) => void) | ((...args: A) => Generator<Promise<any>>)
  >
>(actionsObject: V) {
  return Object.fromEntries(
    Object.entries(actionsObject).map(([actionKey, action]) => [
      actionKey,
      typeof action === 'function' &&
      action?.constructor?.name === 'GeneratorFunction'
        ? flow(action as () => Generator<Promise<any>>)
        : action,
    ]),
  ) as {
    [K in keyof V]: V[K] extends (...args: A) => Generator<Promise<any>>
      ? (...args: A) => Promise<any>
      : (...args: A) => void;
  };
}
