import paymentStore from '@/stores/PaymentStore';
import { serverToken } from '@/utils/stripe/serverToken';

export const processCardDetails = async ({
  createPaymentMethodResult,
  onError,
}) => {
  const response = await serverToken({
    cardToken: createPaymentMethodResult,
    onError,
  });

  if (response?.success) return { success: true };

  paymentStore.cancelProcessing();

  onError(`await serverToken: ${response?.error}`);

  return {
    success: false,
    error: { action: 'await serverToken', message: response?.error },
  };
};
