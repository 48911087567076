import {
  Box,
  Flex,
  IconButton,
  Link,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';

export default function NavigationLink({
  path,
  Icon,
  disabled,
  label,
  external,
}) {
  const location = useLocation();

  const [minimalLabelHeightBreakpointReached] = useMediaQuery(
    '(min-height: 720px)',
  );

  const Component = (
    <Flex direction="column" alignItems="center">
      <IconButton
        icon={<Icon />}
        variant="no-background"
        isActive={location.pathname.startsWith(path)}
        disabled={disabled}
        aria-label={label}
        color="gray.450"
      />
      {minimalLabelHeightBreakpointReached && (
        <Text
          fontSize="xs"
          color={location.pathname.startsWith(path) ? 'black' : 'gray.450'}
        >
          {label}
        </Text>
      )}
    </Flex>
  );

  const link = external ? (
    <a href={path} target="_blank" rel="noreferrer">
      {Component}
    </a>
  ) : (
    <Link as={RouterLink} to={path} style={{ textDecoration: 'none' }}>
      {Component}
    </Link>
  );

  return disabled ? <Box>{Component}</Box> : link;
}

NavigationLink.defaultProps = {
  disabled: false,
  external: false,
  label: '',
};

NavigationLink.propTypes = {
  path: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  external: PropTypes.bool,
};
