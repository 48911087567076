import './App.css';
import 'flag-icons/css/flag-icons.min.css';

import { useEffect } from 'react';
import { Outlet, useLocation, useNavigation } from 'react-router-dom';
import { useAsync } from 'react-use';
import Smartlook from 'smartlook-client';

import { postAffiliateParams } from '@/hooks/useAffiliates';
import useAppcuesTracking from '@/hooks/useAppcuesTracking';
import corporateUsersEventsStore from '@/stores/CorporateUsersEventsStore/CorporateUsersEventsStore';
import eventsStore from '@/stores/EventStore/EventsStore';
import profileStore from '@/stores/ProfileStore/ProfileStore';
import subscriptionStore from '@/stores/SubscriptionStore';
import usersStore from '@/stores/UsersStore/UsersStore';
import i18n from '@/utils/i18n';

import ProgressFallback from './components/molecules/ProgressFallback';
import routePaths from './config/routePaths';
import { confirmInvitation } from './utils/business/confirmInvitation';

export async function appLoader() {
  confirmInvitation({
    hash: localStorage.getItem(import.meta.env.VITE_INVITE_TOKEN_STORAGE_KEY),
  });

  const longLifeToken = JSON.parse(localStorage.getItem('long_life_token'));

  if (longLifeToken) {
    window.addEventListener('storage', (event) => {
      if (event.key === 'long_life_token' && !event.newValue) {
        localStorage.removeItem('apc_user_id');
        localStorage.removeItem('apc_local_id');

        window.location.assign(routePaths.root);
      }
    });

    await Promise.allSettled([subscriptionStore.getSubscription()]);
    await Promise.allSettled([profileStore.fetchProfile()]);

    if (
      profileStore.profile.businessId &&
      !profileStore.profile.isBusinessAdmin &&
      localStorage.getItem(import.meta.env.VITE_INVITE_TOKEN_STORAGE_KEY)
    ) {
      localStorage.removeItem(import.meta.env.VITE_INVITE_TOKEN_STORAGE_KEY);
    }

    eventsStore.fetchAllEvents();
    profileStore.fetchZapierApiKey();
    profileStore.fetchMondayApiKey();
    profileStore.getUserCard();
    corporateUsersEventsStore.fetchEventTypesForCurrentUser();
    usersStore.fetchUsersSeats();
    postAffiliateParams();

    if (profileStore.profile.id) {
      Smartlook.identify(profileStore.profile.id, {
        name:
          profileStore.profile.businessName ||
          `${profileStore.profile.firstName ?? ''} ${
            profileStore.profile.lastName ?? ''
          }`.trim(),
        email: profileStore.profile.homeEmail,
      });
    }

    if (profileStore.profile.language) {
      i18n.changeLanguage(profileStore.profile.language);
    }
  }

  return true;
}

export default function App() {
  const { trackPage } = useAppcuesTracking();

  const location = useLocation();
  const navigation = useNavigation();

  const loaderState = useAsync(appLoader, []);

  useEffect(() => {
    trackPage();
  }, [location, trackPage]);

  if (navigation.state === 'loading' || loaderState.loading) {
    return <ProgressFallback />;
  }

  return <Outlet />;
}
