import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useSubscriptionStore } from '@/stores/RootStore';

import routePaths from './routePaths';

function SubscribedUserRoute({ children }: PropsWithChildren) {
  const subscriptionStore = useSubscriptionStore();

  const currentPeriodEnd = subscriptionStore.subscription.current_period_end
    ? DateTime.fromMillis(subscriptionStore.subscription.current_period_end)
    : null;

  const currentPeriodEndDiffToNow = currentPeriodEnd?.diffNow().toMillis() ?? 0;

  if (
    subscriptionStore.subscription.id === 'no-subscription-id' &&
    currentPeriodEndDiffToNow < 0
  ) {
    return <Navigate to={routePaths.settings} replace />;
  }

  return <>{children}</>;
}

export default observer(SubscribedUserRoute);
