import { Box, Button, Flex, ModalFooter } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import addUsersModalStore from '@/components/pages/Users/organisms/AddUsersModal/addUsersModalStore';
import paymentStore from '@/stores/PaymentStore';
import { useUsersStore } from '@/stores/RootStore';

export const AddSeatsSummaryStepFooter = observer(
  ({ onCancelClick, onNextClick }) => {
    const { t } = useTranslation();

    const usersStore = useUsersStore();

    const disabled =
      addUsersModalStore.isSubmitting ||
      paymentStore.payment.processing ||
      usersStore.userSeatCostProcessing ||
      usersStore.userSeatCost.seatCount <= 0;

    const isLoading =
      addUsersModalStore.isSubmitting ||
      paymentStore.payment.processing ||
      usersStore.userSeatCostProcessing;

    return (
      <ModalFooter as={Flex} direction="row" justifyContent="space-between">
        <Button isLoading={isLoading} variant="cancel" onClick={onCancelClick}>
          {t('cancel')}
        </Button>
        <Box mr={2} />
        <Button
          disabled={disabled}
          isLoading={isLoading}
          variant="submit"
          onClick={onNextClick}
        >
          {t('add_seats')}
        </Button>
      </ModalFooter>
    );
  },
);

AddSeatsSummaryStepFooter.propTypes = {
  onCancelClick: func.isRequired,
  onNextClick: func.isRequired,
};
