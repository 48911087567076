const INIT_STATE = {
  current_lead: JSON.parse(localStorage.getItem('current_lead')),
  plan: false,
};

const getPaymentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_PLAN':
      return {
        ...state,
        plan: !state.plan,
      };
    default:
      return state;
  }
};

export default getPaymentReducer;
