import { VStack } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { MouseEventHandler } from 'react';

import useMobileMenuItems from '@/components/organisms/MobileMenuItems';
import { useProfileStore, useSubscriptionStore } from '@/stores/RootStore';

import MobileNavigationLink from './MobileNavigationLink';

interface MobileNavigationProperties {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export default function MobileNavigation({
  onClick,
}: MobileNavigationProperties) {
  const { subscription } = useSubscriptionStore();
  const {
    profile: { businessId, isBusinessAdmin },
  } = useProfileStore();

  const menuItems = useMobileMenuItems();

  const currentPeriodEnd = subscription?.current_period_end;

  return (
    <VStack align="stretch">
      {menuItems
        .filter(({ hideInList }) => !hideInList)
        .map(({ path, title, Icon, hidden, disabled: itemDisabled }) => (
          <MobileNavigationLink
            Icon={Icon as () => JSX.Element}
            key={path}
            path={path}
            title={title}
            onClick={onClick}
            disabled={
              itemDisabled ??
              (currentPeriodEnd != null &&
                ((currentPeriodEnd < DateTime.now().toMillis() &&
                  path !== '/settings') ||
                  hidden?.({ businessId, isBusinessAdmin })))
            }
          />
        ))}
    </VStack>
  );
}

MobileNavigation.defaultProps = {
  onClick: () => null,
};
