import {
  Avatar,
  Box,
  Center,
  Flex,
  Link,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import TimeZoneIcon from '@/components/atoms/TimeZoneIcon';
import { allAppointmentsLink } from '@/components/Commons';
import useCurrentLanguageDir from '@/hooks/useCurrentLanugageDir';
import { useProfileStore } from '@/stores/RootStore';

// eslint-disable-next-line react/prop-types
const MemoAvatar = memo(({ name, src }) => (
  <Avatar bg="gray.100" size="md" name={name} src={src} alignSelf="left" />
));

MemoAvatar.displayName = 'MemoAvatar';

function Header() {
  const { t } = useTranslation();

  const profileStore = useProfileStore();

  const {
    name,
    businessName,
    profilePic_url: image,
    tzIdentifier: timeZone,
  } = profileStore.profile ?? {};

  const dir = useCurrentLanguageDir();

  return (
    <Box h="120px" w="100%" pt="2.5rem" pb="1.125rem">
      <Flex alignItems="flex-center" mx="7.125rem">
        <MemoAvatar name={businessName || name} src={image || ''} />
        <Box
          w="100%"
          h="100%"
          ml="1.5rem"
          mr="5.5rem"
          borderBottomWidth={1}
          borderBottomColor="gray.300"
        >
          <Flex>
            <Text color="gray.500" fontSize="1.125rem" variant="semibold">
              {businessName || name}
            </Text>
            <Spacer />
            <Center dir={dir}>
              <TimeZoneIcon />
              <Text color="gray.500" fontSize="sm" paddingInlineStart={2}>
                {t('your_time_zone')}: <b>{timeZone}</b>
              </Text>
            </Center>
          </Flex>
          <Flex pb="1.5rem">
            <Text color="gray.500" fontSize="sm" pt="0.25rem" dir={dir}>
              {t('your_main_bookings_link')}:{' '}
              <Link href={allAppointmentsLink} isExternal color="orange.400">
                {allAppointmentsLink}
              </Link>
            </Text>
            <Spacer />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default observer(Header);
