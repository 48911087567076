import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { arrayOf, node, oneOfType } from 'prop-types';
import { useMemo } from 'react';

import getStripePromise from '@/config/stripe';

export function Payments({ children }) {
  const stripePromise = useMemo(() => getStripePromise(), []);

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>{() => children}</ElementsConsumer>
    </Elements>
  );
}

Payments.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};
