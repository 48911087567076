import { captureException } from '@sentry/react';
import { types } from 'mobx-state-tree';

import { autoFlow } from '@/stores/storeUtils';
import { authorizedApi } from '@/utils/apiUtils';
import i18n from '@/utils/i18n';

const Payment = types.model('Payment', {
  authError: types.boolean,
  authErrorMessage: types.string,
  cardComplete: types.boolean,
  clientSecret: types.string,
  couponDiscount: types.number,
  couponDuration: types.maybeNull(types.number),
  couponError: types.boolean,
  couponErrorMessage: types.string,
  couponInput: types.string,
  couponPrice: types.number,
  currency: types.string,
  price: types.number,
  processing: types.boolean,
  requiresAttn: types.boolean,
  showCoupon: types.boolean,
  successCoupon: types.boolean,
});

const PaymentStore = types
  .model('PaymentStore', { payment: Payment })
  .actions((self) =>
    autoFlow({
      init() {
        self.payment = {
          authError: false,
          authErrorMessage: '',
          cardComplete: false,
          clientSecret: '',
          couponDiscount: 0,
          couponDuration: null,
          couponError: false,
          couponErrorMessage: '',
          couponInput: '',
          couponPrice: 0,
          currency: 'gbp',
          price: 10,
          processing: false,
          requiresAttn: false,
          showCoupon: false,
          successCoupon: false,
        };
      },

      failedAuthentication(error) {
        self.payment.authError = true;
        self.payment.authErrorMessage = error;
      },

      resetAuthError() {
        self.payment.authError = false;
        self.payment.authErrorMessage = '';
      },

      processingPayment() {
        self.payment.processing = true;
      },

      *validateCoupon(value) {
        const { id, token } = JSON.parse(
          localStorage.getItem('long_life_token'),
        );
        try {
          self.payment.successCoupon = false;
          self.payment.couponError = false;
          self.payment.couponErrorMessage = '';

          const response = yield authorizedApi.post(
            `/appointments/users/${id}/validate-coupon`,
            {
              couponName: value.toUpperCase(),
            },
            {
              headers: {
                token,
              },
            },
          );

          if (response.data.coupon.valid) {
            self.payment = {
              ...self.payment,
              successCoupon: true,
              couponPrice:
                self.payment.price -
                (response.data.coupon.percent_off / 100) * self.payment.price,
              couponError: false,
              couponErrorMessage: '',
              couponDuration: response.data.coupon.duration_in_months,
              couponDiscount: response.data.coupon.percent_off,
            };
            self.payment.couponInput = value;
          } else {
            self.payment.couponError = true;
            self.payment.couponErrorMessage = i18n.t('invalid_coupon');
            self.payment.successCoupon = false;
          }
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }

          self.payment.couponError = true;
        }
      },

      resetCouponError() {
        self.payment.couponError = false;
        self.payment.couponErrorMessage = '';
      },

      updateCoupon(value) {
        self.payment.couponInput = value;
        self.payment.couponError = false;
        self.payment.couponErrorMessage = '';
      },

      showHideCoupon() {
        self.payment.showCoupon = !self.payment.showCoupon;
      },

      completeForm(flag) {
        self.payment.cardComplete = flag;
      },

      cancelProcessing() {
        self.payment.processing = false;
      },
    }),
  );

const paymentStore = PaymentStore.create({
  payment: {
    authError: false,
    authErrorMessage: '',
    cardComplete: false,
    clientSecret: '',
    couponDiscount: 0,
    couponDuration: null,
    couponError: false,
    couponErrorMessage: '',
    couponInput: '',
    couponPrice: 0,
    currency: 'gbp',
    price: 10,
    processing: false,
    requiresAttn: false,
    showCoupon: false,
    successCoupon: false,
  },
});

export default paymentStore;
