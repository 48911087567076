import { flow, types } from 'mobx-state-tree';

import { authorizedApi } from '@/utils/apiUtils';
import i18n from '@/utils/i18n';

import { Event } from '../EventStore/Event';
import { transformEventToStoreFormat } from '../EventStore/transformEventToStoreFormat';
import { USER_SEAT_STATUS } from '../UsersStore/constants';
import { transformUserToUserSeat } from '../UsersStore/transformUserToUserSeat';
import { UserSeat } from '../UsersStore/UsersStore';

export const CorporateUsersEventsStore = types
  .model('CorporateUsersEventsStore', {
    eventTypes: types.map(types.array(Event)),
    users: types.array(UserSeat),
  })
  .views((self) => ({
    get activeUserSeats() {
      return self.users.filter(
        ({ status }) => status === USER_SEAT_STATUS.ACTIVE,
      );
    },
  }))
  .actions((self) => ({
    fetchSeats: flow(function* fetchSeats({ businessId }) {
      try {
        const businessResponse = yield authorizedApi.get(
          `/business/${businessId}`,
        );

        [self.users] = businessResponse.data.seats
          .map(transformUserToUserSeat)
          .reduce(
            ([accumulatedUsers, userIdSet], user) => {
              if (userIdSet.has(user.user)) {
                return [accumulatedUsers, userIdSet];
              }

              return [[...accumulatedUsers, user], userIdSet.add(user.user)];
            },
            [[], new Set()],
          );
      } catch (error) {
        self.users = [];
      }
    }),

    fetchEventTypesForCurrentUser: () =>
      self.fetchEventTypesForUser({
        userId: authorizedApi.defaults.headers.id,
      }),

    fetchEventTypesForUser: flow(function* fetchEventTypesForUser({ userId }) {
      try {
        const requestTokenResponse = yield authorizedApi.get(
          `/appointments/users/${userId}/requestToken`,
        );

        if (!requestTokenResponse.data.success) {
          throw new Error(i18n.t('user_not_found'));
        }

        const { token } = requestTokenResponse.data;

        const appointmentsResponse = yield authorizedApi.get('/appointments', {
          headers: {
            token,
          },
        });

        if (!appointmentsResponse.data?.success) {
          throw new Error('Event types fetching failed');
        }

        const data = appointmentsResponse.data?.success;

        self.eventTypes.set(
          userId,
          data?.map((item) => transformEventToStoreFormat(item)) ?? [],
        );
      } catch (error) {
        self.eventTypes.set(userId, []);
      }
    }),

    enableEventType({ id }) {
      const userId = authorizedApi.defaults.headers.id;

      self.eventTypes.set(
        userId,
        self.eventTypes.get(userId).map((eventType) =>
          String(eventType.id) === String(id)
            ? {
                ...eventType,
                isActive: true,
              }
            : eventType,
        ),
      );
    },

    disableEventType({ id }) {
      const userId = authorizedApi.defaults.headers.id;

      self.eventTypes.set(
        userId,
        self.eventTypes.get(userId).map((eventType) =>
          String(eventType.id) === String(id)
            ? {
                ...eventType,
                isActive: false,
              }
            : eventType,
        ),
      );
    },
  }));

const corporateUsersEventsStore = CorporateUsersEventsStore.create({
  eventTypes: {},
  users: [],
});

export default corporateUsersEventsStore;
