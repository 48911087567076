import { Box, Divider, IconButton, Link, Text } from '@chakra-ui/react';
import type { MouseEventHandler } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import EventsIcon from '../atoms/EventsIcon';

interface MobileNavigationLinkProperties {
  path: string;
  title: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean;
  Icon?: () => JSX.Element;
}

export default function MobileNavigationLink({
  path,
  title,
  onClick,
  disabled,
  Icon,
}: MobileNavigationLinkProperties) {
  const location = useLocation();

  const component = Icon && (
    <>
      <Text
        fontSize="xl"
        textAlign="left"
        py="1rem"
        display="inline-block"
        pl="5rem"
        color={location.pathname.startsWith(path) ? 'black' : 'gray.470'}
      >
        <IconButton
          icon={<Icon />}
          variant="no-background"
          isActive={location.pathname.startsWith(path)}
          disabled={disabled}
          aria-label={title}
          color="gray.450"
        />
        {title}
      </Text>
      <Divider borderColor="gray.200" mx="1.875rem" w="auto" />
    </>
  );

  return disabled ? (
    <Box>{component}</Box>
  ) : (
    <Link
      as={RouterLink}
      to={path}
      m="0 !important"
      _active={{ background: 'none' }}
      _focus={{ border: 'none' }}
      style={{ textDecoration: 'none' }}
      onClick={onClick}
    >
      {component}
    </Link>
  );
}

MobileNavigationLink.defaultProps = {
  disabled: false,
  Icon: EventsIcon,
  onClick: undefined,
};
