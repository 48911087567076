import { Flex, Text, useToast } from '@chakra-ui/react';

import CheckmarkIcon from '@/assets/Icons _ Checkmark.svg';

export default function useSuccessToast() {
  const toast = useToast();

  return (message: string) => {
    toast({
      position: 'bottom',
      variant: 'unstyled',
      render: () => (
        <Flex
          data-testid="success-toast"
          bg="green.600"
          borderRadius={8}
          direction="row"
          gridColumnGap={2}
          py={1.5}
          px={2}
          m="0 auto"
          w="max-content"
        >
          <img src={CheckmarkIcon} alt="checkmark icon" />
          <Text variant="semibold" color="white">
            {message}
          </Text>
        </Flex>
      ),
    });
  };
}
