import { Box, Flex, Grid } from '@chakra-ui/react';
import { node, string } from 'prop-types';

import bookingsLogoDesktop from '@/assets/bookings-logo-desktop.svg';

export default function DesktopUltraWideLayout({
  children,
  'data-testid': dataTestId,
}) {
  return (
    <Flex
      data-testid={dataTestId}
      data-testname="DesktopUltraWideLayout"
      direction="column"
      justify="flex-start"
      align="center"
      h="100%"
      overflowY="auto"
    >
      <Grid
        templateColumns="1fr 860px 1fr"
        templateRows="minmax(min-content, 800px)"
        gap="70px"
        pt={{ base: 4, '2xl': 12 }}
        pb={4}
        h="100%"
      >
        <img src={bookingsLogoDesktop} alt="CatchApp Bookings Logo" />
        <Box boxShadow="md" px={12} py={8} w="100%" h="100%">
          {children}
        </Box>
      </Grid>
    </Flex>
  );
}

DesktopUltraWideLayout.propTypes = {
  'data-testid': string,
  children: node,
};

DesktopUltraWideLayout.defaultProps = {
  'data-testid': undefined,
  children: undefined,
};
