import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { bool, func } from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getStripePromise from '@/config/stripe';
import { useProfileStore } from '@/stores/RootStore';

import { useErrorToast, useSuccessToast } from '../../Integrations/Commons';

function CardEditModal({ isOpen, onClose }) {
  const { t } = useTranslation();

  const stripePromise = useMemo(() => getStripePromise(), []);

  const [isLoading, setLoading] = useState(false);

  const profileStore = useProfileStore();

  const showErrorToast = useErrorToast();
  const showSuccessToast = useSuccessToast();

  const handleSubmit = async (event, elements, stripe) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    try {
      setLoading(true);

      const paymentMethodResult = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (paymentMethodResult.error) {
        throw new Error(paymentMethodResult.error);
      }

      await profileStore.changeUserCard(paymentMethodResult.paymentMethod.id);

      showSuccessToast(t('success_card_details_change'));
      onClose();
    } catch (error) {
      showErrorToast(
        error?.data?.error ??
          error?.message ??
          t('failure_card_details_change'),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <form onSubmit={(event) => handleSubmit(event, elements, stripe)}>
                <ModalHeader>{t('edit_card_details')}</ModalHeader>
                <ModalBody>
                  <Text align="left">{t('card_number')}</Text>
                  <CardElement />
                </ModalBody>
                <ModalFooter>
                  <Button
                    disabled={isLoading}
                    isLoading={isLoading}
                    variant="cancel"
                    mr={3}
                    onClick={onClose}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    disabled={isLoading}
                    isLoading={isLoading}
                    variant="submit"
                    type="submit"
                  >
                    {t('change')}
                  </Button>
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
        )}
      </ElementsConsumer>
    </Elements>
  );
}

CardEditModal.propTypes = {
  isOpen: bool,
  onClose: func.isRequired,
};

CardEditModal.defaultProps = {
  isOpen: false,
};

export default observer(CardEditModal);
