import { Box, Circle, Flex, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import routePaths from '@/config/routePaths';
import { defaultBreakpoint } from '@/hooks/useBreakpoint';
import { useProfileStore } from '@/stores/RootStore';

function StepIndicator() {
  const { t } = useTranslation();

  const profileStore = useProfileStore();

  const steps = useMemo(
    () =>
      [
        {
          label: t('calendars'),
          route: routePaths.calendars,
        },
        {
          label: t('video_tools'),
          route: routePaths.conferencing,
        },
        ...(profileStore.profile.isBusinessAdmin
          ? [
              {
                label: t('membership'),
                route: routePaths.billing,
              },
            ]
          : []),
      ] as const,
    [profileStore.profile.isBusinessAdmin, t],
  );

  const step = steps.findIndex(
    ({ route }) => route === window.location.pathname,
  );

  return (
    <Flex
      data-testid="step-indicator"
      alignItems="center"
      w="100%"
      px={{ base: 6, [defaultBreakpoint]: 0 }}
      pt={{ base: 10, [defaultBreakpoint]: 0 }}
      pb={8}
    >
      {steps.map(({ label, route }, index) => (
        <Fragment key={route}>
          <Circle
            position="relative"
            bg={index <= step ? 'orange.500' : 'gray.300'}
            size="16px"
          >
            <Text
              color={index <= step ? 'orange.500' : 'gray.300'}
              fontSize="xs"
              left="50%"
              position="absolute"
              top="100%"
              transform="translateX(-50%)"
              whiteSpace="nowrap"
              variant="bold"
            >
              {label}
            </Text>
          </Circle>
          {index < steps.length - 1 && (
            <Box
              bg={index + 1 <= step ? 'orange.500' : 'gray.300'}
              h="1px"
              flexGrow={1}
            />
          )}
        </Fragment>
      ))}
    </Flex>
  );
}

export default observer(StepIndicator);
