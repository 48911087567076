import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useCurrentLanguageDir() {
  const { i18n } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => i18n.dir(i18n.resolvedLanguage), [
    i18n.resolvedLanguage,
  ]);
}
