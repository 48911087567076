import { types } from 'mobx-state-tree';

import profileStore from '@/stores/ProfileStore/ProfileStore';
import { autoFlow } from '@/stores/storeUtils';
import { USER_SEAT_STATUS } from '@/stores/UsersStore/constants';
import usersStore from '@/stores/UsersStore/UsersStore';

export const EditUserModalStore = types
  .model({
    isOpen: types.boolean,
    editedUserSeatId: types.maybeNull(types.union(types.string, types.number)),
    editedIdleUserSeat: types.maybeNull(
      types.model({
        id: types.union(types.string, types.number),
        status: types.enumeration([USER_SEAT_STATUS.IDLE]),
      }),
    ),
  })
  .views((self) => ({
    get editedUserSeat() {
      return (
        usersStore.userSeats.find(({ id }) => id === self.editedUserSeatId) ??
        null
      );
    },
  }))
  .actions((self) =>
    autoFlow({
      close() {
        self.isOpen = false;
        self.editedUserSeatId = null;
      },

      editUser({ userSeat }) {
        const { id, status } = userSeat;

        if (userSeat.status === USER_SEAT_STATUS.IDLE) {
          self.editedIdleUserSeat = { id, status };
          self.editedUserSeatId = id;
        } else {
          self.editedIdleUserSeat = null;
          self.editedUserSeatId = id;
        }

        self.isOpen = true;
      },

      *onIdleSubmit(values) {
        try {
          if (values.email) {
            yield usersStore.inviteUser({
              userSeat: {
                ...self.editedUserSeat,
                email: values.email,
              },
              profile: profileStore.profile,
            });
          } else {
            yield usersStore.deleteSeats({ count: 1 });
          }
          yield usersStore.fetchUsersSeats({ profile: profileStore.profile });
        } finally {
          self.isOpen = false;
        }
      },

      *onPendingSubmit(values) {
        try {
          yield usersStore.inviteUser({
            userSeat: {
              ...self.editedUserSeat,
              email: values.email,
            },
            profile: profileStore.profile,
          });
          yield usersStore.fetchUsersSeats({ profile: profileStore.profile });
        } finally {
          self.isOpen = false;
        }
      },
    }),
  );

const editUserModalStore = EditUserModalStore.create({
  isOpen: false,
});

export default editUserModalStore;
