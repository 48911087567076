import React from 'react';

export default function CalendarIcon() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <g
        id="Bookings-Dashboard-FINAL"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CatchApp-Dashboard/Desktop-Final/MyEvents-(9)"
          transform="translate(-49.000000, -142.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g id="CatchApp-Dashboard/Symbols/Menus">
            <g
              id="Icons/MainMenu/Settings/Grey3Fill-Copy"
              transform="translate(41.000000, 134.000000)"
            >
              <path
                d="M32,14.5454545 L32,31.9996979 L8,31.9894273 L8,14.5454545 L32,14.5454545 Z M30.9090909,8 C32,8 32,10.1818182 32,10.1818182 L32,10.1818182 L32,13.4545455 L8,13.4545455 L8.00071729,10.1351943 C8.00824884,9.81886922 8.09486166,8 9.09090909,8 C10.0869565,8 10.1735693,9.81886922 10.1811009,10.1351943 L10.1818182,10.1818182 L13.4545455,10.1818182 C13.4545455,10.1818182 13.4545455,8 14.5454545,8 C15.6363636,8 15.6363636,10.1818182 15.6363636,10.1818182 L15.6363636,10.1818182 L18.9090909,10.1818182 C18.9090909,10.1818182 18.9447549,8 20.017832,8 C20.997598,8 21.0827951,9.81886922 21.0902035,10.1351943 L21.0909091,10.1818182 L24.3636364,10.1818182 C24.3636364,10.1818182 24.4414061,8 25.4545455,8 C26.4676848,8 26.5454545,10.1818182 26.5454545,10.1818182 L26.5454545,10.1818182 L29.8181818,10.1818182 C29.8181818,10.1818182 29.8181818,8 30.9090909,8 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
