import React from 'react';

export default function GoogleIcon() {
  return (
    <svg
      width="19px"
      height="25px"
      viewBox="0 0 19 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="Bookings-Dashboard-Corporate"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Prompt-(Desktop)" transform="translate(-53.000000, -382.000000)">
          <g id="CatchApp-Dashboard/Symbols/Menus">
            <g
              id="Icons/MainMenu/Settings/Grey3Fill-Copy-3"
              transform="translate(41.000000, 374.000000)"
            >
              <path
                d="M24.228678,18.9919528 C26.7428927,21.2950097 28,23.7428244 28,26.3353968 C28,30.2211739 24.418277,32 20,32 C15.581723,32 12,30.2211739 12,26.3353968 C12,23.7417005 13.2581975,21.2951237 15.7745924,18.9956662 L18.3636364,21.9354839 L20,20 L21.6363636,21.9354839 L24.228678,18.9919528 Z M20,8 C22.6508471,8 24.8,10.167264 24.8,12.8404499 C24.8,15.5136357 22.6508471,19.4509171 20,19.4509171 C17.3488716,19.4509171 15.2,15.5136357 15.2,12.8404499 C15.2,10.167264 17.3491529,8 20,8 Z"
                id="Shape"
                fill="currentColor"
                fillRule="nonzero"
              />
              <circle
                id="Oval"
                strokeOpacity="0.85"
                stroke="currentColor"
                fill="#FFFFFF"
                cx="26.5"
                cy="28.5"
                r="4"
              />
              <path
                d="M27,26 L27,28 L29,28 L29,29 L26.999,29 L27,31 L26,31 L25.999,29 L24,29 L24,28 L26,28 L26,26 L27,26 Z"
                id="Combined-Shape"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
