import { EventLocationOptions } from '@/stores/EventStore/EventLocationOptions';

export const eventLocationTypeToRawLocationType = ({
  location,
  customLocationName,
}) => {
  const [rawLocationType, rawLocationValue] =
    Object.entries(EventLocationOptions).find(
      ([, value]) => value === (location?.value ?? location),
    ) ?? [];

  const [rawCustomLocationKey] = Object.entries(EventLocationOptions).find(
    ([, value]) => value === 'custom',
  );

  switch (rawLocationValue) {
    case 'skype':
      return `${rawLocationType}: ${customLocationName}`;
    case 'zoom':
      return rawLocationType;
    case 'Microsoft Teams':
      return rawLocationType;
    case 'Google Meet':
      return rawLocationType;
    case 'catchapp-call':
      return rawLocationType;
    case 'call':
      return `${rawLocationType}: ${customLocationName}`;
    case 'custom':
      return `${rawLocationType}: ${customLocationName}`;
    case 'attendee-location':
      return rawLocationType;
    case 'custom-location':
      return `${rawLocationType}: ${customLocationName}`;
    default:
      return `${rawCustomLocationKey}: ${customLocationName}`;
  }
};
