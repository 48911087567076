import { PropsWithChildren } from 'react';

import ProgressFallback from '@/components/molecules/ProgressFallback';

import routePaths from './routePaths';

export default function RedirectOnMissingSearchParameter({
  children,
  parameter,
}: PropsWithChildren<{ parameter: string }>) {
  const searchParams = new URLSearchParams(window.location.search);

  if (!searchParams.has(parameter)) {
    window.location.assign(routePaths.root);

    return <ProgressFallback />
  }

  return <>{children}</>;
}
