export const convertPulseTags = (data) => {
  const { pulseTags } = data;

  if (!pulseTags) return { ...data };

  const newPulseTags = pulseTags.map((item) => ({
    id: +item.id,
    name: item.name,
    formName: `pulseCrmTag${item.id}`,
  }));

  return { ...data, pulseTags: [...newPulseTags] };
};

/*
export const convertPulseTags = (tags) => {
  if (!tags) return null;

  return tags.map((item) => ({
    id: +item.id,
    name: item.name,
    formName: `pulseCrmTag${item.id}`,
  }));
};
*/
