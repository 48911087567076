import { Box, Divider, useMediaQuery, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import NavigationLink from '@/components/molecules/NavigationLink';
import useBreakpoint from '@/hooks/useBreakpoint';
import { useProfileStore, useSubscriptionStore } from '@/stores/RootStore';

import CalendarIcon from '../atoms/CalendarIcon';
import CogIcon from '../atoms/CogIcon';
import CogsIcon from '../atoms/CogsIcon';
import EventsIcon from '../atoms/EventsIcon';
import HelpIcon from '../atoms/HelpIcon';
import LinkIcon from '../atoms/LinkIcon';
import LogoIcon from '../atoms/LogoIcon';
import TickIcon from '../atoms/TickIcon';
import UsersIcon from '../atoms/UsersIcon';

const MINIMAL_STACK_SPACING = 1;
const OPTIMAL_STACK_SPACING = 3;
const WIDE_STACK_SPACING = 5;

function Navigation({ disabled }) {
  const { t } = useTranslation();

  const isLargerThanBreakpoint = useBreakpoint();

  const profileStore = useProfileStore();
  const subscriptionStore = useSubscriptionStore();

  const menuItems = useMemo(
    () => [
      {
        path: '/my-availability',
        Icon: CalendarIcon,
        label: t('availability'),
      },
      {
        path: '/my-bookings',
        Icon: TickIcon,
        label: t('bookings'),
      },
      {
        path: '/my-events',
        Icon: EventsIcon,
        label: t('event_types'),
      },
      {
        path: '/manage-users',
        Icon: UsersIcon,
        label: t('users'),
        hidden:
          (!!profileStore.profile.businessId &&
            !profileStore.profile.isBusinessAdmin) ||
          subscriptionStore.subscription.id === 'no-subscription-id',
      },
      {
        path: '/links',
        Icon: LinkIcon,
        label: t('sharing'),
      },
    ],
    [
      profileStore.profile.businessId,
      profileStore.profile.isBusinessAdmin,
      subscriptionStore.subscription.id,
      t,
    ],
  );

  const [
    optimalStackSpacingBreakpointReached,
    wideStackSpacingBreakpointReached,
  ] = useMediaQuery(['(min-height: 600px)', '(min-height: 800px)']);

  const navigationSpacing = useMemo(() => {
    if (wideStackSpacingBreakpointReached) return WIDE_STACK_SPACING;
    if (optimalStackSpacingBreakpointReached) return OPTIMAL_STACK_SPACING;

    return MINIMAL_STACK_SPACING;
  }, [optimalStackSpacingBreakpointReached, wideStackSpacingBreakpointReached]);

  return (
    isLargerThanBreakpoint && (
      <VStack
        w="120px"
        flexGrow={1}
        my={10}
        boxSizing="border-box"
        borderRightWidth={1}
        borderRightStyle="solid"
        borderRightColor="gray.300"
        spacing={navigationSpacing}
        role="navigation"
      >
        <Box pb={3}>
          <LogoIcon />
        </Box>
        <Divider borderColor="gray.300" w={10} />
        {menuItems
          .filter(({ hidden }) => !hidden)
          .map(({ path, Icon, label }) => (
            <NavigationLink
              key={path}
              path={path}
              Icon={Icon}
              disabled={disabled}
              label={label}
            />
          ))}
        <NavigationLink
          path="https://catchapp.mobi/help-centre/"
          Icon={HelpIcon}
          label={t('help')}
          external
        />
        <Divider borderColor="gray.300" w={10} />
        <NavigationLink
          path="/integrations"
          Icon={CogsIcon}
          label={t('integrations')}
        />
        <NavigationLink
          path="/settings"
          Icon={CogIcon}
          label={t('zoom_integration_required_2')}
        />
      </VStack>
    )
  );
}

Navigation.defaultProps = {
  disabled: false,
};

Navigation.propTypes = {
  disabled: PropTypes.bool,
};

export default observer(Navigation);
