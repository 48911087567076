import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { t } from 'i18next';

import { defaultBreakpoint } from '@/hooks/useBreakpoint';
import OnboardingBilling from '@/pages/OnboardingBilling';

export default function TrialModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const size = useBreakpointValue({ base: 'full', [defaultBreakpoint]: '3xl' });
  const scrollBehavior = useBreakpointValue(
    {
      base: 'inside' as const,
      [defaultBreakpoint]: 'outside' as const,
    },
    defaultBreakpoint,
  );

  return (
    <>
      <Button borderWidth={1} borderColor="white" onClick={onOpen}>
        <Text variant="normal" textTransform="uppercase" px={3} fontSize={16}>
          {t('subscription_avoid_loss_expired_5')}
        </Text>
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        scrollBehavior={scrollBehavior}
      >
        <ModalOverlay
          bg="gray.overlay"
          backdropFilter="auto"
          backdropBlur="sm"
        />
        <ModalContent w="100%">
          <ModalBody p={0} w="100%">
            <OnboardingBilling type="modal" onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
