import { captureException } from '@sentry/react';
import { getTimezone } from 'countries-and-timezones';
import { Duration } from 'luxon';
import { types } from 'mobx-state-tree';

import { autoFlow } from '@/stores/storeUtils';
import { authorizedApi, stripeClientId } from '@/utils/apiUtils';

import { convertPulseTags } from './convertPulseTags';

export const googleCalendarProviders = ['gmail'];

export const microsoftCalendarProviders = [
  'ews',
  'eas',
  'graph',
  'hotmail',
  'exchange',
  'office365',
  'outlook',
  'custom',
  'yahoo',
  'generic',
  'IMAP',
  'nylas',
  'aol',
  'fastmail',
  'gandi',
  'gmx',
  'mail.ru',
  'yandex',
  'godaddy',
  'hover',
  'namecheap',
  'bluehost',
  'soverin',
  'zimbra',
  '123_reg',
  '126',
  'qq_enterprise',
  'qq',
  'foxmail',
  'aliyun',
  '163_qiye',
  '163',
  '163_ym',
  'yeah.net',
  '139',
];

export const appleCalendarProviders = ['icloud'];

const BusinessTeamSeats = types.model('BusinessTeamSeats', {
  idle: types.maybeNull(types.integer),
  active: types.maybeNull(types.integer),
  pending: types.maybeNull(types.integer),
  total: types.maybeNull(types.integer),
  occupied: types.maybeNull(types.integer),
});

const TrackingPixels = types.model('TrackingPixels', {
  google: types.maybeNull(types.string),
  meta: types.maybeNull(types.string),
});

const PulseConnection = types.model('PulseConnection', {
  app_url: types.maybeNull(types.string),
  created_at: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  portal_url: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  token_expiry: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  user_id: types.maybeNull(types.number),
});

const PulseTag = types.model('PulseTag', {
  id: types.number,
  name: types.string,
  formName: types.string,
});

const userUrl = types.model('PulseTag', {
  friendlyUrl: types.string,
  user_id: types.number,
});

const PaymentMethod = types.model('PaymentMethod', {
  last4: types.string,
  exp_month: types.integer,
  exp_year: types.integer,
  paymentMethodId: types.string,
});

const Reminder = types.model({
  active: types.boolean,
  id: types.maybeNull(types.integer),
  interval: types.enumeration(['hour', 'minute']),
  length: types.integer,
});

const ClientReminder = types.model({
  enabled: types.boolean,
  subject: types.string,
  body: types.string,
  reminders: types.array(
    types.model({
      id: types.number,
      interval: types.string,
      length: types.number,
      active: types.boolean,
      default: types.boolean,
    }),
  ),
});

export const Profile = types
  .model({
    address: types.string,
    businessId: types.maybeNull(types.integer),
    businessName: types.maybeNull(types.string),
    businessTeamSeats: types.maybeNull(BusinessTeamSeats),
    clientReminder: types.maybeNull(ClientReminder),
    connectedCalendarEmail: types.maybeNull(types.string),
    connectedCalendarProvider: types.maybeNull(types.string),
    description: types.optional(types.string, '', [null, undefined]),
    firstName: types.string,
    friendly_url: types.maybeNull(types.string),
    googleMeetConnectionEmail: types.maybeNull(types.string),
    googleMeetConnectionId: types.maybeNull(types.number),
    homeEmail: types.string,
    id: types.maybeNull(types.number),
    isBusinessAdmin: types.maybeNull(types.boolean),
    isEligibleForDiscount: types.maybeNull(types.boolean),
    isTwelveHour: types.maybeNull(types.boolean),
    language: types.maybeNull(types.string),
    lastName: types.string,
    lastOnboardingPage: types.maybeNull(
      types.union(types.string, types.number),
    ),
    defaultPhoneNumber: types.maybeNull(types.string),
    mobile: types.maybeNull(types.string),
    mondayApiKey: types.maybeNull(types.string),
    mondayConnectionId: types.maybeNull(
      types.union(types.string, types.number),
    ),
    name: types.maybeNull(types.string),
    paymentMethod: types.maybeNull(PaymentMethod),
    permissionCalendarAny: types.number,
    pixels: types.maybeNull(TrackingPixels),
    profilePic: types.maybeNull(types.number),
    profilePic_url: types.maybeNull(types.string),
    pulse_connection: types.maybeNull(PulseConnection),
    pulseTags: types.maybeNull(types.array(PulseTag)),
    reminderEmails24Hour: types.maybeNull(Reminder),
    reminderEmails2Hour: types.maybeNull(Reminder),
    reminderEmailsCustom: types.maybeNull(Reminder),
    skype: types.maybeNull(types.string),
    stripeCurrency: types.maybeNull(types.string),
    stripeCustomerId: types.maybeNull(types.string),
    stripeUserEmail: types.maybeNull(types.string),
    stripeUserId: types.maybeNull(types.string),
    teamsConnectionEmail: types.maybeNull(types.string),
    teamsConnectionId: types.maybeNull(types.integer),
    teams: types.maybeNull(types.string),
    tzIdentifier: types.maybeNull(types.string),
    tzOffset: types.maybeNull(types.number),
    user_url: types.maybeNull(userUrl),
    zapierApiKey: types.maybeNull(types.string),
    zoomConnectionEmail: types.maybeNull(types.string),
    zoomConnectionId: types.maybeNull(types.integer),
    zoom: types.maybeNull(types.string),
  })
  .views((self) => ({
    get isZoomConnected() {
      return Boolean(self.zoomConnectionId);
    },
    get isTeamsConnected() {
      return Boolean(self.teamsConnectionId);
    },
    get isMeetConnected() {
      return Boolean(self.googleMeetConnectionId);
    },
    get isExchangeWebServicesCalendarConnected() {
      return self.connectedCalendarProvider === 'ews';
    },
    get isExchangeActiveSyncServicesCalendarConnected() {
      return self.connectedCalendarProvider === 'eas';
    },
    get isOutlookCalendarConnected() {
      return self.connectedCalendarProvider === 'outlook';
    },

    get isGoogleCalendarConnected() {
      return googleCalendarProviders.includes(self.connectedCalendarProvider);
    },

    get isMicrosoftCalendarConnected() {
      return microsoftCalendarProviders.includes(
        self.connectedCalendarProvider,
      );
    },

    get isICloudCalendarConnected() {
      return appleCalendarProviders.includes(self.connectedCalendarProvider);
    },

    get isAnyCalendarConnected() {
      return Boolean(self.connectedCalendarProvider);
    },

    get isAnyVideoConferencingConnected() {
      return (
        self.isZoomConnected || self.isTeamsConnected || self.isMeetConnected
      );
    },

    get connectedCalendarName() {
      switch (self.connectedCalendarProvider) {
        case 'gmail':
          return 'Google';
        case 'ews':
          return 'Microsoft Office 365';
        case 'eas':
          return 'Microsoft Exchange';
        case 'outlook':
          return 'Microsoft Outlook';
        case 'icloud':
          return 'Apple Calendar';
        case 'custom':
          return 'Custom provider';
        case 'yahoo':
          return 'Yahoo';
        case 'generic':
          return 'IMAP';
        case 'IMAP':
          return 'IMAP';
        case 'nylas':
          return 'Nylas';
        case 'aol':
          return 'AOL Mail';
        case 'fastmail':
          return 'Fastmail';
        case 'gandi':
          return 'GandiMail';
        case 'gmx':
          return 'GMX';
        case 'mail.ru':
          return 'Mail.ru';
        case 'yandex':
          return 'Yandex.Mail';
        case 'godaddy':
          return 'GoDaddy Mail';
        case 'hover':
          return 'Hover.com';
        case 'namecheap':
          return 'Namecheap';
        case 'bluehost':
          return 'Bluehost';
        case 'soverin':
          return 'Soverin';
        case 'zimbra':
          return 'Zimbra';
        case '123_reg':
          return '123-reg';
        case '126':
          return '126';
        case 'qq_enterprise':
          return 'QQ Mail';
        case 'qq':
          return 'QQ Mail';
        case 'foxmail':
          return 'QQ Mail';
        case 'aliyun':
          return 'Aliyun';
        case '163_qiye':
          return 'NetEase 163';
        case '163':
          return 'NetEase 163';
        case '163_ym':
          return 'NetEase 163';
        case 'yeah.net':
          return 'Yeah.net';
        case '139':
          return '139.com';
        default:
          return '';
      }
    },

    get numberOfCalendars() {
      // permissionCalendarAny does not provide valid calendar number
      // this solution is a workaround
      //
      // Nylas allows only one calendar integration per user and thus
      // the calendar provider existence indirectly indicates the provider connected
      return self.connectedCalendarProvider ? 1 : 0;
    },

    get isPulseCrmConnected() {
      return !!self.pulse_connection?.id;
    },

    get displayedName() {
      return (
        `${self.firstName ?? ''} ${self.lastName ?? ''}`.trim() || self.name
      );
    },

    get registered() {
      return Boolean(self.homeEmail);
    },
  }));

const ProfileStore = types
  .model({
    profile: Profile,
  })
  .actions((self) =>
    autoFlow({
      *fetchProfile() {
        const userId = authorizedApi.defaults.headers.id;

        try {
          const response = yield authorizedApi.get(`/users/${userId}`);

          const [data] = response.data?.success ?? [];

          if (!data) {
            throw new Error('Profile data is missing');
          }

          if (!data.tzIdentifier?.trim()) {
            const defaultTimezoneName =
              new Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;

            const defaultTimezoneData = getTimezone(
              defaultTimezoneName ?? 'GMT',
            );

            data.tzIdentifier = (
              defaultTimezoneData.aliasOf ?? defaultTimezoneData.name
            ).trim();

            self.updateProfile(
              data.businessName ?? data.name,
              data.tzIdentifier,
              data.isTwelveHour,
              data.description,
            );
          }

          Object.assign(self.profile, convertPulseTags(data));
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }
        }
      },

      *updateProfile(name, timeZone, isTwelveHour, description, language) {
        const userId = authorizedApi.defaults.headers.id;

        const response = yield authorizedApi.post(`/users/${userId}`, {
          businessName: name,
          tzIdentifier: timeZone,
          language,
          isTwelveHour: isTwelveHour ?? self.profile.isTwelveHour ?? false,
          description: description ?? self.profile.description ?? '',
        });

        if (response?.data?.success) {
          self.profile.businessName = name || self.profile.businessName;
          self.profile.name = name || self.profile.name;
          self.profile.language = language || self.profile.language;
          self.profile.tzIdentifier = timeZone || self.profile.tzIdentifier;
          self.profile.isTwelveHour = isTwelveHour ?? self.profile.isTwelveHour;
          self.profile.description = description || self.profile.description;
        }
      },

      updateLocalProfile(payload) {
        self.profile = {
          ...self.profile,
          ...payload,
        };
      },

      *updateLastOnboardingPage(lastOnboardingPage) {
        const userId = authorizedApi.defaults.headers.id;

        const response = yield authorizedApi.post(`/users/${userId}`, {
          lastOnboardingPage,
        });

        if (response?.data?.success) {
          self.profile.lastOnboardingPage = String(lastOnboardingPage);
        }
      },

      *updateStripeCurrency(stripeCurrency) {
        const userId = authorizedApi.defaults.headers.id;

        const response = yield authorizedApi.post(`/users/${userId}/`, {
          stripeCurrency,
        });

        if (response?.data?.success) {
          self.profile.stripeCurrency = stripeCurrency;
        }
      },

      *getEmailReminder() {
        const userId = authorizedApi.defaults.headers.id;
        const response = yield authorizedApi.get(
          `/users/${userId}/emails/appointment-reminder`,
        );
        if (response?.data?.success?.['appointment-reminder']) {
          const value = response.data.success['appointment-reminder'];

          const [
            reminderEmails2Hour,
            reminderEmails24Hour,
            reminderEmailsCustom,
          ] = value.host.reminders;
          self.profile.clientReminder = value.client;
          self.profile.reminderEmails2Hour = reminderEmails2Hour;
          self.profile.reminderEmails24Hour = reminderEmails24Hour;
          self.profile.reminderEmailsCustom = reminderEmailsCustom;
        }
      },

      *updateEmailReminder({
        Hour24Reminder,
        Hour2Reminder,
        customReminder,
        customReminderTime,
        customReminderTimeType,
      }) {
        const userId = authorizedApi.defaults.headers.id;
        const duration = Duration.fromObject({
          [customReminderTimeType === 'hour' ? 'hours' : 'minutes']:
            customReminderTime,
        });

        const scaledTime = duration.as('minutes');

        const response = yield authorizedApi.post(
          `users/${userId}/emails/appointment-reminder`,
          {
            host: {
              enabled: true,
              reminders: [
                {
                  id: self.profile.reminderEmails2Hour?.id,
                  interval: 'hour',
                  length: 120,
                  active: Hour2Reminder,
                },
                {
                  id: self.profile.reminderEmails24Hour?.id,
                  interval: 'hour',
                  length: 1440,
                  active: Hour24Reminder,
                },
                {
                  id: self.profile.reminderEmailsCustom?.id,
                  interval: customReminderTimeType,
                  length: Number.parseInt(scaledTime, 10) || 1,
                  active: customReminder ?? false,
                },
              ],
            },
          },
        );

        if (response?.data?.success) {
          const [
            reminderEmails2Hour,
            reminderEmails24Hour,
            reminderEmailsCustom,
          ] = response.data.success['appointment-reminder'].host.reminders;
          self.profile.reminderEmails2Hour = reminderEmails2Hour;
          self.profile.reminderEmails24Hour = reminderEmails24Hour;
          self.profile.reminderEmailsCustom = reminderEmailsCustom;
        }
      },

      updateProfilePicUrl(url) {
        self.profile.profilePic_url = url;
      },

      *updateAccountDetails(firstName, lastName, homeEmail) {
        const userId = authorizedApi.defaults.headers.id;

        const response = yield authorizedApi.post(`/users/${userId}/`, {
          firstName,
          lastName,
          homeEmail,
        });

        if (response?.data?.success) {
          self.profile.firstName = firstName;
          self.profile.lastName = lastName;
          self.profile.homeEmail = homeEmail;
        }
      },

      *postMainLink(linkName) {
        const userId = authorizedApi.defaults.headers.id;

        const response = yield authorizedApi.post(
          `/users/${userId}/url/bookings`,
          {
            friendlyUrl: linkName,
          },
        );

        if (response?.data?.success) {
          self.profile.friendly_url = response.data.friendlyUrl;
        }
      },

      *updateVideoConferencing(name, communicator) {
        const userId = authorizedApi.defaults.headers.id;

        const fieldName = name === 'Zoom ID' ? 'zoom' : 'skype';

        const response = yield authorizedApi.post(`/users/${userId}/`, {
          [fieldName]: communicator,
        });

        if (response?.data?.success) {
          self.profile[fieldName] = communicator;
        }
      },

      *initializeStripeIntegration() {
        const userId = authorizedApi.defaults.headers.id;

        const response = yield authorizedApi.get(
          `/appointments/users/${userId}/stripe-state`,
        );

        const { success, state } = response.data ?? {};

        if (success) {
          const stripeSearchParams = new URLSearchParams({
            client_id: stripeClientId,
            // client_id: stripeClientTestId,
            state,
            scope: 'read_write',
            response_type: 'code',
            'stripe_user[email]': self.profile.homeEmail,
          });

          window.location.assign(
            `https://connect.stripe.com/oauth/authorize?${stripeSearchParams}`,
          );
        }
      },

      *updateGoogleTrackingPixel(googleTrackingId) {
        const userId = authorizedApi.defaults.headers.id;

        const response = yield authorizedApi.post(`/users/${userId}/`, {
          'pixels': {'google': googleTrackingId}
        });

        if (response?.data?.success) {
          self.profile.pixels.google = googleTrackingId;
        }
      },

      *updateMetaTrackingPixel(metaTrackingId) {
        const userId = authorizedApi.defaults.headers.id;

        const response = yield authorizedApi.post(`/users/${userId}/`, {
          'pixels': {'meta': metaTrackingId}
        });

        if (response?.data?.success) {
          self.profile.pixels.meta = metaTrackingId;
        }
      },

      requestPulseIntegration(formValues) {
        const userId = authorizedApi.defaults.headers.id;

        const pulseUrl = new URL(
          `${authorizedApi.defaults.baseURL}/pulse/integrate`,
        );

        const searchParams = {
          app_url: formValues.appUrl,
          portal_url: formValues.portalUrl,
          client_id: formValues.clientId,
          client_secret: formValues.clientSecret,
          user_id: userId,
        };

        Object.entries(searchParams).forEach(([paramKey, paramValue]) => {
          pulseUrl.searchParams.append(paramKey, paramValue);
        });

        window.location.assign(pulseUrl.toString());
      },

      *disconnectPulseIntegration() {
        const userId = authorizedApi.defaults.headers.id;

        yield authorizedApi.post('/pulse/disconnect', {
          user_id: userId,
        });

        self.profile.pulse_connection = null;
      },

      *disconnectStripe() {
        if (self.profile.stripeUserId) {
          const userId = authorizedApi.defaults.headers.id;

          // fetch is used due to redaxios not supporting CORS request mode
          const response = yield fetch(
            `${authorizedApi.defaults.baseURL}/appointments/users/${userId}/disconnect-stripe`,
            {
              method: 'post',
              mode: 'cors',
              headers: {
                ...authorizedApi.defaults.headers,
                'Access-Control-Allow-Origin': '*',
              },
            },
          );

          const responseData = yield response.json();

          if (responseData?.success) {
            self.profile.stripeUserId = null;
            self.profile.stripeUserEmail = null;
          }
        }
      },

      *disconnectCalendar() {
        yield authorizedApi.post(`/nylas/disconnect`);

        self.profile.connectedCalendarProvider = null;
        self.profile.connectedCalendarEmail = null;
      },

      *disconnectZoom() {
        yield authorizedApi.post('/zoom/disconnect');

        self.profile.zoomConnectionId = null;
      },

      *disconnectTeams() {
        yield authorizedApi.post('/teams/disconnect');

        self.profile.teamsConnectionId = null;
      },

      *disconnectMeet() {
        yield authorizedApi.post('/google/disconnect');

        self.profile.googleMeetConnectionEmail = null;
        self.profile.googleMeetConnectionId = null;
      },

      *disconnectMonday() {
        yield authorizedApi.delete('/monday/disconnect');

        self.profile.mondayConnectionId = null;
      },

      *fetchZapierApiKey() {
        try {
          const userId = authorizedApi.defaults.headers.id;
          const response = yield authorizedApi.get(`/zapier/generate-key`, {
            params: { user_id: userId },
          });
          self.profile.zapierApiKey = String(response.data?.api_key);
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }

          self.profile.zapierApiKey = null;
        }
      },
      connectMonday() {
        const userId = authorizedApi.defaults.headers.id;

        const authParams = new URLSearchParams({
          user_id: userId,
          redirect_url: window.location.href,
        });

        window.location.assign(
          `${
            import.meta.env.VITE_API_URL
          }/monday/authenticate?${authParams.toString()}`,
        );
      },

      *fetchMondayApiKey() {
        try {
          const userId = authorizedApi.defaults.headers.id;
          const response = yield authorizedApi.get(`/monday/generate-key`, {
            params: { user_id: userId },
          });
          self.profile.mondayApiKey = String(response.data?.api_key);
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }

          self.profile.mondayApiKey = null;
        }
      },

      *getUserCard() {
        try {
          let response;

          if (import.meta.env.DEV) {
            const [testPromise, nonTestPromise] = yield Promise.allSettled([
              authorizedApi.get(
                `/appointments/users/${authorizedApi.defaults.headers.id}/payment-details`,
                {
                  params: { stripeTest: true },
                },
              ),
              authorizedApi.get(
                `/appointments/users/${authorizedApi.defaults.headers.id}/payment-details`,
              ),
            ]);

            if (testPromise.status === 'fulfilled') {
              response = testPromise.value;
            } else {
              response = nonTestPromise.value;
            }
          } else {
            response = yield authorizedApi.get(
              `/appointments/users/${authorizedApi.defaults.headers.id}/payment-details`,
            );
          }

          const data = response.data?.success;

          if (!data) {
            throw new Error(response.data?.error);
          }
          self.profile.paymentMethod = data;
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }
        }
      },

      *changeUserCard(paymentMethodId) {
        try {
          const response = yield authorizedApi.post(
            `/appointments/users/${authorizedApi.defaults.headers.id}/payment-details`,
            {
              paymentMethodId,
              ...(import.meta.env.DEV ? { stripeTest: true } : {}),
            },
          );

          const data = response.data?.success;

          if (!data) {
            throw new Error(response.data?.error);
          }

          self.profile.paymentMethod = {
            // POST request has missing payment method ID in return data
            // as of 2023-02-01
            paymentMethodId,
            ...data,
          };

          return Promise.resolve();
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }

          return Promise.reject(error);
        }
      },
    }),
  );

const profileStore = ProfileStore.create({
  profile: {
    firstName: '',
    lastName: '',
    name: '',
    description: '',
    homeEmail: '',
    address: '',
    mobile: '',
    tzIdentifier: '',
    tzOffset: 0,
    permissionCalendarAny: 0,
    stripeCurrency: 'gbp',
    businessTeamSeats: {
      idle: 0,
      active: 0,
      pending: 0,
      total: 0,
      occupied: 0,
    },
  },
});

export default profileStore;
