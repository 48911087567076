import { Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { defaultBreakpoint } from '@/hooks/useBreakpoint';

export default function Panel({ children }) {
  return (
    <Flex
      direction="column"
      px={{ base: '1.5rem', [defaultBreakpoint]: '6.25rem' }}
      pt="1.5rem"
      pb={{ base: '1.875rem', [defaultBreakpoint]: '3.75rem' }}
      shadow={{ base: 'none', [defaultBreakpoint]: 'md' }}
      width="100%"
      height="auto"
    >
      {children}
    </Flex>
  );
}

Panel.propTypes = {
  children: PropTypes.node.isRequired,
};
