import React from 'react';

export default function CogsIcon() {
  return (
    <svg width="28px" height="28px" viewBox="0 0 28 22" version="1.1">
      <g
        id="Bookings-Dashboard-FINAL"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="scale(0.5)">
          <g
            id="CatchApp-Dashboard/Desktop-Final/MyEvents-(9)"
            transform="translate(-49.000000, -462.000000)"
            fill="currentColor"
          >
            <g id="CatchApp-Dashboard/Symbols/Menus">
              <g
                id="Icons/MainMenu/Settings/Grey3Fill-Copy-2"
                transform="translate(41.000000, 454.000000)"
              >
                <path
                  d="M18,28.7761983 C16.9965787,28.5477293 16.0564052,28.1510913 15.210811,27.6176161 L12.9289322,29.8994949 L10.1005051,27.0710678 L12.3823839,24.789189 C11.8489087,23.9435948 11.4522707,23.0034213 11.2238017,22 L8,22 L8,18 L11.224098,18 C11.4526676,16.9967592 11.8493267,16.0567087 12.3827621,15.2111892 L10.1005051,12.9289322 L12.9289322,10.1005051 L15.2110532,12.382626 C16.0565847,11.8490922 16.9966745,11.4523912 18,11.2238733 L18,8 L22,8 L22,11.2240733 C23.0033056,11.452643 23.9433368,11.8493187 24.7888039,12.3827689 L27.0710678,10.1005051 L29.8994949,12.9289322 L27.6172005,15.2112267 C28.1506612,16.0567364 28.5473264,16.996774 28.7758983,18 L32,18 L32,22 L28.7761946,22 C28.5477233,23.0034065 28.1510792,23.943567 27.6175787,24.7891515 L29.8994949,27.0710678 L27.0710678,29.8994949 L24.7890461,27.6174732 C23.9435163,28.150865 23.0034014,28.5474776 22,28.7759984 L22,32 L18,32 L18,28.7761983 L18,28.7761983 Z M19.9994662,27 C23.8602222,27 27,23.8602222 27,20.0005338 C27,16.1408454 23.8602222,13 19.9994662,13 C16.1397778,13 13,16.1408454 13,20.0005338 C13,23.8602222 16.1397778,27 19.9994662,27 Z M14,20.0004575 C14,16.6921532 16.6912381,14 19.9995425,14 C23.3087619,14 26,16.6921532 26,20.0004575 C26,23.3087619 23.3087619,26 19.9995425,26 C16.6912381,26 14,23.3087619 14,20.0004575 Z M16,20.000305 C16,22.2058412 17.7941588,24 19.999695,24 C22.2058412,24 24,22.2058412 24,20.000305 C24,17.7947688 22.2058412,16 19.999695,16 C17.7941588,16 16,17.7947688 16,20.000305 Z"
                  id="Combined-Shape"
                />
              </g>
            </g>
          </g>
        </g>
        <g transform="scale(0.5) translate(12, 19)">
          <g
            id="CatchApp-Dashboard/Desktop-Final/MyEvents-(9)"
            transform="translate(-49.000000, -462.000000)"
            fill="currentColor"
          >
            <g id="CatchApp-Dashboard/Symbols/Menus">
              <g
                id="Icons/MainMenu/Settings/Grey3Fill-Copy-2"
                transform="translate(41.000000, 454.000000)"
              >
                <path
                  d="M18,28.7761983 C16.9965787,28.5477293 16.0564052,28.1510913 15.210811,27.6176161 L12.9289322,29.8994949 L10.1005051,27.0710678 L12.3823839,24.789189 C11.8489087,23.9435948 11.4522707,23.0034213 11.2238017,22 L8,22 L8,18 L11.224098,18 C11.4526676,16.9967592 11.8493267,16.0567087 12.3827621,15.2111892 L10.1005051,12.9289322 L12.9289322,10.1005051 L15.2110532,12.382626 C16.0565847,11.8490922 16.9966745,11.4523912 18,11.2238733 L18,8 L22,8 L22,11.2240733 C23.0033056,11.452643 23.9433368,11.8493187 24.7888039,12.3827689 L27.0710678,10.1005051 L29.8994949,12.9289322 L27.6172005,15.2112267 C28.1506612,16.0567364 28.5473264,16.996774 28.7758983,18 L32,18 L32,22 L28.7761946,22 C28.5477233,23.0034065 28.1510792,23.943567 27.6175787,24.7891515 L29.8994949,27.0710678 L27.0710678,29.8994949 L24.7890461,27.6174732 C23.9435163,28.150865 23.0034014,28.5474776 22,28.7759984 L22,32 L18,32 L18,28.7761983 L18,28.7761983 Z M19.9994662,27 C23.8602222,27 27,23.8602222 27,20.0005338 C27,16.1408454 23.8602222,13 19.9994662,13 C16.1397778,13 13,16.1408454 13,20.0005338 C13,23.8602222 16.1397778,27 19.9994662,27 Z M14,20.0004575 C14,16.6921532 16.6912381,14 19.9995425,14 C23.3087619,14 26,16.6921532 26,20.0004575 C26,23.3087619 23.3087619,26 19.9995425,26 C16.6912381,26 14,23.3087619 14,20.0004575 Z M16,20.000305 C16,22.2058412 17.7941588,24 19.999695,24 C22.2058412,24 24,22.2058412 24,20.000305 C24,17.7947688 22.2058412,16 19.999695,16 C17.7941588,16 16,17.7947688 16,20.000305 Z"
                  id="Combined-Shape"
                />
              </g>
            </g>
          </g>
        </g>
        <g transform="scale(0.5) translate(32, 7)">
          <g
            id="CatchApp-Dashboard/Desktop-Final/MyEvents-(9)"
            transform="translate(-49.000000, -462.000000)"
            fill="currentColor"
          >
            <g id="CatchApp-Dashboard/Symbols/Menus">
              <g
                id="Icons/MainMenu/Settings/Grey3Fill-Copy-2"
                transform="translate(41.000000, 454.000000)"
              >
                <path
                  d="M18,28.7761983 C16.9965787,28.5477293 16.0564052,28.1510913 15.210811,27.6176161 L12.9289322,29.8994949 L10.1005051,27.0710678 L12.3823839,24.789189 C11.8489087,23.9435948 11.4522707,23.0034213 11.2238017,22 L8,22 L8,18 L11.224098,18 C11.4526676,16.9967592 11.8493267,16.0567087 12.3827621,15.2111892 L10.1005051,12.9289322 L12.9289322,10.1005051 L15.2110532,12.382626 C16.0565847,11.8490922 16.9966745,11.4523912 18,11.2238733 L18,8 L22,8 L22,11.2240733 C23.0033056,11.452643 23.9433368,11.8493187 24.7888039,12.3827689 L27.0710678,10.1005051 L29.8994949,12.9289322 L27.6172005,15.2112267 C28.1506612,16.0567364 28.5473264,16.996774 28.7758983,18 L32,18 L32,22 L28.7761946,22 C28.5477233,23.0034065 28.1510792,23.943567 27.6175787,24.7891515 L29.8994949,27.0710678 L27.0710678,29.8994949 L24.7890461,27.6174732 C23.9435163,28.150865 23.0034014,28.5474776 22,28.7759984 L22,32 L18,32 L18,28.7761983 L18,28.7761983 Z M19.9994662,27 C23.8602222,27 27,23.8602222 27,20.0005338 C27,16.1408454 23.8602222,13 19.9994662,13 C16.1397778,13 13,16.1408454 13,20.0005338 C13,23.8602222 16.1397778,27 19.9994662,27 Z M14,20.0004575 C14,16.6921532 16.6912381,14 19.9995425,14 C23.3087619,14 26,16.6921532 26,20.0004575 C26,23.3087619 23.3087619,26 19.9995425,26 C16.6912381,26 14,23.3087619 14,20.0004575 Z M16,20.000305 C16,22.2058412 17.7941588,24 19.999695,24 C22.2058412,24 24,22.2058412 24,20.000305 C24,17.7947688 22.2058412,16 19.999695,16 C17.7941588,16 16,17.7947688 16,20.000305 Z"
                  id="Combined-Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
