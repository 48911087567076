import React from 'react';

export default function ExpandIcon() {
  return (
    <svg width="10px" height="6px" viewBox="0 0 10 6" version="1.1">
      <defs>
        <polygon id="path-1" points="25 23 15 23 19.9997232 17" />
      </defs>
      <g
        id="Bookings-Dashboard-FINAL"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CatchApp-Dashboard/Desktop-Final/Settings-(12)"
          transform="translate(-850.000000, -300.000000)"
        >
          <g
            id="Website/Buttons/CTABlackOnOrange-Copy-14"
            transform="translate(855.000000, 303.000000) rotate(-360.000000) translate(-855.000000, -303.000000) translate(835.000000, 283.000000)"
          >
            <g
              id="Stroke-1"
              transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "
            >
              <use fill="#FFFFFF" xlinkHref="#path-1" />
              <use fill="currentColor" xlinkHref="#path-1" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
