import { captureException } from '@sentry/react';
import { types } from 'mobx-state-tree';

import profileStore from '@/stores/ProfileStore/ProfileStore';
import { autoFlow } from '@/stores/storeUtils';
import {
  USER_SEAT_STATUS,
  USER_SEAT_TYPE,
} from '@/stores/UsersStore/constants';
import { authorizedApi } from '@/utils/apiUtils';
import i18n from '@/utils/i18n';

import { transformUserToUserSeat } from './transformUserToUserSeat';

export const UserSeat = types.model('UserSeat', {
  id: types.maybeNull(types.union(types.number, types.string)),
  user: types.maybeNull(types.union(types.number, types.string)),
  name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  status: types.enumeration(Object.values(USER_SEAT_STATUS)),
  type: types.maybeNull(types.enumeration(Object.values(USER_SEAT_TYPE))),
});

export const NewUserSeat = types.model('NewUserSeat', {
  id: types.union(types.number, types.string),
  email: types.maybeNull(types.string),
});

export const UsersStore = types
  .model('UsersStore', {
    isSubmitting: types.boolean,
    userSeats: types.array(UserSeat),
    userSeatCost: types.model({
      seatCount: types.maybeNull(types.number),
      totalCost: types.maybeNull(types.string),
      costPerSeat: types.maybeNull(types.string),
      currency: types.maybeNull(types.string),
    }),
    userSeatCostProcessing: types.boolean,
  })
  .views((self) => ({
    get activeUserSeats() {
      return self.userSeats.filter(
        ({ status }) => status === USER_SEAT_STATUS.ACTIVE,
      );
    },
    get idleUserSeats() {
      return self.userSeats.filter(
        ({ status }) => status === USER_SEAT_STATUS.IDLE,
      );
    },
    get pendingUserSeats() {
      return self.userSeats.filter(
        ({ status }) => status === USER_SEAT_STATUS.PENDING,
      );
    },
    get occupiedUserSeats() {
      return self.userSeats.filter(
        ({ status }) =>
          status === USER_SEAT_STATUS.PENDING ||
          status === USER_SEAT_STATUS.ACTIVE,
      );
    },
    get userSeatsCounters() {
      return {
        active: self.activeUserSeats?.length ?? 0,
        pending: self.pendingUserSeats?.length ?? 0,
        idle: self.idleUserSeats?.length ?? 0,
        total: self.userSeats?.length ?? 0,
        occupied: self.occupiedUserSeats?.length ?? 0,
      };
    },
  }))
  .actions((self) =>
    autoFlow({
      *fetchUsersSeats() {
        const { profile } = profileStore;

        if (!profile?.businessId) {
          return;
        }

        const { businessId } = profile;

        self.isSubmitting = true;

        try {
          const response = yield authorizedApi.get(`/business/${businessId}`);

          const { data } = response ?? {};

          if (data?.seats?.length) {
            const convertedUserToUserSeat = data.seats.map((item) => {
              const { user, status, email, id, type } = item;

              return transformUserToUserSeat({
                seatId: id,
                user,
                status,
                email,
                type,
              });
            });

            self.userSeats = convertedUserToUserSeat.filter((item) => !!item);
          }
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }
        }
        self.isSubmitting = false;
      },

      *inviteUser({ profile, userSeat, reFetch = true }) {
        try {
          yield authorizedApi.post(
            `/business/${profile.businessId}/seats/${userSeat.id}/invite-user`,
            {},
            {
              params: {
                email: userSeat.email,
              },
            },
          );

          if (reFetch) {
            // TODO: in case of success re-fetch seats data
            yield self.fetchUsersSeats();
          }
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }
        }
      },

      *addUsersSeats({ profile, newUsers }) {
        try {
          yield self.fetchUsersSeats();

          const nonEmptyNewUsers = newUsers.filter(({ email }) => email);

          if (self.idleUserSeats.length !== nonEmptyNewUsers.length) {
            throw new Error(i18n.t('idle_seat_difference'));
          }

          yield Promise.all(
            self.idleUserSeats.map(({ id }, index) =>
              self.inviteUser({
                profile,
                userSeat: {
                  id,
                  email: nonEmptyNewUsers[index].email,
                },
                reFetch: false,
              }),
            ),
          );

          yield self.fetchUsersSeats();
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }
        }
      },

      setEmptySeats(amount) {
        self.userSeatCost.seatCount = parseInt(amount, 10);
      },

      *removeUserFromSeat({ profile, userSeat }) {
        try {
          yield authorizedApi.post(
            `/business/${profile.businessId}/seats/remove-user`,
            {},
            {
              params: {
                email: userSeat.email,
              },
            },
          );

          // TODO: in case of success re-fetch seats data
          yield self.fetchUsersSeats();
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }
        }
      },

      setUserSeatCostProcessing(newUserSeatCostProcessing) {
        self.userSeatCostProcessing = newUserSeatCostProcessing;
      },

      *calculateUserSeatCost({ signal, currency, newUserSeats, period, idle }) {
        if (!self.userSeatCost.currency) {
          if (currency) {
            self.assignUserSeatCostCurrency(currency);
          }
        }

        const seatEmails = newUserSeats
          ? newUserSeats.filter(({ email }) => email).map(({ email }) => email)
          : [];

        const { data } = yield authorizedApi.get('/business/calculate-cost', {
          fetch: (resource, options) =>
            fetch(resource, {
              ...options,
              signal,
            }),
          params: {
            currency,
            emails: seatEmails,
            billingPeriod: period || 'monthly',
            idle,
          },
          paramsSerializer: (params) => {
            const queryArray = [];

            for (const [key, value] of Object.entries(params)) {
              if (Array.isArray(value)) {
                for (const arrayValue of value) {
                  if (typeof arrayValue !== 'undefined') {
                    queryArray.push(
                      `${key}[]=${encodeURIComponent(arrayValue)}`,
                    );
                  }
                }
              } else if (typeof value !== 'undefined') {
                queryArray.push(`${key}=${encodeURIComponent(value)}`);
              }
            }

            return queryArray.join('&');
          },
        });

        return data;
      },

      setNewUserSeatCost(data) {
        self.userSeatCost.seatCount = data.added_seat_count;
        self.userSeatCost.totalCost = data.total_cost;
        self.userSeatCost.costPerSeat = data.cost_per_seat;
      },

      assignUserSeatCostCurrency(currency) {
        self.userSeatCost.currency = currency;
      },

      *deleteSeat(seatId) {
        try {
          yield authorizedApi.delete(
            `/business/${profileStore.profile.businessId}/seats/${seatId}`,
          );

          return { success: true };
        } catch (error) {
          return { success: false, error };
        }
      },

      *deleteSeats({ count }) {
        if (!Number.isInteger(+count))
          return {
            success: false,
            error: { message: i18n.t('nothing_to_do') },
          };

        const seatsIdToDelete = self.idleUserSeats
          .slice(0, +count)
          .map((seat) => seat.id);

        const result = yield Promise.all(
          seatsIdToDelete.map((seatId) => self.deleteSeat(seatId)),
        );

        if (result.some((item) => !item.success)) {
          return {
            success: false,
            error: { message: i18n.t('failure_seats_delete') },
          };
        }

        return { success: true };
      },
    }),
  );

const usersStore = UsersStore.create({
  isSubmitting: false,
  userSeats: [],
  userSeatCost: {},
  userSeatCostProcessing: false,
});

export default usersStore;
