import { ModalHeader, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function AddSeatsSummaryStepHeader() {
  const { t } = useTranslation();

  return (
    <ModalHeader px={4} w="100%">
      <Text
        borderBottomColor="gray.300"
        borderBottomWidth={1}
        letterSpacing="wide"
        pb={4}
        textTransform="uppercase"
        variant="black"
      >
        {t('add_users')}
      </Text>
    </ModalHeader>
  );
}
