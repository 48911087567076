import { extendTheme, theme as defaultTheme } from '@chakra-ui/react';

import Checkmark from '@/components/atoms/Checkmark';

const typographyVariants = {
  light: {
    fontFamily: '"Source Sans Pro Light", sans-serif',
  },
  regular: {
    fontFamily: '"Source Sans Pro", sans-serif',
  },
  semibold: {
    fontFamily: '"Source Sans Pro Semibold", sans-serif',
  },
  bold: {
    fontFamily: '"Source Sans Pro Bold", sans-serif',
  },
  black: {
    fontFamily: '"Source Sans Pro Black", sans-serif',
  },
};

const buttonCommonStyle = {
  border: 0,
  borderRadius: 0,
  px: '3rem',
  py: '1.5rem',
  textTransform: 'uppercase',
  fontSize: '1rem',
  color: 'white',
  _focus: {
    boxShadow: 'none',
  },
};

const theme = extendTheme({
  fontSizes: {
    '2xs': '0.6875rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  sizes: {
    ...defaultTheme.sizes,
    '6xl': '80rem',
  },
  breakpoints: {
    ...defaultTheme.breakpoints,
    '2xl': '90em',
    '3xl': '96em',
  },
  colors: {
    primary: {
      normal: '#f19837',
      active: '#e37b09',
      disabled: '#e6e6e6',
      text: '#ffffff',
      activeLight: '#fff4e7',
      textPressed: '#cc6f08',
    },
    secondary: {
      normal: '#12cac0',
      active: '#10b6ad',
      disabled: '#e6e6e6',
      text: '#ffffff',
      activeLight: '#e7f4ff',
      textPressed: '#0ea29a',
    },
    primaryBlue: {
      normal: '#1146f5',
      active: '#093adb',
      disabled: '#e6e6e6',
      text: '#ffffff',
      activeLight: '#f0f3ff',
    },
    primaryGray: {
      normal: '#afafaf',
      active: '#bbbbbb',
      disabled: '#e6e6e6',
      text: '#ffffff',
      activeLight: '#f4f4f4',
      textPressed: '#dddddd',
    },
    // old color schemes below for compatibility
    blue: {
      link: '#000aff',
      linkDark: '#0000ee',
    },
    cornflower: {
      '01': '#f0f3ff',
      '04': '#5d80f9',
      '05': '#3763f7',
      '06': '#1146f5',
      '07': '#093adb',
      '09': '#072ba6',
    },
    gray: {
      100: '#f2f2f2',
      150: '#e6e6e6',
      200: '#e1e1e1',
      '200opacity20': '#e1e1e133',
      '200opacity50': '#e1e1e180',
      250: '#d8d8d8',
      300: '#c8c8c8',
      350: '#bbbbbb',
      400: '#afafaf',
      450: '#969696',
      500: '#4b4b4b',
      600: '#323232',
      overlay: '#ffffff98',
      secondary: '#808080',
    },
    orange: {
      100: '#fff4e6',
      150: '#ffe4cc',
      400: '#ff9400',
      '400opacity20': '#ff940033',
      450: '#f19837',
      500: '#e68600',
    },
    teal: {
      '01': '#eef9f8',
      '05': '#12cac0',
      '06': '#10b6ad',
      200: '#39cac1',
      400: '#33b7af',
    },
    red: {
      '01': '#fbe4e4',
      '06': '#b41818',
      400: '#e02020',
      500: '#b41818',
    },
    green: {
      500: '#309f70',
      600: '#68b43b',
    },
  },
  textStyles: {
    code: {
      fontFamily: '"PT Mono Regular", sans-serif',
    },
  },
  fonts: {
    body: '"Source Sans Pro", sans-serif',
    heading: '"Source Sans Pro Semibold", sans-serif',
  },
  shadows: {
    outline: '0 0 0 3px rgba(230, 134, 0, 0.6)',
    md: '-1px 9px 25px 0 #e1e1e1',
    popover: '6px 6px 8px 0px #c8c8c8',
    mobile: '9px 1px 25px 0 #e1e1e1',
    mobileBottom: 'inset 0 -9px 16px -9px #e1e1e1',
    focusButton: '0 0 0 3px rgba(230, 134, 0, 0.6)',
  },
  borderRadius: {
    base: '5px',
  },
  styles: {
    global: {
      body: {
        bg: 'white',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
      },
      html: {
        height: '100%',
        width: '100%',
      },
      main: {
        height: '100%',
        width: '100%',
      },
    },
  },
  zIndices: {
    select: 2000,
  },
  components: {
    Select: {
      variants: {
        outline: {
          field: {
            borderRadius: 0,
            h: '3.125rem',
            _focus: {
              borderColor: 'orange.400',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Checkbox: {
      sizes: {
        md: {
          control: {
            width: '30px',
            height: '30px',
            borderRadius: 0,
          },
          iconSize: '30px',
          icon: () => <Checkmark width={30} height={30} />,
        },
      },
      defaultProps: {
        colorScheme: 'orange',
      },
    },
    Button: {
      variants: {
        link: {
          _active: {
            color: 'orange',
          },
          _hover: {
            color: 'orange',
          },
        },
        outline: ({ colorScheme: c }) => ({
          bg: `${c}.text`,
          borderColor: `${c}.normal`,
          borderRadius: 0,
          borderStyle: 'solid',
          borderWidth: 1,
          boxShadow: 'none',
          color: `${c}.normal`,
          fontFamily: '"Source Sans Pro Semibold", sans-serif',
          fontSize: 'sm',
          h: 'unset',
          letterSpacing: 'normal',
          lineHeight: '18px',
          paddingX: 4,
          paddingY: 2,
          textTransform: 'uppercase',

          _focus: {
            boxShadow: 'none',
          },

          _hover: {
            bg: `${c}.activeLight`,
            borderColor: `${c}.active`,
            boxShadow: 'none',
            color: `${c}.active`,
          },

          _active: {
            bg: `${c}.active`,
            borderColor: `${c}.active`,
            boxShadow:
              'inset 4px 4px 4px 0 rgba(204, 111, 8, 0.2), inset -4px -4px 4px 0 rgba(204, 111, 8, 0.2)',
            color: `${c}.text`,
          },

          _disabled: {
            borderColor: `${c}.disabled`,
            color: `${c}.disabled`,
            boxShadow: 'none',
            opacity: 1,
          },

          '&[disabled]:active': {
            borderColor: `${c}.disabled`,
            boxShadow: 'none',
          },
        }),
        solid: ({ colorScheme: c }) => ({
          bg: `${c}.normal`,
          borderColor: `${c}.normal`,
          borderRadius: 0,
          borderStyle: 'solid',
          borderWidth: 1,
          boxShadow: 'none',
          color: `${c}.text`,
          fontFamily: '"Source Sans Pro Semibold", sans-serif',
          fontSize: 'sm',
          h: 'unset',
          letterSpacing: 'normal',
          paddingX: 4,
          paddingY: 2,
          textTransform: 'uppercase',

          _focus: {
            boxShadow: 'none',
          },

          _hover: {
            bg: `${c}.active`,
            borderColor: `${c}.active`,
            boxShadow: 'none',
          },

          _active: {
            bg: `${c}.active`,
            borderColor: `${c}.active`,
            boxShadow:
              'inset 4px 4px 4px 0 rgba(204, 111, 8, 0.2), inset -4px -4px 4px 0 rgba(204, 111, 8, 0.2)',
          },

          _disabled: {
            bg: `${c}.disabled`,
            borderColor: `${c}.disabled`,
            boxShadow: 'none',
            opacity: 1,
          },

          '&[disabled]:active, &[disabled]:focus, &[disabled]:hover': {
            bg: `${c}.disabled`,
            borderColor: `${c}.disabled`,
            boxShadow: 'none',
          },
        }),
        solidRounded: ({ colorScheme: c }) => ({
          bg: `${c}.normal`,
          borderRadius: 4,
          borderStyle: 'solid',
          boxShadow: `inset 0px -2px 0px 0px var(--chakra-colors-${c}-active)`,
          color: `${c}.text`,
          fontFamily: '"Source Sans Pro Semibold", sans-serif',
          fontSize: 'sm',
          h: 'unset',
          letterSpacing: 'normal',
          paddingX: 4,
          paddingY: 2,
          textTransform: 'uppercase',
          transition: 'all 125ms ease',

          _focus: {
            boxShadow: `inset 0px 0px 0px 0px var(--chakra-colors-${c}-active)`,
          },

          _hover: {
            bg: `${c}.active`,
            boxShadow: `inset 0px 0px 0px 0px var(--chakra-colors-${c}-active)`,
          },

          _active: {
            bg: `${c}.active`,
            borderColor: `${c}.active`,
            boxShadow: `inset 0px 0px 0px 0px var(--chakra-colors-${c}-active), inset 4px 4px 4px 0 rgba(204, 111, 8, 0.2), inset -4px -4px 4px 0 rgba(204, 111, 8, 0.2)`,
          },

          _disabled: {
            bg: `${c}.disabled`,
            boxShadow: 'none',
            opacity: 1,
          },

          '&[disabled]:active, &[disabled]:focus, &[disabled]:hover': {
            bg: `${c}.disabled`,
            borderColor: `${c}.disabled`,
            boxShadow: 'none',
          },
        }),
        text: ({ colorScheme: c }) => ({
          bg: `${c}.text`,
          borderRadius: 0,
          borderStyle: 'solid',
          borderWidth: 0,
          boxShadow: 'none',
          color: `${c}.normal`,
          fontFamily: '"Source Sans Pro Semibold", sans-serif',
          fontSize: 'sm',
          h: 'unset',
          letterSpacing: 'normal',
          lineHeight: '18px',
          paddingX: 4,
          paddingY: 2,
          textTransform: 'uppercase',

          _focus: {
            boxShadow: 'none',
          },

          _hover: {
            bg: `${c}.text`,
            boxShadow: 'none',
            color: `${c}.active`,
            textDecoration: 'none',
          },

          _active: {
            bg: `${c}.text`,
            boxShadow: 'none',
            color: `${c}.textPressed`,
          },

          _disabled: {
            borderColor: `${c}.disabled`,
            color: `${c}.disabled`,
            boxShadow: 'none',
            opacity: 1,
          },

          '&[disabled]:active': {
            borderColor: `${c}.disabled`,
            boxShadow: 'none',
          },
        }),

        // styles below are deprecated, do not use them
        'no-background': {
          bg: 'transparent',
          border: 0,
          color: 'gray.400',
          _active: {
            color: 'black',
            boxShadow: 'none !important',
            outline: 'none !important',
          },
          _focus: {
            boxShadow: 'none !important',
            outline: 'none !important',
          },
          _focusWithin: {
            boxShadow: 'none !important',
            outline: 'none !important',
          },
          _focusVisible: {
            boxShadow: 'none !important',
            outline: 'none !important',
          },
          '&:active:focus': {
            boxShadow: 'none !important',
            outline: 'none !important',
          },
          _hover: {
            bg: 'gray.100',
          },
        },
        ghost: {
          color: 'orange.500',
          height: '100%',
          _disabled: {
            color: 'gray.400',
          },
          _hover: {
            bg: 'gray.100',
            color: 'orange.400',
          },
          '&&[disabled]:hover': {
            bg: 'gray.100',
            color: 'gray.300',
          },
        },
        submit: {
          bg: 'orange.400',
          _disabled: {
            bg: 'orange.400',
          },
          _hover: {
            bg: 'orange.500',
          },
          '&&[disabled]:hover': {
            bg: 'orange.500',
          },
          ...buttonCommonStyle,
        },
        submitDark: {
          bg: 'orange.450',
          _disabled: {
            bg: 'gray.400',
          },
          _hover: {
            bg: 'orange.500',
          },
          '&&[disabled]:hover': {
            bg: 'gray.300',
          },
          ...buttonCommonStyle,
        },
        'submit-enhanced': {
          bg: 'gray.350',
          _disabled: {
            bg: 'gray.350',
          },
          _hover: {
            bg: 'gray.350',
          },
          '&&[disabled]:hover': {
            bg: 'gray.350',
          },
          ...buttonCommonStyle,
        },
        cancel: {
          bg: 'gray.300',
          _disabled: {
            bg: 'gray.300',
          },
          _hover: {
            bg: 'gray.300',
          },
          '&&[disabled]:hover': {
            bg: 'gray.300',
          },
          ...buttonCommonStyle,
        },
        'cancel-enhanced': {
          ...buttonCommonStyle,
          border: '1px',
          borderColor: 'gray.300',
          color: 'gray.300',
          bg: 'white',
          _disabled: {
            borderColor: 'gray.300',
            color: 'gray.300',
          },
          _hover: {
            borderColor: 'gray.400',
            color: 'gray.400',
          },
          '&&[disabled]:hover': {
            borderColor: 'gray.300',
            color: 'gray.300',
          },
        },
        'cancel-red': {
          bg: 'red.400',
          _disabled: {
            bg: 'red.400',
          },
          _hover: {
            bg: 'red.500',
          },
          '&&[disabled]:hover': {
            bg: 'red.500',
          },
          ...buttonCommonStyle,
        },
        teal: {
          bg: 'teal.200',
          _disabled: {
            bg: 'teal.200',
          },
          _hover: {
            bg: 'teal.400',
          },
          '&&[disabled]:hover': {
            bg: 'teal.400',
          },
          ...buttonCommonStyle,
        },
        'orange-border': {
          ...buttonCommonStyle,
          bg: 'transparent',
          border: '1px',
          borderColor: 'orange.400',
          color: 'orange.400',
          _hover: {
            borderColor: 'orange.500',
            color: 'orange.500',
          },
        },
        border: {
          ...buttonCommonStyle,
          bg: 'white',
          borderColor: 'orange.500',
          borderWidth: 1,
          borderStyle: 'solid',
          color: 'orange.500',
          _disabled: {
            bg: 'white',
            borderColor: 'gray.400',
            color: 'gray.400',
          },
          _hover: {
            bg: 'white',
            borderColor: 'orange.400',
            color: 'orange.400',
          },
          '&&[disabled]:hover': {
            bg: 'white',
            borderColor: 'gray.300',
            color: 'gray.300',
          },
        },
      },
    },
    Popover: {
      baseStyle: {
        popper: {
          maxWidth: 'fit-content',
          width: 'fit-content',
        },
      },
    },
    Tooltip: {
      baseStyle: {
        bg: 'white',
        borderRadius: 0,
        borderColor: 'orange.400',
        borderWidth: 1,
        color: 'orange.400',
        pointerEvents: 'all',
      },
    },
    Heading: {
      variants: { ...typographyVariants },
    },
    Text: {
      variants: { ...typographyVariants },
    },
    FormLabel: {
      variants: {
        form: {
          marginBottom: '0',
          fontSize: 'sm',
          fontWeight: 'semibold',
        },
        ...typographyVariants,
      },
    },
    Textarea: {
      variants: {
        rectangular: {
          borderColor: 'gray.200',
          borderWidth: 1,
          borderRadius: 0,
          borderStyle: 'solid',
          _hover: {
            borderColor: 'gray.300',
          },
          _focus: {
            borderColor: 'orange.400',
            boxShadow: 'none',
          },
          '&[aria-invalid="true"]': {
            borderColor: 'red.500',
            borderWidth: 2,
          },
          '&[aria-invalid="true"]:focus': {
            borderColor: 'orange.400',
          },
        },
      },
    },
    Input: {
      baseStyle: {
        height: '3.125rem',
        color: 'red',
      },
      variants: {
        rectangular: {
          field: {
            borderColor: 'gray.200',
            borderWidth: 1,
            borderRadius: 0,
            borderStyle: 'solid',
            _hover: {
              borderColor: 'gray.300',
            },
            _focus: {
              borderColor: 'orange.400',
              boxShadow: 'none',
            },
            '&[aria-invalid="true"]': {
              borderColor: 'red.500',
              borderWidth: 2,
            },
            '&[aria-invalid="true"]:focus': {
              borderColor: 'orange.400',
            },
          },
        },
      },
    },
    Link: {
      baseStyle: {
        boxShadow: 'none !important',
        outline: 'none !important',
        _active: {
          color: 'black',
          boxShadow: 'none !important',
          outline: 'none !important',
        },
        _focus: {
          boxShadow: 'none !important',
          outline: 'none !important',
        },
        _focusWithin: {
          boxShadow: 'none !important',
          outline: 'none !important',
        },
        _focusVisible: {
          boxShadow: 'none !important',
          outline: 'none !important',
        },
        '&:active:focus': {
          boxShadow: 'none !important',
          outline: 'none !important',
        },
      },
    },
    Modal: {
      sizes: {
        full: {
          dialog: {
            borderRadius: 0,
          },
        },
      },
    },
  },
});

export default theme;
