/* eslint-disable react/no-array-index-key */

import { Flex, Image, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import LocationMarkerTransparent from '@/assets/Location Marker transparent.svg';

const bubbleData = [
  {
    start: DateTime.now().set({ hour: 13, minute: 0, second: 0 }),
    end: DateTime.now().set({ hour: 13, minute: 30, second: 0 }),
    meeting: 'Signing a contract with Voxlytics',
    location: 'TechCo office',
  },
  {
    start: DateTime.now().set({ hour: 11, minute: 0, second: 0 }),
    end: DateTime.now().set({ hour: 12, minute: 0, second: 0 }),
    meeting: 'Prospect meeting with John Smith',
    location: 'Zoom',
  },
  {
    start: DateTime.now().set({ hour: 9, minute: 0, second: 0 }),
    end: DateTime.now().set({ hour: 10, minute: 0, second: 0 }),
    meeting: 'Sprint retrospective',
    location: 'Zoom',
  },
  {
    start: DateTime.now().set({ hour: 14, minute: 0, second: 0 }),
    end: DateTime.now().set({ hour: 15, minute: 30, second: 0 }),
    meeting: 'Sales Meeting',
    location: 'TechCo office',
  },
] as const;

const bubblePositions = [
  {
    bottom: 698,
    right: 164,
    wide: true,
  },
  {
    bottom: 560,
    right: 44,
  },
  {
    bottom: 441,
    right: 246,
  },
  {
    bottom: 347,
    right: 58,
  },
] as const;

const itemVariants = {
  visible: (index: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: index * 0.1,
      duration: 0.4,
      bounce: 0.75,
      damping: 7.5,
      type: 'spring',
    },
  }),
  hidden: { opacity: 0, y: 70 },
  exit: { opacity: 0 },
  hover: { scale: 1.05, transition: { ease: 'backOut' } },
};

export default function ClientBubbles() {
  const { i18n } = useTranslation();

  const { width, height } = useWindowSize();

  const baseFontSize = useMemo(() => {
    const heightFontSize = Math.min(width / 3, 480, 480) / 480;
    const widthFontSize = Math.min(height, 900) / 900;

    return Math.min(heightFontSize, widthFontSize);
  }, [height, width]);

  return (
    <Flex as={motion.div} position="absolute" inset={0} zIndex={3}>
      {bubbleData.map((data, index) => {
        const position = bubblePositions[index];

        return (
          <Flex
            as={motion.div}
            bg="white"
            borderColor="gray.secondary"
            borderStyle="solid"
            borderWidth={1}
            borderRadius={16}
            bottom={`${position.bottom * baseFontSize}px`}
            display="flex"
            flexDirection="column"
            key={index}
            maxW={'wide' in position && position?.wide ? '308px' : '228px'}
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            custom={index}
            whileHover="hover"
            px={`${1.25 * baseFontSize}rem`}
            py={`${baseFontSize}rem`}
            position="fixed"
            right={`${position.right * baseFontSize}px`}
            rowGap={1}
          >
            <Text
              color="cornflower.05"
              fontFamily="Poppins"
              fontSize={`${0.6875 * baseFontSize}rem`}
              fontWeight="semibold"
              letterSpacing="normal"
              lineHeight={1.2}
              userSelect="none"
            >
              {data.start
                .setLocale(i18n.resolvedLanguage ?? '')
                .toLocaleString(DateTime.TIME_24_SIMPLE)}{' '}
              -{' '}
              {data.end
                .setLocale(i18n.resolvedLanguage ?? '')
                .toLocaleString(DateTime.TIME_24_SIMPLE)}
            </Text>
            <Text
              color="black"
              fontFamily="Poppins"
              letterSpacing="normal"
              lineHeight={1.2}
              userSelect="none"
              fontSize={`${baseFontSize}rem`}
            >
              {data.meeting}
            </Text>
            <Flex alignItems="center" columnGap={2}>
              <Image h={`${2 * baseFontSize}rem`} src={LocationMarkerTransparent} />
              <Text
                color="gray.secondary"
                fontFamily="Poppins"
                letterSpacing="normal"
                lineHeight={1.2}
                userSelect="none"
                fontSize={`${baseFontSize}rem`}
              >
                {data.location}
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
}

ClientBubbles.displayName = 'ClientBubbles';
