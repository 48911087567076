/* eslint-disable no-param-reassign */

import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import { sanitize } from 'isomorphic-dompurify';
import { DateTime, Duration, Interval } from 'luxon';
import { useSelector } from 'react-redux';

import type { Poll } from '@/components/pages/MyEvents/poll/PollInterface';
import { timeZones } from '@/components/pages/Settings/Commons';
import { eventLocationTypeToRawLocationType } from '@/stores/EventStore/eventLocationTypeToRawLocationType';
import { rawLocationTypeToEventLocationType } from '@/stores/EventStore/rawLocationTypeToEventLocationType';
import profileStore from '@/stores/ProfileStore/ProfileStore';
import { authorizedApi } from '@/utils/apiUtils';
import removeHours from '@/utils/removeHours';

import type { RootState } from './rootState';

type TimeSlot = { from: string; to: string };

export interface PollState {
  differentTimesForSelectedDates: boolean;
  isOpen: boolean;
  isSendingRequest: boolean;
  isSummaryModalOpen: boolean;
  pickedDays: Record<string, TimeSlot[]>;
  pollSummaryData?: Poll & {
    pickedDays: PollState['pickedDays'];
    sharingLink: string;
  };
}

const initialState: PollState = {
  differentTimesForSelectedDates: false,
  isOpen: false,
  isSendingRequest: false,
  isSummaryModalOpen: false,
  pickedDays: {},
};

export const createAppointmentPoll = createAsyncThunk<
  PollState['pollSummaryData'],
  { data: Poll },
  {
    rejectValue: AxiosError | Error;
  }
>('poll/createAppointmentPoll', async ({ data }, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const times: Array<readonly [number, number]> = Object.entries(
    state.poll.pickedDays,
  ).flatMap(([date, timeSlots]) =>
    timeSlots.map(
      ({ from }) =>
        [
          DateTime.fromFormat(`${date} ${from}`, 'yyyy-MM-dd hh:mm', {
            zone: removeHours(data.timezone.label),
          }).toSeconds(),
          DateTime.fromFormat(`${date} ${from}`, 'yyyy-MM-dd hh:mm', {
            zone: removeHours(data.timezone.label),
          })
            .plus({ minutes: data.duration })
            .toSeconds(),
        ] as const,
    ),
  );

  const preparedData = {
    times,
    eventName: data.name,
    fundamentalType: 'group',
    message: sanitize(data.description),
    flexible: true,
    customLocationName:
      eventLocationTypeToRawLocationType({
        location: data.location,
        customLocationName: data.locationDescription ?? '',
      })?.trim() ?? '',
    isAppointmentPoll: true,
    invitedUsers: [
      {
        id: (authorizedApi.defaults.headers?.id ?? '') as string,
        target: profileStore.profile.homeEmail,
      },
    ],
    timezone: removeHours(data.timezone.label),
  };

  try {
    const { data: responseData } = await authorizedApi.post<{
      success: { sharingLink: string };
    }>('/catchup/create', preparedData);

    if (!responseData.success) {
      return thunkApi.rejectWithValue(new Error('Poll creation failed'));
    }

    return {
      ...data,
      pickedDays: state.poll.pickedDays,
      sharingLink: responseData.success.sharingLink,
    };
  } catch (error) {
    return thunkApi.rejectWithValue(error as AxiosError);
  }
});

export const editAppointmentPoll = createAsyncThunk<
  PollState['pollSummaryData'],
  { data: Poll; id: number },
  {
    rejectValue: AxiosError | Error;
  }
>('poll/editAppointmentPoll', async ({ data, id }, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const times: Array<readonly [number, number]> = Object.entries(
    state.poll.pickedDays,
  ).flatMap(([date, timeSlots]) =>
    timeSlots.map(
      ({ from }) =>
        [
          DateTime.fromFormat(`${date} ${from}`, 'yyyy-MM-dd hh:mm', {
            zone: removeHours(data.timezone.label),
          }).toSeconds(),
          DateTime.fromFormat(`${date} ${from}`, 'yyyy-MM-dd hh:mm', {
            zone: removeHours(data.timezone.label),
          })
            .plus({ minutes: data.duration })
            .toSeconds(),
        ] as const,
    ),
  );

  const { bookingDetails, currentBookingId } = state.bookings.pending;

  const currentBookingDetails =
    typeof currentBookingId !== 'undefined'
      ? bookingDetails[currentBookingId]
      : undefined;

  const preparedData = {
    times,
    eventName: data.name,
    fundamentalType: 'group',
    message: sanitize(data.description),
    flexible: true,
    customLocationName:
      eventLocationTypeToRawLocationType({
        location: data.location,
        customLocationName: data.locationDescription ?? '',
      })?.trim() ?? '',
    isAppointmentPoll: true,
    invitedUsers: currentBookingDetails?.data?.invited ?? [
      {
        id: (authorizedApi.defaults.headers?.id ?? '') as string,
        target: profileStore.profile.homeEmail,
      },
    ],
    timezone: removeHours(data.timezone.label),
  };

  try {
    const userId = (authorizedApi.defaults.headers?.id ?? '') as string;

    const { data: responseData } = await authorizedApi.post<{
      success: { sharingLink: string };
    }>(`/users/${userId}/catchups/polls/${id}`, preparedData);

    if (!responseData.success) {
      return thunkApi.rejectWithValue(new Error('Poll creation failed'));
    }

    return {
      ...data,
      pickedDays: state.poll.pickedDays,
      sharingLink: responseData.success.sharingLink,
    };
  } catch (error) {
    return thunkApi.rejectWithValue(error as AxiosError);
  }
});

export const modalSlice = createSlice({
  name: 'poll',
  initialState,
  reducers: {
    addPickedDay(
      state,
      action: PayloadAction<{ day: string; duration: number }>,
    ) {
      const [firstDayAvailable] = Object.keys(state.pickedDays).sort(
        (dayA, dayB) => dayA.localeCompare(dayB),
      );

      if (!state.pickedDays[firstDayAvailable]) {
        const startDuration = Duration.fromObject({
          hours: 9,
        });

        state.pickedDays[action.payload.day] = [
          {
            from: startDuration.toFormat('hh:mm'),
            to: startDuration
              .plus({ minutes: action.payload.duration })
              .toFormat('hh:mm'),
          },
        ];
      } else {
        state.pickedDays[action.payload.day] = Array.from(
          state.pickedDays[firstDayAvailable] ?? [],
        ).map(({ from, to }) => ({ from, to }));
      }
    },
    removePickedDay(state, action: PayloadAction<{ day: string }>) {
      delete state.pickedDays[action.payload.day];
    },
    openPollModal(state) {
      state.isOpen = true;
    },
    closePollModal(state) {
      state.isOpen = false;
    },
    openSummaryModal(state) {
      state.isSummaryModalOpen = true;
    },
    closeSummaryModal(state) {
      state.isSummaryModalOpen = false;
    },
    modifyTimeSlot(
      state,
      action: PayloadAction<{
        key: 'from' | 'to';
        index: number;
        value: string;
        date?: string;
      }>,
    ) {
      if (state.differentTimesForSelectedDates && action.payload.date) {
        state.pickedDays[action.payload.date][action.payload.index][
          action.payload.key
        ] = action.payload.value;
      } else {
        Object.keys(state.pickedDays).forEach((day) => {
          state.pickedDays[day][action.payload.index][action.payload.key] =
            action.payload.value;
        });
      }
    },
    addTimeSlot(
      state,
      action: PayloadAction<{ duration: number; date?: string }>,
    ) {
      if (state.differentTimesForSelectedDates && action.payload.date) {
        const [lastDayValue] =
          state.pickedDays[action.payload.date]?.slice(-1) ?? [];

        const startDuration = lastDayValue?.to
          ? Duration.fromISOTime(lastDayValue.to)
          : Duration.fromObject({
              hours: 9,
            });

        state.pickedDays[action.payload.date].push({
          from: startDuration.toFormat('hh:mm'),
          to: startDuration
            .plus({ minutes: action.payload.duration })
            .rescale()
            .set({ days: 0 })
            .toFormat('hh:mm'),
        });
      } else {
        Object.keys(state.pickedDays).forEach((day) => {
          const [lastDayValue] = state.pickedDays[day]?.slice(-1) ?? [];

          const startDuration = lastDayValue?.to
            ? Duration.fromISOTime(lastDayValue.to)
            : Duration.fromObject({
                hours: 9,
              });

          state.pickedDays[day].push({
            from: startDuration.toFormat('hh:mm'),
            to: startDuration
              .plus({ minutes: action.payload.duration })
              .rescale()
              .set({ days: 0 })
              .toFormat('hh:mm'),
          });
        });
      }
    },
    removeTimeSlot(
      state,
      action: PayloadAction<{ index: number; date?: string }>,
    ) {
      if (state.differentTimesForSelectedDates && action.payload.date) {
        state.pickedDays[action.payload.date].splice(action.payload.index, 1);
        if (state.pickedDays[action.payload.date].length === 0) {
          delete state.pickedDays[action.payload.date];
        }
      } else {
        Object.keys(state.pickedDays).forEach((day) => {
          state.pickedDays[day].splice(action.payload.index, 1);
          if (state.pickedDays[day].length === 0) {
            delete state.pickedDays[day];
          }
        });
      }
    },
    rescaleTimeSlots(state, action: PayloadAction<{ duration: number }>) {
      const startDuration = Duration.fromObject({
        hours: 9,
      });

      Object.keys(state.pickedDays).forEach((day) => {
        state.pickedDays[day] = [
          {
            from: startDuration.toFormat('hh:mm'),
            to: startDuration
              .plus({ minutes: action.payload.duration })
              .toFormat('hh:mm'),
          },
        ];
      });
    },
    clearPickedDays(state) {
      state.pickedDays = {};
    },
    setTimesFromTimestamps(
      state,
      action: PayloadAction<{
        times: Array<[number, number]>;
        timezone?: string;
      }>,
    ) {
      const pickedDays = action.payload.times.reduce<PollState['pickedDays']>(
        (accumulatedPickedDays, [fromTimestamp, toTimestamp]) => {
          const fromDateTime = DateTime.fromSeconds(fromTimestamp, {
            zone: action.payload.timezone,
          });
          const toDateTime = DateTime.fromSeconds(toTimestamp, {
            zone: action.payload.timezone,
          });

          const isoDateKey = fromDateTime.toISODate();

          return {
            ...accumulatedPickedDays,
            [isoDateKey]: (accumulatedPickedDays[isoDateKey] ?? []).concat({
              from: fromDateTime.toFormat('HH:mm'),
              to: toDateTime.toFormat('HH:mm'),
            }),
          };
        },
        {},
      );

      state.pickedDays = {};

      const sameTimesForSelectedDates = Object.values(pickedDays).every(
        (timeSlots, _, otherTimeSlotsArrays) =>
          otherTimeSlotsArrays.every((otherTimeSlots) =>
            timeSlots.every(
              (timeSlot, timeSlotIndex) =>
                timeSlot?.from === otherTimeSlots[timeSlotIndex]?.from &&
                timeSlot?.to === otherTimeSlots[timeSlotIndex]?.to,
            ),
          ),
      );

      state.differentTimesForSelectedDates = !sameTimesForSelectedDates;

      Object.entries(pickedDays).forEach(([date, timeSlots]) => {
        state.pickedDays[date] = timeSlots;
      });
    },
    setDifferentTimesForSelectedDates(state) {
      state.differentTimesForSelectedDates = true;
    },
    resetDifferentTimesForSelectedDates(state) {
      state.differentTimesForSelectedDates = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createAppointmentPoll.pending, (state) => {
        state.isSendingRequest = true;
      })
      .addCase(createAppointmentPoll.fulfilled, (state, action) => {
        state.isOpen = false;
        state.isSendingRequest = false;
        state.pollSummaryData = action.payload;
      })
      .addCase(createAppointmentPoll.rejected, (state) => {
        state.isSendingRequest = false;
      })
      .addCase(editAppointmentPoll.pending, (state) => {
        state.isSendingRequest = true;
      })
      .addCase(editAppointmentPoll.fulfilled, (state, action) => {
        state.isOpen = false;
        state.isSendingRequest = false;
        state.pollSummaryData = action.payload;
      })
      .addCase(editAppointmentPoll.rejected, (state) => {
        state.isSendingRequest = false;
      });
  },
});

export const {
  addPickedDay,
  addTimeSlot,
  closePollModal,
  modifyTimeSlot,
  openPollModal,
  removePickedDay,
  removeTimeSlot,
  rescaleTimeSlots,
  clearPickedDays,
  setDifferentTimesForSelectedDates,
  resetDifferentTimesForSelectedDates,
  setTimesFromTimestamps,
  openSummaryModal,
  closeSummaryModal,
} = modalSlice.actions;

export const useIsPollModalOpen = () =>
  useSelector((state: RootState) => state.poll.isOpen);

export const useIsPollSummaryModalOpen = () =>
  useSelector((state: RootState) => state.poll.isSummaryModalOpen);

export const usePickedDays = () =>
  useSelector((state: RootState) => state.poll.pickedDays);
export const useHasTimeSlots = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.poll.pickedDays,
      (pickedDays) => pickedDays[Object.keys(pickedDays)[0]]?.length >= 0,
    ),
  );
export const usePickedCommonTimeSlots = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.poll.pickedDays,
      (state: RootState) => state.poll.differentTimesForSelectedDates,
      (pickedDays, differentTimesForSelectedDates) => {
        if (differentTimesForSelectedDates) {
          return [];
        }

        const [firstDayAvailable] = Object.keys(pickedDays).sort((dayA, dayB) =>
          dayA.localeCompare(dayB),
        );

        return pickedDays[firstDayAvailable] ?? [];
      },
    ),
  );

export const useSlotCount = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.poll.pickedDays,
      (pickedDays) => Object.values(pickedDays).flat().length,
    ),
  );

export const useSelectedDaysCount = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.poll.pickedDays,
      (pickedDays) => Object.keys(pickedDays).length,
    ),
  );

export const useDifferentTimesForSelectedDates = () =>
  useSelector((state: RootState) => state.poll.differentTimesForSelectedDates);

export const useIsSendingRequest = () =>
  useSelector((state: RootState) => state.poll.isSendingRequest);

export const usePollFromAppointmentPoll = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.pending.bookings,
      (state: RootState) => state.bookings.pending.currentBookingId,
      (state: RootState) => state.bookings.pending.bookingDetails,
      (bookings, currentBookingId, bookingDetails) => {
        if (typeof currentBookingId === 'undefined') return undefined;

        const currentBooking = bookings.find(
          ({ catchup_id }) => catchup_id === currentBookingId,
        );

        if (!currentBooking) return undefined;

        const currentBookingDetails = bookingDetails[currentBookingId];

        if (!currentBookingDetails?.data) return undefined;

        const { data: details } = currentBookingDetails;

        const [firstEvent] = details.events ?? [];

        const eventDuration =
          (firstEvent?.to_timestamp ?? 0) - (firstEvent?.from_timestamp ?? 0);

        const duration = Number.isNaN(eventDuration)
          ? 0
          : Duration.fromObject({ seconds: eventDuration }).as('minutes');

        const [rawLocationType, , ...rawLocationNameParts] = (
          details.location?.location ?? ''
        ).split(/(:\s*)/);

        const rawLocationName = (rawLocationNameParts ?? []).join('');

        const locationType = rawLocationTypeToEventLocationType({
          rawLocationType,
        }) as string;

        const defaultTimezoneName = new Intl.DateTimeFormat()?.resolvedOptions()
          ?.timeZone;

        const poll: Poll = {
          name: currentBooking.name,
          duration,
          location: locationType,
          timezone:
            timeZones.find(({ label }) => label.includes(details.timezone)) ||
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            timeZones.find(({ label }) =>
              label.includes(
                profileStore.profile.tzIdentifier ?? defaultTimezoneName,
              ),
            )!,
          description: currentBookingDetails.data.initial_message ?? '',
          locationDescription: rawLocationName ?? '',
        };

        return poll;
      },
    ),
  );

export const useTimesFromAppointmentPoll = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.pending.currentBookingId,
      (state: RootState) => state.bookings.pending.bookingDetails,
      (currentBookingId, bookingDetails) => {
        if (typeof currentBookingId === 'undefined') return undefined;

        const currentBookingDetails = bookingDetails[currentBookingId];

        if (!currentBookingDetails?.data) return undefined;

        const { data: details } = currentBookingDetails;

        return details.events.map(
          ({ from_timestamp, to_timestamp }) =>
            [from_timestamp, to_timestamp] as [number, number],
        );
      },
    ),
  );

export const usePollSummaryData = () =>
  useSelector((state: RootState) => state.poll.pollSummaryData);

export const useOverlappingRanges = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.poll.pickedDays,
      (pickedDays) =>
        Object.fromEntries(
          Object.entries(pickedDays).map(([date, timeSlots]) => {
            const dateTime = DateTime.fromISO(date).startOf('day');

            const intervals = timeSlots.map(({ from, to }) =>
              Interval.fromDateTimes(
                dateTime.plus(Duration.fromISOTime(from)),
                dateTime.plus(Duration.fromISOTime(to)),
              ),
            );

            return [
              date,
              intervals.map((interval, intervalIndex, otherIntervals) =>
                otherIntervals.some((otherInterval, otherIntervalIndex) =>
                  intervalIndex === otherIntervalIndex
                    ? false
                    : interval.overlaps(otherInterval),
                ),
              ),
            ];
          }),
        ),
    ),
  );

export const useHasOverlappingRanges = () => {
  const overlappingRanges = useOverlappingRanges();

  return Object.values(overlappingRanges)
    .flat()
    .some((value) => value);
};

export const useHasSameTimesForSelectedDates = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.poll.pickedDays,
      (pickedDays) =>
        Object.values(pickedDays).every((timeSlots, _, otherTimeSlotsArrays) =>
          otherTimeSlotsArrays.every((otherTimeSlots) =>
            timeSlots.every(
              (timeSlot, timeSlotIndex) =>
                timeSlot?.from === otherTimeSlots[timeSlotIndex]?.from &&
                timeSlot?.to === otherTimeSlots[timeSlotIndex]?.to,
            ),
          ),
        ),
    ),
  );

export default modalSlice.reducer;
