import { AddSeatsSummaryStepBody } from './atoms/AddSeatsSummaryStepBody';
import { AddSeatsSummaryStepFooter } from './atoms/AddSeatsSummaryStepFooter';
import { AddSeatsSummaryStepHeader } from './atoms/AddSeatsSummaryStepHeader';

export default {
  Header: AddSeatsSummaryStepHeader,
  Body: AddSeatsSummaryStepBody,
  Footer: AddSeatsSummaryStepFooter,
  key: Symbol('AddSeatsSummaryStep'),
};
