import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Duration } from 'luxon';

import {
  type AppointmentReminder,
  type EmailClient,
  type EmailHost,
  EmailTemplate,
} from '@/components/pages/Settings/organisms/RemindersTypes';
import { authorizedApi } from '@/utils/apiUtils';

import type { RootState } from './rootState';

export interface EmailReminderResponse {
  success: {
    'appointment-reminder': AppointmentReminder;
  };
}
interface InitialStateTypes {
  supported_variables: string[];
}

const initialState: InitialStateTypes = {
  supported_variables: [],
};

export const unifyIntervalDurations = <
  ReminderData extends EmailClient | EmailHost,
>(
  reminderData: ReminderData,
): ReminderData => ({
  ...reminderData,
  reminders: reminderData.reminders.map((reminder) => ({
    ...reminder,
    length: Duration.fromObject({ minutes: reminder.length ?? 0 }).as(
      reminder.interval,
    ),
  })),
});

export const fetchEmailReminder = createAsyncThunk(
  'emailReminder/fetch',
  async () => {
    const userId = (authorizedApi.defaults.headers?.id as string) ?? '';

    const response = await authorizedApi.get<EmailReminderResponse>(
      `/users/${userId}/emails/appointment-reminder`,
    );

    response.data.success['appointment-reminder'] = {
      ...response.data.success['appointment-reminder'],
      client: unifyIntervalDurations(
        response.data.success['appointment-reminder'].client,
      ),
      host: unifyIntervalDurations(
        response.data.success['appointment-reminder'].host,
      ),
    };

    return response;
  },
);

export const postEmailReminderHost = createAsyncThunk(
  'emailReminder/post',
  async (appointmentReminder: { host: EmailHost }) => {
    const userId = (authorizedApi.defaults.headers?.id as string) ?? '';

    const response = await authorizedApi.post<EmailReminderResponse>(
      `/users/${userId}/emails/appointment-reminder`,
      {
        ...appointmentReminder,
        host: {
          ...appointmentReminder.host,
          reminders: appointmentReminder.host.reminders.map((reminder) => ({
            ...reminder,
            length: Duration.fromObject({
              [reminder.interval]: reminder.length,
            }).as('minutes'),
          })),
        },
      },
    );

    return response;
  },
);

export const postEmailReminderClient = createAsyncThunk(
  'postEmailReminderClient/post',
  async (appointmentReminder: { client: EmailClient }) => {
    const userId = (authorizedApi.defaults.headers?.id as string) ?? '';

    const response = await authorizedApi.post<EmailReminderResponse>(
      `/users/${userId}/emails/appointment-reminder`,
      {
        ...appointmentReminder,
        client: {
          ...appointmentReminder.client,
          reminders: appointmentReminder.client.reminders.map((reminder) => ({
            ...reminder,
            length: Duration.fromObject({
              [reminder.interval]: reminder.length,
            }).as('minutes'),
          })),
        },
      },
    );

    return response;
  },
);

export const postEmailTemplate = createAsyncThunk(
  'postEmailTemplate/post',
  async (appointmentReminder: { client: EmailTemplate }) => {
    const userId = (authorizedApi.defaults.headers?.id as string) ?? '';

    const response = await authorizedApi.post<EmailReminderResponse>(
      `/users/${userId}/emails/appointment-reminder`,
      {
        client: {
          enabled: appointmentReminder.client.enabled,
          body: appointmentReminder.client.body,
          subject: appointmentReminder.client.subject,
        },
      },
    );

    return response;
  },
);

export const removeEmailReminder = createAsyncThunk(
  'emailReminder/removeEmailReminder',
  async (id: number) => {
    const userId = (authorizedApi.defaults.headers?.id as string) ?? '';

    const response = await authorizedApi.delete(
      `/users/${userId}/emails/appointment-reminder/${id}`,
    );

    return response;
  },
);

const emailReminderSlice = createSlice({
  name: 'emailReminder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmailReminder.fulfilled, (state, action) => ({
      ...state,

      supported_variables:
        action.payload.data.success['appointment-reminder'].supported_variables,
    }));
  },
});

export const variables = (state: RootState) =>
  state.emailReminderReducer.supported_variables;

export default emailReminderSlice.reducer;
