import React from 'react';

export default function EventsIcon() {
  return (
    <svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1">
      <g
        id="Bookings-Dashboard-FINAL"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CatchApp-Dashboard/Desktop-Final/MyEvents-(9)"
          transform="translate(-49.000000, -263.000000)"
          fill="currentColor"
        >
          <g id="CatchApp-Dashboard/Symbols/Menus">
            <g
              id="Icons/MainMenu/Settings/Grey3Fill-Copy-4"
              transform="translate(41.000000, 254.000000)"
            >
              <rect id="Rectangle" x="8" y="9" width="24" height="1" />
              <rect id="Rectangle-Copy-3" x="8" y="16" width="24" height="1" />
              <rect id="Rectangle-Copy-4" x="8" y="23" width="24" height="1" />
              <rect id="Rectangle-Copy-5" x="8" y="30" width="24" height="1" />
              <rect id="Rectangle" x="8" y="12" width="12" height="2" rx="1" />
              <rect
                id="Rectangle-Copy"
                x="8"
                y="19"
                width="9"
                height="2"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-2"
                x="8"
                y="26"
                width="15"
                height="2"
                rx="1"
              />
              <rect id="Rectangle" x="25" y="11" width="7" height="4" />
              <rect id="Rectangle-Copy-6" x="25" y="18" width="7" height="4" />
              <rect id="Rectangle-Copy-7" x="25" y="25" width="7" height="4" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
