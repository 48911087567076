const INIT_STATE = {
  auth_error: false,
  auth_error_message: '',
  cardComplete: false,
  clientSecret: '',
  couponDiscount: '',
  couponDuration: null,
  couponError: false,
  couponInput: '',
  couponPrice: 0,
  currency: 'gbp',
  price: 10,
  processing: false,
  requiresAttn: false,
  showCoupon: false,
  successCoupon: false,
  monthlyPrice: 0,
  yearlyPrice: 0,
};

const paymentReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case 'PROCESS_PAYMENT':
      return {
        ...state,
        processing: true,
      };
    case 'CANCEL_PROCESS_PAYMENT':
      return {
        ...state,
        processing: false,
      };
    case 'REQUIRES_ATTN':
      return {
        ...state,
        requiresAttn: true,
        clientSecret: action.payload,
      };
    case 'FAILED_AUTHENTICATION':
      return {
        ...state,
        auth_error: true,
        auth_error_message: action.payload,
      };
    case 'SET_SUBSCRIPTION_CURRENCY':
      return {
        ...state,
        currency: action.payload.currency,
        price: action.payload.price,
      };
    case 'SET_SUBSCRIPTION_DATA':
      return {
        ...state,
        currency: action.payload.currency,
        monthlyPrice: action.payload.monthlyPrice,
        yearlyPrice: action.payload.yearlyPrice,
      };
    case 'UPDATE_COUPON':
      return {
        ...state,
        couponInput: action.payload,
      };
    case 'COUPON_SUCCESS':
      return {
        ...state,
        successCoupon: true,
        couponPrice:
          state.price - (action.payload.percent_off / 100) * state.price,
        couponError: false,
        couponDuration: action.payload.duration_in_months,
        couponDiscount: action.payload.percent_off,
      };
    case 'SHOW_HIDE_COUPON':
      return {
        ...state,
        showCoupon: !state.showCoupon,
      };
    case 'COUPON_ERROR':
      return {
        ...state,
        couponError: true,
      };
    case 'CARD_FIELD_STATUS':
      return {
        ...state,
        cardComplete: action.payload,
      };
    default:
      return state;
  }
};

export default paymentReducer;
