import {
  Box,
  Button,
  Flex,
  ModalFooter,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import AvailabilityUpdatedIcon from '@/components/atoms/AvailabilityUpdatedIcon';
import addUsersModalStore from '@/components/pages/Users/organisms/AddUsersModal/addUsersModalStore';
import useBreakpoint from '@/hooks/useBreakpoint';
import { useUsersStore } from '@/stores/RootStore';

function SeatsAddedStepHeader() {
  return null;
}

const SeatsAddedStepBody = observer(() => {
  const { t } = useTranslation();

  const isLargerThanBreakpoint = useBreakpoint();
  const { isSubmitting } = useUsersStore();
  const {
    error: { message },
  } = addUsersModalStore;

  if (isSubmitting) {
    return (
      <Flex direction="column" alignItems="center" justifyContent="center">
        <Box>
          <Spinner size="xl" />
        </Box>
      </Flex>
    );
  }

  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      {message ? (
        <>
          <Text fontSize={isLargerThanBreakpoint ? '3xl' : '2xl'}>
            {t('something_went_wrong')}
          </Text>
          <Box m="20px 0">
            <Text color="red.400">{message}</Text>
          </Box>
        </>
      ) : (
        <>
          <Text fontSize={isLargerThanBreakpoint ? '3xl' : '2xl'}>
            {t('success_users_added')}
          </Text>
          <Box m="20px 0">
            <AvailabilityUpdatedIcon />
          </Box>
        </>
      )}
    </Flex>
  );
});

function SeatsAddedStepFooter({ onNextClick }) {
  const { t } = useTranslation();

  return (
    <ModalFooter as={Flex} direction="row" justifyContent="center">
      <Button variant="submit" onClick={onNextClick}>
        {t('finish')}
      </Button>
    </ModalFooter>
  );
}

SeatsAddedStepFooter.propTypes = {
  onNextClick: func.isRequired,
};

export default {
  Header: SeatsAddedStepHeader,
  Body: SeatsAddedStepBody,
  Footer: SeatsAddedStepFooter,
  key: Symbol('SeatsAddedStep'),
};
