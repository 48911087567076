import { Flex, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';

import { useProfileStore } from '@/stores/RootStore';

function BillingInformationDiscountBanner(): JSX.Element {
  const profileStore = useProfileStore();

  const { t } = useTranslation();

  return (
    <>
      {profileStore.profile.isEligibleForDiscount && (
        <Flex
          bg="orange.100"
          direction="column"
          gridGap={1}
          py={3}
          px={4}
          width={{ base: '100%', sm: 'auto' }}
        >
          <Text fontSize="sm" lineHeight={1.25} textAlign="left">
            <b>{t('activate_account_today_1')} </b>
            <Trans i18nKey="activate_account_today_2" />
          </Text>
        </Flex>
      )}
    </>
  );
}

export default observer(BillingInformationDiscountBanner);
