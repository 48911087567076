/* eslint-disable no-param-reassign, camelcase */
import { captureException } from '@sentry/react';
import { types } from 'mobx-state-tree';

import { autoFlow } from '@/stores/storeUtils';
import { authorizedApi, fetchStatus } from '@/utils/apiUtils';

import corporateUsersEventsStore from '../CorporateUsersEventsStore/CorporateUsersEventsStore';
import { Event } from './Event';
import { transformEventToStoreFormat } from './transformEventToStoreFormat';
import { transformStoreFormatToEvent } from './transformStoreFormatToEvent';

export const ROW_UPDATED_ID = -42;

export const DummyEvent = types.model({
  id: types.identifierNumber,
  name: types.string,
});

export const EventsStore = types
  .model({
    events: types.array(types.union(Event, DummyEvent)),
    fetchStatus: types.enumeration(Object.values(fetchStatus)),
  })
  .views((self) => ({
    get hasFetchedAtLeastOnce() {
      return self.fetchStatus !== fetchStatus.noStatus;
    },

    get isFetching() {
      return self.fetchStatus === fetchStatus.pending;
    },

    get isDoneFetching() {
      return self.fetchStatus === fetchStatus.done;
    },
  }))
  .actions((self) =>
    autoFlow({
      *fetchAllEvents() {
        if (fetchStatus.pending === self.fetchStatus) {
          return;
        }

        self.fetchStatus = fetchStatus.pending;

        try {
          const response = yield authorizedApi.get('/appointments');
          const data = response.data?.success;
          self.events =
            data?.map((item) => transformEventToStoreFormat(item)) ?? [];
        } catch (error) {
          self.events = [];

          if (!error?.response) {
            captureException(error);
          }
        } finally {
          self.fetchStatus = fetchStatus.done;
        }
      },

      *fetchDetails({ id }) {
        const response = yield authorizedApi.get(`/appointments/${id}`);

        const [data] = response.data?.success ?? [];

        const fetchedEvent = transformEventToStoreFormat(data);

        return fetchedEvent;
      },

      *addEvent({ values }) {
        try {
          self.events.push({ id: ROW_UPDATED_ID, name: values.name }); // an empty event with id==-42 means placeholder with progressbar
          const newEventData = transformStoreFormatToEvent(values);
          const response = yield authorizedApi.post(
            `/appointments/create`,
            newEventData,
          );

          if (response.data?.success) {
            const data = response.data?.success;

            const transformedNewEventData = transformEventToStoreFormat({
              ...data,
            });

            if (!transformedNewEventData.url) {
              transformedNewEventData.url = '';
            }

            if (transformedNewEventData.isActive) {
              corporateUsersEventsStore.enableEventType({
                id: transformedNewEventData.id,
              });
            } else {
              corporateUsersEventsStore.disableEventType({
                id: transformedNewEventData.id,
              });
            }

            self.events.pop();
            self.events.push(transformedNewEventData);
          }
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }

          self.events.pop();

          throw error;
        }
      },

      *editEvent({ id, values }) {
        const editedEventData = transformStoreFormatToEvent(values);

        const eventIndex = self.events.findIndex(
          ({ id: existingEventId }) => String(existingEventId) === String(id),
        );

        if (eventIndex >= 0) {
          self.events[eventIndex].isLoading = true;
        }

        try {
          delete editedEventData.friendlyName;
          delete editedEventData.url;

          const response = yield authorizedApi.post(
            `/appointments/${id}/edit`,
            editedEventData,
          );

          const data = response.data?.success;

          response.data.success.maxAppointees = data.maxAppointees;

          if (eventIndex >= 0) {
            const newEventData = {
              ...self.events[eventIndex],
              ...transformEventToStoreFormat(data),
            };

            if (!newEventData.url) {
              newEventData.url = self.events[eventIndex].url;
            }

            newEventData.maxAppointees = Number.parseInt(
              newEventData.maxAppointees,
              10,
            );

            if (Number.isNaN(newEventData.maxAppointees)) {
              newEventData.maxAppointees = 1;
            }

            if (newEventData.isActive) {
              corporateUsersEventsStore.enableEventType({ id });
            } else {
              corporateUsersEventsStore.disableEventType({ id });
            }

            self.events[eventIndex] = newEventData;
          }
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }

          throw error;
        } finally {
          if (eventIndex >= 0) {
            self.events[eventIndex].isLoading = false;
          }
        }
      },

      *editFriendlyName({ id, details, linkName }) {
        const editedEventData = transformStoreFormatToEvent(details);
        delete editedEventData.url;
        editedEventData.friendlyName = linkName;

        if (typeof editedEventData?.pulse_tags === 'string') {
          editedEventData.pulse_tags = editedEventData?.pulse_tags
            ?.split(',')
            ?.map((item) => +item);
        } else {
          editedEventData.pulse_tags = editedEventData?.pulse_tags?.map(
            (item) => +item,
          );
        }

        const response = yield authorizedApi.post(
          `/appointments/${id}/edit`,
          editedEventData,
        );
        const data = response.data?.success;

        const eventIndex = self.events.findIndex(
          ({ id: existingEventId }) => String(existingEventId) === String(id),
        );

        if (eventIndex >= 0) {
          self.events[eventIndex] = transformEventToStoreFormat(data);
        }
      },

      *deleteEvent({ id }) {
        yield authorizedApi.delete(`/appointments/${id}`);

        self.events.remove(
          self.events.find(
            ({ id: existingEventId }) => String(existingEventId) === String(id),
          ),
        );
      },

      *disableEvent({ id }) {
        yield authorizedApi.post(`/appointments/${id}/inactive`);

        const eventIndex = self.events.findIndex(
          ({ id: existingEventId }) => String(existingEventId) === String(id),
        );
        self.events[eventIndex].isActive = false;

        corporateUsersEventsStore.disableEventType({ id });
      },

      *enableEvent({ id }) {
        yield authorizedApi.post(`/appointments/${id}/active`);
        const eventIndex = self.events.findIndex(
          ({ id: existingEventId }) => String(existingEventId) === String(id),
        );
        self.events[eventIndex].isActive = true;

        corporateUsersEventsStore.enableEventType({ id });
      },

      *reorderEvents(newEvents) {
        const userId = authorizedApi.defaults.headers.id;

        const body = {
          appointments: newEvents.map(({ id }, index) => ({
            appointment_id: id,
            index,
          })),
        };

        const response = yield authorizedApi.post(
          `/appointments/users/${userId}/indexing`,
          body,
        );

        if (response.data?.success) {
          self.events.forEach((_, oldIndex) => {
            self.events[oldIndex].index = newEvents.findIndex(
              ({ id }) => id === self.events[oldIndex].id,
            );
          });
        }
      },
    }),
  );

const eventsStore = EventsStore.create({
  events: [],
  fetchStatus: fetchStatus.noStatus,
});

export default eventsStore;
