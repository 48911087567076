import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ar from '@/languages/ar.json';
import en from '@/languages/en.json';

const resources = {
  ar: {
    translation: ar,
  },
  en: {
    translation: en,
  },
} as const;

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: 'translation',
    fallbackLng: 'en',
    resources,
    returnNull: false,
    detection: {
      order: ['localStorage', 'navigator'],
    },
  });

export default i18n;
