import { Collapse, Grid, GridItem } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage, useMount } from 'react-use';

import { Payments } from '@/components/organisms/Payments';
import TrialBanner from '@/components/organisms/TrialBanner';
import AddUsersModal from '@/components/pages/Users/organisms/AddUsersModal/AddUsersModal';
import { anyUsersRoutes, subscribedUsersRoutes } from '@/config/routes';
import useBreakpoint from '@/hooks/useBreakpoint';
import { useProfileStore, useSubscriptionStore } from '@/stores/RootStore';

import Header from '../organisms/Header';
import InactiveSubscription from '../organisms/InactiveSubscription';
import MobileHeader from '../organisms/MobileHeader';
import Navigation from '../organisms/Navigation';

function MainLayout({ Content }) {
  const [longLifeToken] = useLocalStorage('long_life_token');

  const isAuthenticated = Boolean(longLifeToken);

  const location = useLocation();
  const navigate = useNavigate();
  const isLargerThanBreakpoint = useBreakpoint();

  // NOTE DO NOT desctructure MobX stores, it strips the data from reactivity
  const subscriptionStore = useSubscriptionStore();
  const profileStore = useProfileStore();

  const mainLayoutRef = useRef(null);

  const currentPeriodEnd = subscriptionStore.subscription?.current_period_end;

  setTimeout(() => {
    mainLayoutRef.current?.scroll?.(0, 0);
  }, 250); // half of the animation time, to prevent visible jump

  const match = [...anyUsersRoutes, ...subscribedUsersRoutes].find(
    (routeInfo) =>
      matchPath(
        {
          ...routeInfo,
          exact: true,
        },
        location.pathname,
      ) && routeInfo,
  );

  const { hideMobileHeader } = match ?? {};

  const shouldHideMobileHeader = !isLargerThanBreakpoint && hideMobileHeader;

  const HeaderComponent = isLargerThanBreakpoint ? Header : MobileHeader;

  const gridTemplate = isLargerThanBreakpoint
    ? {
        templateColumns: 'auto minmax(0, 1fr)',
        templateRows: 'auto minmax(0, 1fr)',
      }
    : {
        templateColumns: '1fr',
        templateRows: 'auto minmax(0, 1fr)',
      };

  useMount(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true });
    }
  });

  if (!isAuthenticated || !HeaderComponent) {
    return null;
  }

  const showTrialBanner = Boolean(
    subscriptionStore.subscription?.isTrial &&
      profileStore.profile.isBusinessAdmin &&
      profileStore.profile.businessId,
  );

  const dataLoaded = Boolean(
    profileStore.profile.id && subscriptionStore.subscription.id,
  );

  return (
    <>
      {showTrialBanner && (
        <TrialBanner currentPeriodEndTimestamp={currentPeriodEnd} />
      )}
      <Payments>
        <AddUsersModal />
      </Payments>
      <Grid
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...gridTemplate}
        w="100%"
        h={
          showTrialBanner
            ? `calc(100% - ${isLargerThanBreakpoint ? 49 : 84}px)`
            : '100%'
        }
      >
        {isLargerThanBreakpoint && (
          <GridItem rowSpan={2} display="flex" flexDirection="column">
            <Navigation
              disabled={currentPeriodEnd < DateTime.now().toMillis()}
            />
          </GridItem>
        )}
        <Collapse in={!shouldHideMobileHeader} animateOpacity={false}>
          <GridItem>
            <HeaderComponent />
          </GridItem>
        </Collapse>
        {dataLoaded && (
          <GridItem
            overflowX="hidden"
            display="flex"
            flexDirection="column"
            ref={mainLayoutRef}
          >
            {currentPeriodEnd < DateTime.now().toMillis() &&
            location.pathname !== '/settings' &&
            !profileStore.profile.businessId ? (
              <InactiveSubscription />
            ) : (
              Content
            )}
          </GridItem>
        )}
      </Grid>
    </>
  );
}

MainLayout.propTypes = {
  Content: PropTypes.element.isRequired,
};

export default observer(MainLayout);
