import parsePhoneNumber from 'libphonenumber-js';

/**
 * @param {string} phoneNumber
 * @returns {string}
 */
export default function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return '';
  }

  const parsedPhoneNumber =
    parsePhoneNumber(phoneNumber) ?? parsePhoneNumber(`+${phoneNumber}`);

  return parsedPhoneNumber?.isValid()
    ? parsedPhoneNumber.formatInternational().replace(/\s/g, '')
    : phoneNumber;
}
