interface CheckmarkProperties {
  height?: string | number;
  width?: string | number;
  fill?: string;
}

export default function Checkmark({
  width,
  height,
  fill,
}: CheckmarkProperties) {
  return (
    <svg
      width={width || '22px'}
      height={height || '28px'}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="q2lj5w8mta">
          <path d="M1200 0v2521H0V0h1200z" />
        </clipPath>
        <clipPath id="yjlyi2loxb">
          <path d="m18.64 0 1.16 1.43L6.652 17.4 0 9.446l1.16-1.43 5.492 6.588L18.64 0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#q2lj5w8mta)" transform="translate(-625 -1354)">
        <g clipPath="url(#yjlyi2loxb)" transform="translate(627.4 1357)">
          <path fill={fill} d="M0 0h19.8v17.4H0V0z" />
        </g>
      </g>
    </svg>
  );
}

Checkmark.defaultProps = {
  width: '22px',
  height: '28px',
  fill: '#fff',
};
