export default function TickIcon() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <desc>Created with sketchtool.</desc>
      <g
        id="Bookings-Dashboard-FINAL"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CatchApp-Dashboard/Desktop-Final/MyEvents-(9)"
          transform="translate(-49.000000, -202.000000)"
        >
          <g id="CatchApp-Dashboard/Symbols/Menus">
            <g
              id="Icons/MainMenu/Settings/Grey3Fill-Copy-4"
              transform="translate(41.000000, 194.000000)"
            >
              <circle id="Oval" fill="currentColor" cx="20" cy="20" r="12" />
              <g
                id="Website/Elements/TickThin"
                transform="translate(14.000000, 13.000000)"
                fill="#FFFFFF"
              >
                <g id="Group-5-Copy-28">
                  <path
                    d="M3.13726447,14 C3.1271349,14 3.11700533,14 3.10687577,13.9995496 C2.7021996,13.9876141 2.35475548,13.7398966 2.25396629,13.3910653 L0.0290070815,5.69268089 C-0.0965995393,5.25895006 0.197411119,4.81688691 0.684896492,4.70541403 C1.1726351,4.59394116 1.66974356,4.85517052 1.79509695,5.28890136 L3.30009724,10.4954729 C3.62981462,9.77506536 4.03474403,8.93845579 4.50779477,8.04599718 C6.41189998,4.45364297 8.42312534,1.81455076 10.4862647,0.201909802 C10.8643508,-0.0937748197 11.4404699,-0.060895951 11.7729729,0.27554946 C12.1054759,0.611769673 12.068503,1.12431971 11.6899104,1.42000433 C7.18731827,4.93984487 4.03474403,13.3579613 4.00334238,13.4426356 C3.88001491,13.7759283 3.52978516,14 3.13726447,14 Z"
                    id="Stroke-3"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
