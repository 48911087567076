/* eslint-disable react/jsx-props-no-spreading */

import { Box, Flex } from '@chakra-ui/react';
import { node, string } from 'prop-types';

export function MobileLayout({ children, 'data-testid': dataTestId, ...rest }) {
  return (
    <Box
      data-testid={dataTestId}
      data-testname="MobileLayout"
      px={4}
      py={4}
      h="100%"
      overflowY="auto"
    >
      <Flex direction="column" alignItems="center" {...rest}>
        {children}
      </Flex>
    </Box>
  );
}

MobileLayout.propTypes = {
  'data-testid': string,
  children: node,
};

MobileLayout.defaultProps = {
  'data-testid': undefined,
  children: undefined,
};
