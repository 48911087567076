const INIT_STATE = {
  loading: true,
  user_auth: JSON.parse(localStorage.getItem('long_life_token')),
  user_profile: JSON.parse(localStorage.getItem('user_profile'))
    ? JSON.parse(localStorage.getItem('user_profile'))
    : {},
  micro_loading: false,
  isActiveBookingsUser: false,
  showModal: true,
  openTime: '09:00',
  closeTime: '17:00',
  meetingDurations: '30',
  openingDays: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  },
  industryInfo: '',
  makingApiCall: false,
};

const dashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'DASHBOARD_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_USER_PROFILE_SUCCESS':
      return {
        ...state,
        user_profile: action.payload,
      };
    case 'SET_SUBSCRIPTION_STATUS':
      return {
        ...state,
        isActiveBookingsUser: action.payload,
        loading: false,
      };
    case 'PROFILE_UPDATE_BUSINESS_NAME':
      return {
        ...state,
        user_profile: {
          ...state.user_profile,
          name: action.payload,
        },
      };
    case 'PROFILE_UPDATE_EMAIL':
      return {
        ...state,
        user_profile: {
          ...state.user_profile,
          homeEmail: action.payload,
        },
      };
    case 'PROFILE_UPDATE_SKYPE':
      return {
        ...state,
        user_profile: {
          ...state.user_profile,
          skype: action.payload,
        },
      };
    case 'PROFILE_UPDATE_ZOOM':
      return {
        ...state,
        user_profile: {
          ...state.user_profile,
          zoom: action.payload,
        },
      };
    case 'UPDATE_LOGO_LOADING':
      return {
        ...state,
        micro_loading: true,
      };
    case 'STOP_LOGO_LOADING':
      return {
        ...state,
        micro_loading: false,
      };
    case 'SET_SUBSCRIPTION_DATA':
      return {
        ...state,
        subscription: action.payload,
      };
    case 'HIDE_MODAL':
      return {
        ...state,
        showModal: false,
      };
    case 'UPDATE_OPEN_TIME':
      return {
        ...state,
        openTime: action.payload,
      };
    case 'UPDATE_CLOSE_TIME':
      return {
        ...state,
        closeTime: action.payload,
      };
    case 'UPDATE_OPEN_DAYS':
      return {
        ...state,
        openingDays: action.payload,
      };
    case 'UPDATE_DURATIONS':
      return {
        ...state,
        meetingDurations: action.payload,
      };
    case 'UPDATE_INDUSTRY_INFO':
      return {
        ...state,
        industryInfo: action.payload,
      };
    case 'MAKING_API_CALL':
      return {
        ...state,
        makingApiCall: true,
      };
    case 'SUCCESS_API_CALL':
      return {
        ...state,
        makingApiCall: false,
      };
    case 'API_CALL_ERROR':
      return {
        ...state,
        makingApiCall: false,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
