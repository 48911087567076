import { captureException } from '@sentry/react';
import { DateTime } from 'luxon';
import { observable } from 'mobx';
import { types } from 'mobx-state-tree';

import { authorizedApi, fetchStatus, leadUser } from '@/utils/apiUtils';

import { Booking } from './BookingsStore';
import profileStore from './ProfileStore/ProfileStore';
import { autoFlow } from './storeUtils';

const firstPageIndex = 1;

function extractPageIndex(rawUrl) {
  try {
    if (rawUrl) {
      const url = new URL(rawUrl);

      const pageIndex = Number(url.searchParams.get('page'));

      if (!Number.isNaN(pageIndex)) {
        return pageIndex;
      }
    }
  } catch (error) {
    captureException(error);
  }

  return null;
}

export const PastBookingsStore = types
  .model('PastBookingsStore', {
    bookings: types.array(Booking),
    fetchStatus: types.enumeration(Object.values(fetchStatus)),
    fetchCount: types.integer,
    currentPageIndex: types.integer,
    nextPageIndex: types.maybeNull(types.integer),
    previousPageIndex: types.maybeNull(types.integer),
  })

  .views((self) => ({
    get isFetching() {
      return self.fetchStatus === fetchStatus.pending;
    },

    get hasFetchedAtLeastOnce() {
      return self.fetchCount > 0;
    },

    get dateGroupedBookings() {
      return observable(
        self.bookings.reduce((bookingsMap, booking) => {
          const date = DateTime.fromMillis(booking.from).setZone(
            profileStore.profile.tzIdentifier,
          );
          const isoDateKey = date.toISODate();

          return bookingsMap.set(isoDateKey, [
            ...(bookingsMap.get(isoDateKey) ?? []),
            {
              ...booking,
            },
          ]);
        }, new Map()),
      );
    },
  }))

  .actions((self) =>
    autoFlow({
      *fetchPage({ page, signal }) {
        const userId = authorizedApi.defaults.headers.id;

        self.fetchStatus = fetchStatus.pending;

        const response = yield authorizedApi.get(
          `/users/${userId}/catchups/confirmed/past`,
          {
            fetch: (resource, options) =>
              fetch(resource, {
                ...options,
                signal,
              }),
            params: {
              page: page ?? self.currentPageIndex,
            },
          },
        );

        const bookings = response.data?.success;

        if (bookings) {
          if (page) {
            self.currentPageIndex = page;
          }

          self.previousPageIndex = extractPageIndex(
            response.data.meta.previous_page_url,
          );

          self.nextPageIndex = extractPageIndex(
            response.data.meta.next_page_url,
          );

          self.bookings = bookings
            .map((booking) => {
              const existingBooking =
                self.bookings.find(
                  ({ id }) => String(id) === String(booking.event_id),
                ) ?? {};

              const [{ from, to }] = booking.events ?? [{}];

              const bookingFromDate = DateTime.fromSeconds(from);

              const appointee = booking.attending.find(
                ({ id }) => id !== leadUser.id,
              );

              if (!appointee || !bookingFromDate.isValid) {
                return null;
              }

              return {
                ...existingBooking,
                id: booking.event_id,
                catchupId: booking.catchup_id,
                from: DateTime.fromSeconds(from).toMillis(),
                to: DateTime.fromSeconds(to).toMillis(),
                appointee: appointee.name,
                appointmentType: booking.catchup_name,
                maxAppointees: booking.maxAppointees,
                classBooking: booking.classBooking,
                attending: booking.attending.map((attending) => ({
                  ...attending,
                  modified: DateTime.fromISO(attending.modified).toMillis(),
                })),
                initiator: booking.initiator,
                owner: booking.owner,
                callingWho: booking.callingWho,
                clientPhone: booking.clientPhone,
                details: {
                  ...existingBooking.details,
                  locationDetails: booking.catchup_location_name,
                },
              };
            })
            .filter((value) => value !== null);
        }

        self.fetchStatus = fetchStatus.done;
        self.fetchCount = Math.min(
          self.fetchCount + 1,
          Number.MAX_SAFE_INTEGER,
        );
      },
    }),
  );

const pastBookingsStore = PastBookingsStore.create({
  bookings: [],
  currentPageIndex: firstPageIndex,
  fetchStatus: fetchStatus.noStatus,
  fetchCount: 0,
});

export default pastBookingsStore;
