import { CardElement } from '@stripe/react-stripe-js';

import paymentStore from '@/stores/PaymentStore';
import profileStore from '@/stores/ProfileStore/ProfileStore';
import { processCardDetails } from '@/utils/stripe/processCardDetails';

export const paymentMethod = async ({ stripe, elements, onError }) => {
  paymentStore.processingPayment();

  const paymentMethodResult = async () => {
    if (profileStore.profile?.paymentMethod) {
      return Promise.resolve({
        paymentMethod: {
          id: profileStore.profile?.paymentMethod?.paymentMethodId,
        },
      });
    }

    const card = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: profileStore.profile.name,
        email: profileStore.profile.homeEmail,
      },
    });

    return card;
  };

  const result = await paymentMethodResult();

  if (!result || result.error) {
    onError(result?.error?.message ?? '');
    paymentStore.cancelProcessing();

    return { success: false, error: result?.error };
  }

  const response = await processCardDetails({
    createPaymentMethodResult: result,
    onError,
  });

  if (response?.success) {
    paymentStore.cancelProcessing();

    return { success: true };
  }

  onError(response?.error?.message?.message);
  paymentStore.cancelProcessing();

  return { success: false, error: response?.error };
};
