import axios from 'axios';

/**
 * @type {axios}
 */
export const publicApi = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

export const headers = () => {
  const longLifeToken = localStorage.getItem('long_life_token');

  if (!longLifeToken) {
    return {};
  }

  const parsedLongLifeToken = JSON.parse(longLifeToken);

  if (
    typeof parsedLongLifeToken?.id !== 'string' ||
    typeof parsedLongLifeToken?.token !== 'string'
  ) {
    return {};
  }

  return {
    id: parsedLongLifeToken.id,
    token: parsedLongLifeToken.token,
  };
};

/**
 * @type {axios}
 */
export const authorizedApi = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    ...(() => {
      const longLifeToken = localStorage.getItem('long_life_token');

      if (!longLifeToken) {
        return {};
      }

      const parsedLongLifeToken = JSON.parse(longLifeToken);

      if (
        typeof parsedLongLifeToken?.id !== 'string' ||
        typeof parsedLongLifeToken?.token !== 'string'
      ) {
        return {};
      }

      return {
        id: parsedLongLifeToken.id,
        token: parsedLongLifeToken.token,
      };
    })(),
  },
});

export const leadUser = {
  id: 4484959,
  target: 'sme@catchapp.mobi',
};

export const stripeClientId = 'ca_HC9aqgONouMOMLBSvApNuar0dEiZD6yW';
export const stripeClientTestId = 'ca_HC9akTYJIbl8jFKD5MncqqZQlRU5HU3f';

export const fetchStatus = {
  noStatus: 'noStatus',
  pending: 'pending',
  done: 'done',
};
