/* eslint-disable @typescript-eslint/naming-convention, camelcase */
import { nanoid } from 'nanoid';

import { eventDuration } from '@/components/pages/AddEditMyEvent/organisms/AddEditForm/constants';
import { rawLocationTypeToEventLocationType } from '@/stores/EventStore/rawLocationTypeToEventLocationType';
import { centsToDecimal } from '@/utils/centsToDecimal';

export const transformEventToStoreFormat = (params) => {
  const {
    id,
    name,
    appointmentPrice,
    confirmationPage,
    days,
    duration,
    from,
    to,
    times,
    message,
    allowGuests,
    callingWho,
    url,
    limitBookings,
    customLocationName,
    maxAppointees,
    bookingQuestions,
    classBooking,
    hasSMSReminders,
    availability,
    friendlyName,
    buffer_time,
    buffer_type,
    pulse_tags,
    smsReminders,
    customise_by_day,
    times_by_day,
    timezone,
    buffer_length_before,
    buffer_length_after,
    buffer_type_before,
    buffer_type_after,
    questions,
    customLocationNames,
    isActive,
    index,
  } = params;

  const [rawLocationType, ...rawLocationName] = (
    customLocationName ?? ''
  ).split(/:\s*/);

  const locationType = rawLocationTypeToEventLocationType({ rawLocationType });

  const daysEventToStoreFormat = (daysEventFormat) => {
    if (!daysEventFormat)
      return {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      };

    return Object.entries(days).reduce(
      (accumulatedDays, [dayKey, dayValue]) => ({
        ...accumulatedDays,
        [dayKey]: Boolean(dayValue),
      }),
      {},
    );
  };

  const questionsArray = questions ? Object.values(questions) : [];

  const additionalLocation =
    customLocationNames
      ?.filter((item) => {
        if (item.includes('Call:')) return false;

        return item?.trim() !== customLocationName?.trim();
      })
      ?.map((item) => {
        const [type, customName] = item.split(/:\s*/);

        const newLocationOption = rawLocationTypeToEventLocationType({
          rawLocationType: type,
        });

        return { name: newLocationOption, customLocationName: customName };
      }) ?? [];

  const mapTimes = (timesArray) =>
    timesArray.map(([timeFrom, timeTo]) => ({
      id: nanoid(),
      from: timeFrom,
      to: timeTo,
    }));

  const returnValue = {
    bookingQuestions,
    bufferTime: parseFloat(buffer_time),
    bufferType: buffer_type,
    bufferLengthBefore: parseFloat(buffer_length_before) ?? 0,
    bufferLengthAfter: parseFloat(buffer_length_after) ?? 0,
    bufferTypeBefore: buffer_type_before ?? 'hour',
    bufferTypeAfter: buffer_type_after ?? 'hour',
    customizeByDay: customise_by_day,
    confirmationPage,
    customLocationName: rawLocationName.join(':') ?? rawLocationType ?? '',
    additionalLocation,
    customTimeDuration: !eventDuration.includes(duration),
    customTimeDurationValue: duration,
    days: daysEventToStoreFormat(days),
    duration,
    has_sms_reminders: hasSMSReminders, // Field should be deleted in the future. Now smsReminders is responisble for this field
    limitBookings,
    allowGuests,
    id,
    maxAppointees,
    callingWho,
    classBooking,
    location: locationType,
    name,
    isActive,
    friendlyName,
    notes: message ?? '',
    prePayment: centsToDecimal(appointmentPrice),
    pulse_tags,
    availability,
    smsReminders,
    index,
    times:
      from && to
        ? [
            {
              id: nanoid(),
              from,
              to,
            },
          ]
        : mapTimes(times),
    timezone,
    url,
    weekdays: Object.fromEntries(
      Object.entries(times_by_day).map(([key, value]) => [
        key,
        {
          ...value,
          available: Boolean(value.available),
          times: mapTimes(value.times),
        },
      ]),
    ),
    questions: questionsArray,
  };

  return returnValue;
};
