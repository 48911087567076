/* eslint-disable camelcase */

import { eventLocationTypeToRawLocationType } from '@/stores/EventStore/eventLocationTypeToRawLocationType';
import { decimalToCents } from '@/utils/decimalToCents';
import formatPhoneNumber from '@/utils/formatPhoneNumber';

export const transformStoreFormatToEvent = ({
  bookingQuestions,
  bufferTime,
  bufferType,
  callingWho,
  customLocationName,
  additionalLocation,
  customTimeDuration,
  customTimeDurationValue,
  customizeByDay,
  confirmationPage,
  classBooking,
  days,
  duration,
  has_sms_reminders, // Field should be deleted in the future. Now smsReminders is responisble for this field
  limitBookings,
  limitBookingsAmount,
  limitBookingsInterval,
  allowGuests,
  location,
  maxAppointees,
  name,
  phone,
  notes,
  prePayment,
  pulse_tags,
  availability,
  friendlyName,
  showAdditionalBookingQuestions,
  showAppointmentPrice,
  times,
  timezone,
  questions,
  url,
  weekdays,
  bufferLengthBefore,
  smsReminders,
  bufferLengthAfter,
  bufferTypeBefore,
  bufferTypeAfter,
  isActive,
  index,
}) => {
  const rawLocation = eventLocationTypeToRawLocationType({
    location,
    customLocationName,
  });

  const rawLocations =
    additionalLocation?.map((item) =>
      eventLocationTypeToRawLocationType({
        location: item.name.value,
        customLocationName: item.customLocationName,
      }),
    ) ?? [];

  const mapTimes = (timesArray) =>
    timesArray?.map(({ from, to }) => [from, to]) ?? [];

  return {
    appointmentPrice: showAppointmentPrice ? decimalToCents(prePayment) : 0,
    bookingQuestions: showAdditionalBookingQuestions ? bookingQuestions : '',
    buffer_time: parseFloat(bufferTime),
    buffer_type: bufferType,
    buffer_length_before: parseFloat(bufferLengthBefore) ?? 0,
    buffer_length_after: parseFloat(bufferLengthAfter) ?? 0,
    buffer_type_before: bufferTypeBefore ?? 'hour',
    buffer_type_after: bufferTypeAfter ?? 'hour',
    customise_by_day: customizeByDay,
    customLocationName:
      location.value === 'call' && callingWho === 'initiator'
        ? 'Call:'
        : rawLocation,
    duration:
      customTimeDuration && duration !== customTimeDurationValue
        ? customTimeDurationValue
        : duration,
    has_sms_reminders, // Field should be deleted in the future. Now smsReminders is responisble for this field
    limitBookings: limitBookings
      ? {
          amount: limitBookingsAmount,
          interval: limitBookingsInterval,
        }
      : null,
    allowGuests,
    confirmationPage,
    message: notes,
    name,
    phone: formatPhoneNumber(phone),
    classBooking,
    callingWho,
    maxAppointees,
    timezone,
    questions,
    pulse_tags,
    friendlyName,
    availability,
    smsReminders,
    url,
    index,
    customLocationNames: [
      location.value === 'call' && callingWho === 'initiator'
        ? 'Call:'
        : rawLocation,
      ...rawLocations,
    ],
    times_by_day: Object.fromEntries(
      Object.entries(weekdays).map(([key, value]) => [
        key,
        {
          ...value,
          times: mapTimes(value.times),
        },
      ]),
    ),
    days: Object.entries(days).reduce(
      (accumulatedDays, [dayKey, dayValue]) => ({
        ...accumulatedDays,
        [dayKey]: Boolean(dayValue),
      }),
      {},
    ),
    times: mapTimes(times),
    isActive,
  };
};
