import { Box, Progress, Text, VStack } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

import { CheckoutForm } from '@/components/organisms/CheckoutForm';
import addUsersModalStore from '@/components/pages/Users/organisms/AddUsersModal/addUsersModalStore';
import {
  usePaymentStore,
  useProfileStore,
  useSubscriptionStore,
  useUsersStore,
} from '@/stores/RootStore';
import { defaultSubscriptionInterval } from '@/stores/SubscriptionStore';
import i18n from '@/utils/i18n';

import editUserModalStore from '../../../EditUserModal/editUserModalStore';

const fullDiscount = 100;

const getMonthlyCost = (yearlyCost) => yearlyCost / 12;

const useSeatCountLabel = () => {
  const { userSeatCost, idleUserSeats } = useUsersStore();

  if (!userSeatCost) return '';

  const seatNoun =
    userSeatCost.seatCount === 1 ? i18n.t('seat') : i18n.t('seats');
  const idleSeatNoun =
    idleUserSeats.length === 1 ? i18n.t('seat') : i18n.t('seats');
  const idleUserNoun =
    idleUserSeats.length === 1 ? i18n.t('user') : i18n.t('users');

  return (
    <div>
      <Text>
        {i18n.t('seats_adding_total_1')} {userSeatCost.seatCount} {seatNoun}{' '}
        {i18n.t('seats_adding_total_3')}
      </Text>
      {idleUserSeats.length > 0 && (
        <>
          <Text variant="semibold" as="span">
            {i18n.t('idle_seats_can_be_assigned', {
              idleSeats: idleUserSeats.length,
              idleSeatOrSeats: idleSeatNoun,
              idleUserOrUsers: idleUserNoun,
            })}
          </Text>
          {'. '}
          <Text
            as="button"
            variant="semibold"
            textDecoration="underline"
            display="inline-block"
            onClick={() => {
              editUserModalStore.editUser({ userSeat: idleUserSeats[0] });
              addUsersModalStore.close();
            }}
          >
            {i18n.t('click here')}
          </Text>
          <Text variant="semibold" as="span">
            {' '}
            {i18n.t('first_available_seat_invite_2')}
          </Text>
        </>
      )}
    </div>
  );
};

const useAdditionalCostLabel = () => {
  const { payment } = usePaymentStore();
  const { subscription } = useSubscriptionStore();
  const { userSeatCost } = useUsersStore();
  const profileStore = useProfileStore();

  if (!userSeatCost) return '';

  const currencyFormatter = new Intl.NumberFormat(
    window.navigator.language ?? 'en-GB',
    {
      currency: profileStore.profile.stripeCurrency || userSeatCost.currency || 'gbp',
      style: 'currency',
    },
  );

  // year / month / null
  const interval = subscription.interval ?? defaultSubscriptionInterval;

  const parsedCost = Number.parseFloat(userSeatCost.totalCost);

  const costByInterval =
    interval === 'year' ? getMonthlyCost(parsedCost) : parsedCost;

  const formattedCost = currencyFormatter.format(costByInterval);

  if (payment.successCoupon && payment.couponDiscount === fullDiscount) {
    const noCost = currencyFormatter.format(0);

    const nextCouponPaymentDate = Number(payment.couponDuration)
      ? DateTime.now().plus({ months: Number(payment.couponDuration) })
      : null;

    return (
      <span>
        {i18n.t('additional_account_cost', { cost: '' })}
        <Text color="green.500" fontStyle="normal" as="em" variant="bold">
          {noCost}
        </Text>
        . {i18n.t('card_charge_none')}.{' '}
        {nextCouponPaymentDate?.isValid &&
          `${i18n.t('your_next_account_charge', {
            formattedCost,
            monthlyPayableAnnually:
              interval === 'year' && i18n.t('monthly_payable_annually'),
            nextCouponPaymentDate: nextCouponPaymentDate.toLocaleString(
              DateTime.DATE_FULL,
            ),
          })}. `}
        {i18n.t('please_complete_payment_details_below')}
      </span>
    );
  }

  if (payment.successCoupon && payment.couponDiscount !== fullDiscount) {
    const { couponDiscount } = payment;

    const discountedCost = currencyFormatter.format(
      (costByInterval * (100 - couponDiscount)) / 100,
    );

    const nextCouponPaymentDate = Number(payment.couponDuration)
      ? DateTime.now().plus({ months: Number(payment.couponDuration) })
      : null;

    const formattedPercentDiscount = couponDiscount
      .toFixed(2)
      .replace(/(\.[^0]*)0+$/, '$1')
      .replace(/\.$/, '');

    const discountAppliedLabel = i18n.t('discount_applied_percent', {
      discount: formattedPercentDiscount,
    });

    return (
      <span>
        {i18n.t('additional_account_cost', { cost: '' })}
        <Text color="green.500" fontStyle="normal" as="em" variant="bold">
          {discountedCost} {discountAppliedLabel}
          {'. '}
        </Text>
        {nextCouponPaymentDate?.isValid &&
          i18n.t('your_next_account_charge', {
            formattedCost,
            monthlyPayableAnnually:
              interval === 'year' && i18n.t('monthly_payable_annually'),
            nextCouponPaymentDate: nextCouponPaymentDate.toLocaleString(
              DateTime.DATE_FULL,
            ),
          })}
        {'. '}
        {i18n.t('please_complete_payment_details_below')}
      </span>
    );
  }

  return (
    <span>
      {i18n.t('additional_account_cost', { cost: formattedCost })}
      {interval === 'year' && i18n.t('monthly_payable_annually')}
      {'. '}
      {i18n.t('please_complete_payment_details_below')}
    </span>
  );
};

export const AddSeatsSummaryStepBody = observer(() => {
  const { t } = useTranslation();

  const [isFetchingSeatCost, setFetchingSeatCost] = useState(true);
  const usersStore = useUsersStore();
  const profileStore = useProfileStore()

  const additionalCostLabel = useAdditionalCostLabel();
  const seatCountLabel = useSeatCountLabel();

  useMount(() => {
    usersStore.setUserSeatCostProcessing(true);

    usersStore
      .calculateUserSeatCost({
        currency: profileStore.profile.stripeCurrency,
        newUserSeats: addUsersModalStore.newSeats,
      })
      .then((data) => {
        usersStore.setNewUserSeatCost(data);
        setFetchingSeatCost(false);
      })
      .catch(() => {
        usersStore.setNewUserSeatCost({});
      })
      .finally(() => {
        usersStore.setUserSeatCostProcessing(false);
      });
  });

  if (isFetchingSeatCost) {
    return <Progress my={4} size="xs" isIndeterminate colorScheme="orange" />;
  }

  if (!usersStore.userSeatCost) {
    return (
      <Text my={4} color="red.400" variant="semibold">
        {t('failure_calculating_seat_cost')}
      </Text>
    );
  }

  if (!usersStore.userSeatCost.seatCount) {
    const idleSeatNoun =
      usersStore.idleUserSeats.length === 1 ? t('seat') : t('seats');
    const idleUserNoun =
      usersStore.idleUserSeats.length === 1 ? t('user') : t('users');

    return (
      <Box>
        {usersStore.idleUserSeats.length > 0 ? (
          <>
            <Text color="red.400" variant="semibold" as="span">
              {t('no_additional_seats_to_add')}{' '}
              {t('idle_seats_can_be_assigned', {
                idleSeats: usersStore.idleUserSeats.length,
                idleSeatOrSeats: idleSeatNoun,
                idleUserOrUsers: idleUserNoun,
              })}
              {'. '}
            </Text>
            <Text
              as="button"
              color="red.400"
              variant="semibold"
              textDecoration="underline"
              display="inline-block"
              onClick={() => {
                editUserModalStore.editUser({
                  userSeat: usersStore.idleUserSeats[0],
                });
                addUsersModalStore.close();
              }}
            >
              {t('first_available_seat_invite_1')}
            </Text>
            <Text color="red.400" variant="semibold" as="span">
              {' '}
              {t('first_available_seat_invite_2')}
            </Text>
          </>
        ) : (
          <Text color="red.400" variant="semibold">
            {t('no_seats_to_add')}
          </Text>
        )}
      </Box>
    );
  }

  return (
    <VStack spacing={4} align="left">
      <Text align="left">{seatCountLabel}</Text>
      <Text align="left">{additionalCostLabel}</Text>
      <Box
        w="100%"
        display="flex"
        flexDirection="row"
        justifyContent="left"
        textAlign="left"
      >
        <Box w="100%">
          <CheckoutForm />
        </Box>
      </Box>
    </VStack>
  );
});
