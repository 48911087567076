import { captureException } from '@sentry/react';

import { publicApi } from '@/utils/apiUtils';

import parseLocationSearchString from '../utils/parseLocationSearchString';

export const postAffiliateParams = async () => {
  const affiliatesScaleo = JSON.parse(
    localStorage.getItem('affiliates_scaleo'),
  );
  const longLifeToken = JSON.parse(localStorage.getItem('long_life_token'));

  if (!affiliatesScaleo || !longLifeToken) {
    return;
  }

  const { service, params } = affiliatesScaleo;
  const { id, token } = longLifeToken;

  if (service && params && id && token) {
    try {
      const response = await publicApi.post(
        `/${service}/save-user-params`,
        {
          user_id: id,
          ...params,
        },
        { headers: { token } },
      );

      if (!response.data.success) throw new Error(response.data.message);

      await publicApi.get(
        `${import.meta.env.VITE_SCALEO_TRACK_LINK}/track/goal-by-click-id`,
        {
          params: {
            click_id: params.c,
            goal_id: params.o,
          },
        },
      );

      localStorage.removeItem('affiliates_scaleo');
    } catch (error) {
      if (!error?.response) {
        captureException(error);
      }
    }
  }
};

export const useAffiliates = () => {
  const storeAffiliateParams = ({ service, params }) => {
    const paramsAsString = params.toString();

    localStorage.setItem(
      `affiliates_${service}`,
      JSON.stringify({
        service,
        params: parseLocationSearchString(paramsAsString),
      }),
    );
  };

  return { storeAffiliateParams, passAffiliateParams: postAffiliateParams };
};
