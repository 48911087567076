import { captureException } from '@sentry/react';
import { DateTime } from 'luxon';
import { observable, toJS } from 'mobx';
import { types } from 'mobx-state-tree';

import { authorizedApi, fetchStatus, leadUser } from '@/utils/apiUtils';

import profileStore from './ProfileStore/ProfileStore';
import { autoFlow } from './storeUtils';

export const Booking = types.model('Booking', {
  id: types.identifierNumber,
  catchupId: types.integer,
  callingWho: types.maybeNull(types.string),
  clientPhone: types.maybeNull(types.string),
  from: types.integer,
  to: types.integer,
  appointee: types.string,
  appointmentType: types.string,
  classBooking: types.boolean,
  maxAppointees: types.maybeNull(types.number),
  owner: types.maybeNull(types.boolean),
  questions: types.maybeNull(
    types.array(
      types.model({
        question: types.maybeNull(types.string),
        id: types.maybeNull(types.number),
      }),
    ),
  ),
  attending: types.array(
    types.model({
      homeEmail: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
      firstName: types.maybeNull(types.string),
      lastName: types.maybeNull(types.string),
      id: types.number,
      tzIdentifier: types.maybeNull(types.string),
      mobile: types.maybeNull(types.string),
      modified: types.maybeNull(types.number),
      bookingAnswers: types.maybeNull(types.string),
      bookingQuestions: types.maybeNull(types.string),
      guests: types.maybeNull(
        types.array(
          types.model({
            name: types.maybeNull(types.string),
            email: types.maybeNull(types.string),
          }),
        ),
      ),
      pivot: types.maybeNull(
        types.model({
          location: types.maybeNull(types.string),
        }),
      ),
      answers: types.maybeNull(
        types.array(
          types.model({
            answer: types.maybeNull(types.string),
            calendar_event_id: types.maybeNull(types.number),
            id: types.maybeNull(types.number),
            question: types.maybeNull(types.string),
            question_id: types.maybeNull(types.number),
          }),
        ),
      ),
    }),
  ),
  details: types.maybeNull(
    types.model({
      id: types.maybeNull(types.number),
      email: types.maybeNull(types.string),
      phone: types.maybeNull(types.string),
      timezoneAbbreviation: types.maybeNull(types.string),
      timezoneOffset: types.maybeNull(types.number),
      locationDetails: types.maybeNull(types.string),
      locationPrefix: types.maybeNull(types.string),
      locationUrl: types.maybeNull(types.string),
      payment: types.maybeNull(types.number),
      createdAt: types.maybeNull(types.integer),
      updatedAt: types.maybeNull(types.integer),
      eventUpdatedAt: types.maybeNull(types.integer),
      question: types.maybeNull(types.string),
      answer: types.maybeNull(types.string),
    }),
  ),
  initiator: types.maybeNull(
    types.model({
      mobilePublic: types.maybeNull(types.string),
      homeEmail: types.maybeNull(types.string),
    }),
  ),
});

export const BookingsStore = types
  .model('BookingsStore', {
    bookings: types.array(Booking),
    bookingsFetchStatus: types.enumeration(Object.values(fetchStatus)),
    bookingsFetchCount: types.integer,
  })

  .views((self) => ({
    get isFetching() {
      return self.bookingsFetchStatus === fetchStatus.pending;
    },

    get hasFetchedAtLeastOnce() {
      return self.bookingsFetchCount > 0;
    },

    bookingsForDate(timestamp) {
      return self.bookingsForDateWithTimezoneOffset(timestamp, 0);
    },

    bookingsForDateWithTimezoneOffset(timestamp, timezoneOffsetInMilliseconds) {
      const date = DateTime.fromMillis(timestamp);

      if (!date.isValid) {
        return observable([]);
      }

      return self.bookings.filter(({ from }) => {
        const fromDate = DateTime.fromMillis(from).plus({
          milliseconds: timezoneOffsetInMilliseconds,
        });

        return (
          fromDate.hasSame(date, 'year') &&
          fromDate.hasSame(date, 'month') &&
          fromDate.hasSame(date, 'day')
        );
      });
    },

    dateGroupedBookingsForPage({ page, entriesPerPage }) {
      return observable(
        self.bookings
          .slice(page * entriesPerPage, page * entriesPerPage + entriesPerPage)
          .reduce((bookingsMap, booking) => {
            const date = DateTime.fromMillis(booking.from).setZone(
              profileStore.profile.tzIdentifier,
            );
            const isoDateKey = date.toISODate();

            return bookingsMap.set(isoDateKey, [
              ...(bookingsMap.get(isoDateKey) ?? []),
              {
                ...booking,
              },
            ]);
          }, new Map()),
      );
    },
  }))

  .actions((self) =>
    autoFlow({
      *fetchAll() {
        const userId = authorizedApi.defaults.headers.id;

        self.bookingsFetchStatus = fetchStatus.pending;

        let response = null;

        try {
          response = yield authorizedApi.get(
            `/users/${userId}/catchups/confirmed`,
          );
        } catch (error) {
          if (!error?.response) {
            captureException(error);
          }
        }

        const bookings = response?.data?.success;

        if (bookings) {
          self.bookings = bookings
            .map((booking) => {
              const existingBooking =
                self.bookings.find(
                  ({ id }) => String(id) === String(booking.event_id),
                ) ?? {};

              const [{ from, to }] = booking.events ?? [{}];

              const bookingFromDate = DateTime.fromSeconds(from);

              const appointee = booking.attending.find(
                ({ id }) => id !== leadUser.id,
              );

              if (!appointee || !bookingFromDate.isValid) {
                return null;
              }

              return {
                ...existingBooking,
                id: booking.event_id,
                catchupId: booking.catchup_id,
                from: DateTime.fromSeconds(from).toMillis(),
                to: DateTime.fromSeconds(to).toMillis(),
                appointee: appointee.name,
                appointmentType: booking.catchup_name,
                maxAppointees: booking.maxAppointees,
                classBooking: booking.classBooking,
                attending: booking.attending.map((attending, index) => ({
                  ...attending,
                  pivot: toJS(existingBooking?.attending?.[index]?.pivot),
                  modified: DateTime.fromISO(attending.modified).toMillis(),
                })),
                initiator: booking.initiator,
                owner: booking.owner,
                callingWho: booking.callingWho,
                clientPhone: booking.clientPhone,
              };
            })
            .filter((value) => value !== null);
        }

        self.bookingsFetchStatus = fetchStatus.done;
        self.bookingsFetchCount = Math.min(
          self.bookingsFetchCount + 1,
          Number.MAX_SAFE_INTEGER,
        );

        return response?.data;
      },

      *fetchDetails({ booking }) {
        const response = yield authorizedApi.get(
          `/catchup/details/${
            booking.catchupId ?? booking.catchup_id
          }/EVENTSTATUS_CONFIRMED/${booking.id ?? booking.event_id}`,
        );

        const [details] = response.data?.success ?? [];

        if (!details) {
          return;
        }

        const appointee =
          details.attendingUsers.find(
            ({ id }) => id !== leadUser.id && id !== details.initiator_id,
          ) ?? details.attendingUsers.find(({ id }) => id !== leadUser.id);

        if (appointee) {
          const [, locationPrefix, locationUrl] =
            /^(.+?)\s?(https?:\/\/.+)$/.exec(details.location?.name ?? '') ?? [
              null,
              null,
              details.wherebyUrl,
            ];

          const locationDetails = locationPrefix
            ? locationUrl
            : details.location?.name;

          const editedBookingIndex = self.bookings.findIndex(
            (existingBooking) =>
              String(details.id) === String(existingBooking.catchupId),
          );

          if (editedBookingIndex < 0) {
            return;
          }

          const existingBooking = self.bookings[editedBookingIndex];

          for (const attendingIndex of existingBooking.attending.keys()) {
            const newUserData = details.attendingUsers.find(
              (newAttending) =>
                existingBooking.attending[attendingIndex].id ===
                newAttending.id,
            );

            if (newUserData) {
              self.bookings[editedBookingIndex].attending[
                attendingIndex
              ].pivot = toJS(newUserData.pivot);
            }
          }

          self.bookings[editedBookingIndex].details =
            self.bookings[editedBookingIndex].details || {};
          self.bookings[editedBookingIndex].details.id = details.id;
          self.bookings[editedBookingIndex].details.email = appointee.homeEmail;
          self.bookings[editedBookingIndex].details.phone = appointee.mobile;
          self.bookings[editedBookingIndex].details.timezoneAbbreviation =
            appointee.tzAbbreviation;
          self.bookings[editedBookingIndex].details.timezoneOffset =
            appointee.tzOffset;
          self.bookings[editedBookingIndex].details.locationDetails =
            locationDetails;
          self.bookings[editedBookingIndex].details.locationPrefix =
            locationPrefix;
          self.bookings[editedBookingIndex].details.locationUrl = locationUrl;
          self.bookings[editedBookingIndex].details.payment =
            details.appointmentPrice;
          self.bookings[editedBookingIndex].details.createdAt =
            DateTime.fromISO(details.created_at).toMillis();
          self.bookings[editedBookingIndex].details.updatedAt =
            DateTime.fromISO(details.updated_at).toMillis();
          self.bookings[editedBookingIndex].details.eventUpdatedAt =
            DateTime.fromISO(details.eventUpdatedAt).toMillis();
          self.bookings[editedBookingIndex].details.answer =
            appointee.bookingAnswers;
          self.bookings[editedBookingIndex].details.question =
            appointee.bookingQuestions;
        }
      },

      *deleteCatchupSlot({ catchupId, slotId, message, attendeeId }) {
        const requestUrl = attendeeId
          ? `/catchup/delete/${catchupId}/${slotId}/${attendeeId}`
          : `/catchup/delete/${catchupId}/${slotId}`;

        yield authorizedApi.delete(requestUrl, {
          data: { message },
        });

        if (attendeeId) {
          const modifiedBookingIndex = self.bookings.findIndex(
            (booking) => booking.catchupId === catchupId,
          );

          if (modifiedBookingIndex < 0) {
            return;
          }

          const attendeeIndex = self.bookings[
            modifiedBookingIndex
          ].attending.findIndex(
            (attendee) => String(attendee.id) === String(attendeeId),
          );

          if (attendeeIndex < 0) {
            return;
          }

          self.bookings[modifiedBookingIndex].attending.splice(
            attendeeIndex,
            1,
          );

          if (self.bookings[modifiedBookingIndex].attending.length === 0) {
            self.bookings.splice(modifiedBookingIndex, 1);
          }
        } else {
          const removedBookingIndex = self.bookings.findIndex(
            (booking) => booking.catchupId === catchupId,
          );

          if (removedBookingIndex >= 0) {
            self.bookings.splice(removedBookingIndex, 1);
          }
        }

        self.fetchAll();
      },
    }),
  );

const bookingsStore = BookingsStore.create({
  bookings: [],
  bookingsFetchStatus: fetchStatus.noStatus,
  bookingsFetchCount: 0,
});

export default bookingsStore;
