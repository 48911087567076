import { createContext, useContext } from 'react';

import availabilityStore from '@/stores/AvailabilityStore';
import bookingsStore from '@/stores/BookingsStore';
import corporateUsersEventsStore from '@/stores/CorporateUsersEventsStore/CorporateUsersEventsStore';
import eventsStore from '@/stores/EventStore/EventsStore';
import pastBookingsStore from '@/stores/PastBookingsStore';
import paymentStore from '@/stores/PaymentStore';
import profileStore from '@/stores/ProfileStore/ProfileStore';
import subscriptionStore from '@/stores/SubscriptionStore';
import usersStore from '@/stores/UsersStore/UsersStore';

export const StoreContext = createContext({
  availabilityStore,
  corporateUsersEventsStore,
  bookingsStore,
  eventsStore,
  pastBookingsStore,
  profileStore,
  subscriptionStore,
  usersStore,
  paymentStore,
});

export const useAvailabilityStore = () =>
  useContext(StoreContext).availabilityStore;
export const useCorporateUsersEventsStore = () =>
  useContext(StoreContext).corporateUsersEventsStore;
export const useBookingsStore = () => useContext(StoreContext).bookingsStore;
export const useEventsStore = () => useContext(StoreContext).eventsStore;
export const usePastBookingsStore = () => useContext(StoreContext).pastBookingsStore;
export const useProfileStore = () => useContext(StoreContext).profileStore;
export const useSubscriptionStore = () =>
  useContext(StoreContext).subscriptionStore;
export const useUsersStore = () => useContext(StoreContext).usersStore;
export const usePaymentStore = () => useContext(StoreContext).paymentStore;
