import React from 'react';

export default function HideIcon() {
  return (
    <svg width="10px" height="6px" viewBox="0 0 10 6" version="1.1">
      <defs>
        <polygon id="path-1" points="25 23 15 23 19.9997232 17" />
      </defs>
      <g
        id="Bookings-Dashboard-FINAL"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CatchApp-Dashboard/Desktop-Final/Settings-(12)"
          transform="translate(-785.000000, -299.000000)"
        >
          <g
            id="Website/Buttons/CTABlackOnOrange-Copy-10"
            transform="translate(790.000000, 302.000000) rotate(180.000000) translate(-790.000000, -302.000000) translate(770.000000, 282.000000)"
          >
            <g
              id="Stroke-1"
              transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "
            >
              <use fill="#FFFFFF" xlinkHref="#path-1" />
              <use fill="currentColor" xlinkHref="#path-1" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
