import { combineReducers } from 'redux';

import { pendingBookingsReducer } from '@/components/MyBookings/reducers/pendingBookingsReducer';
import { upcomingBookingsReducer } from '@/components/MyBookings/reducers/upcomingBookingsReducer';

import authTelReducer from './authTelReducer';
import confirmedReducer from './confirmedReducer';
import dashboardReducer from './dashboardReducer';
import emailReminderReducer from './emailReminder';
import getPaymentReducer from './getPaymentReducer';
import paymentReducer from './paymentReducer';
import poll from './poll';
import signUpReducer from './signUpReducer';
import typePickerModal from './typePickerModal';
import userProfileReducer from './userProfileReducer';

const reducer = combineReducers({
  typePickerModal,
  poll,
  getPaymentReducer,
  authTelReducer,
  dashboardReducer,
  confirmedReducer,
  paymentReducer,
  userProfileReducer,
  emailReminderReducer,

  auth: combineReducers({
    signUp: signUpReducer,
  }),

  bookings: combineReducers({
    pending: pendingBookingsReducer,
    upcoming: upcomingBookingsReducer,
  }),
});

export default reducer;

export type RootState = ReturnType<typeof reducer>;
