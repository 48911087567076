import { loadStripe } from '@stripe/stripe-js';

const isStripeModeLive = import.meta.env.VITE_STRIPE_MODE === 'live';

const getStripePromise = () =>
  loadStripe(
    isStripeModeLive
      ? import.meta.env.VITE_STRIPE_CLIENT
      : import.meta.env.VITE_STRIPE_CLIENT_TEST,
    {
      locale: 'en',
    },
  );

export default getStripePromise;
