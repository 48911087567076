import { types } from 'mobx-state-tree';

import { EventLocationOptions } from './EventLocationOptions';

const Times = types.model({
  id: types.string,
  from: types.string,
  to: types.string,
});

const DayOfWeek = types.model({
  available: types.boolean,
  times: types.maybeNull(types.array(Times)),
});

const Question = types.model({
  question: types.string,
  required: types.boolean,
  id: types.union(types.null, types.string, types.number),
});

export const Event = types.model({
  bookingQuestions: types.maybeNull(types.string),
  bufferTime: types.maybeNull(types.number),
  bufferType: types.union(types.null, types.string, types.number),
  bufferLengthBefore: types.maybeNull(types.number),
  bufferLengthAfter: types.maybeNull(types.number),
  bufferTypeBefore: types.maybeNull(
    types.enumeration('buffer_length_before', ['minute', 'hour']),
  ),
  bufferTypeAfter: types.maybeNull(
    types.enumeration('buffer_length_after', ['minute', 'hour']),
  ),
  customLocationName: types.maybeNull(types.string),
  days: types.model({
    monday: types.boolean,
    tuesday: types.boolean,
    wednesday: types.boolean,
    thursday: types.boolean,
    friday: types.boolean,
    saturday: types.boolean,
    sunday: types.boolean,
  }),
  duration: types.number,
  has_sms_reminders: types.maybeNull(types.boolean), // Field should be deleted in the future. Now smsReminders is responisble for this field
  allowGuests: types.maybeNull(types.boolean),
  callingWho: types.maybeNull(types.string),
  limitBookings: types.maybeNull(
    types.model({
      amount: types.snapshotProcessor(types.maybeNull(types.number), {
        preProcessor(amount) {
          return typeof amount === 'string'
            ? Number.parseInt(amount, 10)
            : amount;
        },
      }),
      interval: types.maybeNull(types.string),
    }),
  ),
  id: types.identifierNumber,
  index: types.maybeNull(types.number),
  location: types.enumeration(Object.values(EventLocationOptions)),
  additionalLocation: types.array(
    types.model({
      name: types.enumeration(Object.values(EventLocationOptions)),
      customLocationName: types.maybeNull(types.string),
    }),
  ),

  name: types.string,
  friendlyName: types.maybeNull(types.string),
  notes: types.string,
  prePayment: types.number,
  pulse_tags: types.maybeNull(
    types.union(types.array(types.number), types.string),
  ),
  classBooking: types.boolean,
  maxAppointees: types.maybeNull(types.number),
  availability: types.maybeNull(
    types.union(
      types.model({
        continuous: types.maybeNull(
          types.model({
            type: types.literal('day'),
            time: types.integer,
          }),
        ),
      }),
      types.model({
        period: types.maybeNull(
          types.model({
            from: types.string,
            to: types.string,
          }),
        ),
      }),
    ),
  ),
  times: types.array(Times),
  timezone: types.maybeNull(types.string),
  questions: types.maybeNull(types.array(Question)),
  url: types.string,
  isActive: types.maybeNull(types.boolean),
  confirmationPage: types.maybeNull(types.string),
  customizeByDay: types.boolean,
  weekdays: types.model({
    monday: DayOfWeek,
    tuesday: DayOfWeek,
    wednesday: DayOfWeek,
    thursday: DayOfWeek,
    friday: DayOfWeek,
    saturday: DayOfWeek,
    sunday: DayOfWeek,
  }),
  smsReminders: types.maybeNull(
    types.model({
      host: types.maybeNull(
        types.model({
          enabled: types.maybeNull(types.boolean),
          phone: types.maybeNull(types.string),
        }),
      ),
      client: types.maybeNull(
        types.model({
          enabled: types.maybeNull(types.boolean),
          required: types.maybeNull(types.boolean),
        }),
      ),
    }),
  ),
  customTimeDuration: types.boolean,
  customTimeDurationValue: types.number,
  isLoading: types.maybeNull(types.boolean),
});
