import addUsersModalStore from '@/components/pages/Users/organisms/AddUsersModal/addUsersModalStore';
import AddSeatsSummaryStep from '@/components/pages/Users/organisms/AddUsersModal/molecules/AddSeatsSummaryStep';
import AddUsersStep from '@/components/pages/Users/organisms/AddUsersModal/molecules/AddUsersStep';
import SeatsAddedStep from '@/components/pages/Users/organisms/AddUsersModal/molecules/SeatsAddedStep';

export default {
  defaultStep: AddUsersStep.key,
  steps: {
    [AddUsersStep.key]: {
      ...AddUsersStep,
      onNextClick: ({ formContext, setCurrentStep }) => () => {
        addUsersModalStore.setNewSeats(formContext.getValues().users);
        setCurrentStep(AddSeatsSummaryStep.key);
      },
    },
    [AddSeatsSummaryStep.key]: {
      ...AddSeatsSummaryStep,
      onNextClick: ({
        elements,
        profile,
        setCurrentStep,
        stripe,
        usersStore,
        showErrorToast,
      }) => async () => {
        try {
          await addUsersModalStore.onSubmit({
            elements,
            profile,
            stripe,
            usersStore,
          });

          setCurrentStep(SeatsAddedStep.key);
        } catch (error) {
          showErrorToast(error.message?.message ?? error.message);
        }
      },
    },
    [SeatsAddedStep.key]: {
      ...SeatsAddedStep,
      onNextClick: () => addUsersModalStore.close,
    },
  },
};
