import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useProfileStore } from '@/stores/RootStore';

import routePaths from './routePaths';

function UnauthenticatedRoute({ children }: PropsWithChildren) {
  const profileStore = useProfileStore();

  if (profileStore.profile?.id) {
    return <Navigate to={routePaths.myEvents} replace />;
  }

  return <>{children}</>;
}

export default observer(UnauthenticatedRoute);
