import '@/utils/i18n';
import './index.css';
import './config/fonts.css';
import '@fontsource/lato/700.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/roboto/500.css';

import { ChakraProvider } from '@chakra-ui/react';
import {
  ErrorBoundary as SentryErrorBoundary,
  init as sentryInit,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import { Settings } from 'luxon';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import Smartlook from 'smartlook-client';

import ErrorOccurred from './components/ErrorOccured';
import RequestErrorBoundary from './components/RequestErrorBoundary';
import router from './config/router';
import theme from './config/theme';
import * as serviceWorker from './serviceWorker';
import store from './store';

Settings.defaultZone = 'utc';

// Sentry integration

if (import.meta.env.PROD) {
  sentryInit({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_CATCHAPP_VERSION,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
        stripBasename: true
      }),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded', /Network Error/],
  });
}

// Smartlook integration

if (import.meta.env.PROD) {
  Smartlook.init(import.meta.env.VITE_SMARTLOOK_KEY);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <SentryErrorBoundary fallback={<ErrorOccurred />}>
          <RequestErrorBoundary>
            <RouterProvider router={router} />
          </RequestErrorBoundary>
        </SentryErrorBoundary>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (import.meta.hot) {
  import.meta.hot.accept();
}
