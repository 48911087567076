const INIT_STATE = {
  loading: true,
  appointments: [],
};

const confirmedReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'FETCH_CONFIRMED':
      return {
        ...state,
        loading: true,
      };

    case 'FETCH_CONFIRMED_SUCCESS':
      return {
        ...state,
        loading: false,
        appointments: action.payload,
      };

    default:
      return state;
  }
};

export default confirmedReducer;
