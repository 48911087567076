import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from './rootState';

interface ModalState {
  isOpen: boolean;
}

const initialState: ModalState = {
  isOpen: false,
};

export const modalSlice = createSlice({
  name: 'typePickerModal',
  initialState,
  reducers: {
    openTypePickerModal: (state) => ({ ...state, isOpen: true }),
    closeTypePickerModal: (state) => ({ ...state, isOpen: false }),
  },
});

export const { openTypePickerModal, closeTypePickerModal } = modalSlice.actions;

export const selectIsModalOpen = (state: RootState) =>
  state.typePickerModal.isOpen;

export default modalSlice.reducer;
