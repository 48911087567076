const INIT_STATE = {
  tel: '',
  isValid: false,
  formattedNumber: '',
  awaitingSms: false,
  loading: false,
  error: false,
  errorMessage: '',
  pin: '',
  tooManyTries: false,
};

const authTelReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_TEL':
      return {
        ...state,
        tel: action.payload.tel,
        formattedNumber: action.payload.formattedNumber,
        isValid: action.payload.isValid,
      };
    case 'UPDATE_PIN':
      return {
        ...state,
        pin: action.payload,
        error: false,
        errorMessage: '',
      };
    case 'REQUEST_SMS':
      return {
        ...state,
        loading: true,
        pin: '',
      };
    case 'OTP_SUCCESS':
      return {
        ...state,
        loading: false,
        awaitingSms: true,
      };
    case 'REQUEST_AUTHENTICATE_PIN':
      return {
        ...state,
        loading: true,
        awaitingSms: true,
      };
    case 'SUCCESSFUL_OTP':
      return {
        ...state,
        loading: false,
        awaitingSms: true,
      };
    case 'AUTH_ERROR':
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        pin: '',
        loading: false,
      };
    case 'CHANGE_TEL':
      return {
        ...state,
        awaitingSms: false,
      };
    case 'OTP_TOO_MANY_REQUESTS': {
      return {
        ...state,
        tooManyTries: true,
      };
    }
    default:
      return state;
  }
};

export default authTelReducer;
