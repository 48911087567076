import { Flex, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion'
import { func, node, string } from 'prop-types';
import { useMemo } from 'react';

import useBreakpoint from '@/hooks/useBreakpoint';

export const animationDuration = 0.25;

const pageAnimationVariants = {
  enter: {
    opacity: 0,
  },
  active: {
    opacity: 1,
    x: 0,
    transition: {
      duration: animationDuration,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: animationDuration,
      ease: 'easeOut',
    },
  },
};

export default function PageLayout({
  children,
  title,
  renderTitle,
  renderHeadButtons,
}) {
  const isLargerThanBreakpoint = useBreakpoint();

  const defaultTitleComponent = useMemo(
    () => title && <Heading fontSize="1.75rem">{title}</Heading>,
    [title],
  );

  const renderTitleWithDefault =
    renderTitle || ((titleComponent) => titleComponent);

  return (
    <Flex
      as={motion.div}
      direction="column"
      w="100%"
      flexGrow={1}
      h={isLargerThanBreakpoint ? 'auto' : '100%'}
      mb={isLargerThanBreakpoint ? '2.5rem' : 0}
      pb={isLargerThanBreakpoint ? '1rem' : '0'}
      pl={isLargerThanBreakpoint ? '5rem' : '0'}
      pr={isLargerThanBreakpoint ? '6.25rem' : '0'}
      position="relative"
      variants={pageAnimationVariants}
      initial="enter"
      animate="active"
      exit="exit"
    >
      {isLargerThanBreakpoint && title && (
        <Flex
          h="6.25rem"
          w="100%"
          align="center"
          pl="6.25rem"
          pr="6.25rem"
          justifyContent="space-between"
        >
          {renderTitleWithDefault(defaultTitleComponent)}
          {renderHeadButtons && renderHeadButtons()}
        </Flex>
      )}
      {children}
      {!isLargerThanBreakpoint &&
        title &&
        renderHeadButtons &&
        renderHeadButtons()}
    </Flex>
  );
}

PageLayout.propTypes = {
  children: node.isRequired,
  renderTitle: func,
  title: string,
  renderHeadButtons: func,
};

PageLayout.defaultProps = {
  renderTitle: undefined,
  title: '',
  renderHeadButtons: () => {},
};
