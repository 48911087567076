import { useTranslation } from 'react-i18next';

import UsersIcon from '@/components/atoms/UsersIcon';

import CalendarIcon from '../atoms/CalendarIcon';
import CogIcon from '../atoms/CogIcon';
import CogsIcon from '../atoms/CogsIcon';
import EventsIcon from '../atoms/EventsIcon';
import HelpIcon from '../atoms/HelpIcon';
import LinkIcon from '../atoms/LinkIcon';
import TickIcon from '../atoms/TickIcon';

export default function useMobileMenuItems() {
  const { t } = useTranslation();

  return [
    {
      Icon: CalendarIcon,
      path: '/my-availability',
      title: t('my_availability'),
    },
    {
      Icon: TickIcon,
      path: '/my-bookings',
      title: t('my_bookings'),
    },
    {
      Icon: EventsIcon,
      path: '/my-events',
      title: t('my_event_types'),
    },
    {
      path: '/manage-users',
      Icon: UsersIcon,
      title: t('users'),
      hidden: ({
        businessId,
        isBusinessAdmin,
      }: {
        businessId: number | null;
        isBusinessAdmin: boolean | null;
      }) => !!businessId && !isBusinessAdmin,
    },
    {
      Icon: LinkIcon,
      path: '/links',
      title: t('links_and_sharing'),
    },
    {
      Icon: CogsIcon,
      path: '/integrations',
      title: t('integrations'),
    },
    {
      Icon: CogIcon,
      path: '/settings',
      title: t('zoom_integration_required_2'),
    },
    {
      path: '/manage-subscription',
      title: t('manage_subscription'),
      hideInList: true,
    },
    {
      Icon: HelpIcon,
      path: '/help',
      title: t('help'),
      disabled: false,
    },
  ];
}
