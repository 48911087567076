/* eslint-disable no-param-reassign */
/* eslint-disable import/order */
// disabling rule due to simple-import-sort conflict

import {
  createAction,
  createAsyncThunk,
  createReducer,
  createSelector,
} from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import { Duration } from 'luxon';
import { useSelector } from 'react-redux';

import type { RootState } from '@/reducers/rootState';
import type AppointmentPoll from '@/types/AppointmentPoll';
import type AppointmentPollDetails from '@/types/AppointmentPollDetails';
import { authorizedApi } from '@/utils/apiUtils';

type BookingDetailsEntry = {
  isFetching?: boolean;
  error?: Error;
  data?: AppointmentPollDetails;
};

export type PendingBookingState = {
  areBookingsLoading: boolean;
  bookings: AppointmentPoll[];
  bookingDetails: Record<number, BookingDetailsEntry | undefined>;
  currentBookingId?: number;
  currentTimeSlot?: number;
  isPendingMeetingBooking: boolean;
  isPollCancellationModalOpen: boolean;
  isPollConfirmationModalOpen: boolean;
  isPollDetailsModalOpen: boolean;
  isFetchingPage: boolean;
  currentPageUrl: string | null;
  nextPageUrl: string | null;
  previousPageUrl: string | null;
};

const initialState: PendingBookingState = {
  areBookingsLoading: false,
  bookings: [],
  bookingDetails: {},
  currentBookingId: undefined,
  currentTimeSlot: undefined,
  isFetchingPage: false,
  isPendingMeetingBooking: false,
  isPollCancellationModalOpen: false,
  isPollConfirmationModalOpen: false,
  isPollDetailsModalOpen: false,
  currentPageUrl: null,
  nextPageUrl: null,
  previousPageUrl: null,
};

// ACTIONS

export const setCurrentBooking = createAction<
  PendingBookingState['currentBookingId']
>('pendingBookings/setCurrentBooking');
export const resetCurrentBooking = createAction(
  'pendingBookings/resetCurrentBooking',
);
export const setCurrentTimeSlot = createAction<
  PendingBookingState['currentTimeSlot']
>('pendingBookings/setCurrentTimeSlot');
export const resetCurrentTimeSlot = createAction(
  'pendingBookings/resetCurrentTimeSlot',
);
export const openDetailsModal = createAction(
  'pendingBookings/openDetailsModal',
);
export const closeDetailsModal = createAction(
  'pendingBookings/closeDetailsModal',
);
export const openCancellationModal = createAction(
  'pendingBookings/openCancellationModal',
);
export const closeCancellationModal = createAction(
  'pendingBookings/closeCancellationModal',
);
export const openConfirmationModal = createAction(
  'pendingBookings/openConfirmationModal',
);
export const closeConfirmationModal = createAction(
  'pendingBookings/closeConfirmationModal',
);
export const removeBookingWithId = createAction<
  PendingBookingState['currentBookingId']
>('pendingBookings/removeBookingWithId');

// ASYNC THUNKS

export const fetchPendingBookings = createAsyncThunk(
  'pendingBookings/fetchPendingBookings',
  async () => {
    const userId = (authorizedApi.defaults.headers?.id as string) ?? '';

    const { data } = await authorizedApi.get<{
      success: AppointmentPoll[];
      meta: { nextPageUrl: string | null; previousPageUrl: string | null };
    }>(`/users/${userId}/catchups/polls`);

    return {
      meta: data.meta,
      success: data.success,
      currentUrl: `/users/${userId}/catchups/polls`,
    };
  },
);

export const fetchPendingBookingsNextPage = createAsyncThunk(
  'pendingBookings/fetchPendingBookingsNextPage',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const { nextPageUrl } = state.bookings.pending;

    if (!nextPageUrl) {
      return thunkApi.rejectWithValue(new Error('No next page set'));
    }

    const { data } = await authorizedApi.get<{
      success: AppointmentPoll[];
      meta: { nextPageUrl: string | null; previousPageUrl: string | null };
    }>(nextPageUrl);

    return {
      meta: data.meta,
      success: data.success,
      currentUrl: nextPageUrl,
    };
  },
);

export const fetchPendingBookingsPreviousPage = createAsyncThunk(
  'pendingBookings/fetchPendingBookingsPreviousPage',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const { previousPageUrl } = state.bookings.pending;

    if (!previousPageUrl) {
      return thunkApi.rejectWithValue(new Error('No previous page set'));
    }

    const { data } = await authorizedApi.get<{
      success: AppointmentPoll[];
      meta: { nextPageUrl: string | null; previousPageUrl: string | null };
    }>(previousPageUrl);

    return {
      meta: data.meta,
      success: data.success,
      currentUrl: previousPageUrl,
    };
  },
);

export const fetchPendingBookingsCurrentPage = createAsyncThunk(
  'pendingBookings/fetchPendingBookingsCurrentPage',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const { currentPageUrl, previousPageUrl } = state.bookings.pending;

    if (!currentPageUrl) {
      return thunkApi.rejectWithValue('No current page set');
    }

    const { data: currentData } = await authorizedApi.get<{
      success: AppointmentPoll[];
      meta: { nextPageUrl: string | null; previousPageUrl: string | null };
    }>(currentPageUrl);

    if (currentData.success.length > 0) {
      return {
        meta: currentData.meta,
        success: currentData.success,
        currentUrl: currentPageUrl,
      };
    }

    if (!previousPageUrl) {
      return thunkApi.rejectWithValue(new Error('No previous page set'));
    }

    const { data: previousData } = await authorizedApi.get<{
      success: AppointmentPoll[];
      meta: { nextPageUrl: string | null; previousPageUrl: string | null };
    }>(previousPageUrl);

    return {
      meta: previousData.meta,
      success: previousData.success,
      currentUrl: previousPageUrl,
    };
  },
);

export const fetchPendingMeetingDetails = createAsyncThunk<
  BookingDetailsEntry['data'],
  number,
  {
    rejectValue: string;
  }
>('pendingBookings/fetchPendingMeetingDetails', async (catchupId, thunkApi) => {
  try {
    const { data } = await authorizedApi.get<{
      success: [AppointmentPollDetails];
    }>(`/catchup/details/${catchupId}/EVENTSTATUS_OUTGOING`);

    return data.success?.[0];
  } catch (error) {
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const bookPendingMeeting = createAsyncThunk<
  boolean,
  Pick<PendingBookingState, 'currentTimeSlot' | 'currentBookingId'>,
  {
    rejectValue: string;
  }
>(
  'pendingBookings/bookPendingMeeting',
  async ({ currentTimeSlot, currentBookingId }, thunkApi) => {
    if (
      typeof currentTimeSlot === 'undefined' ||
      typeof currentBookingId === 'undefined'
    )
      throw new Error('Invalid data');

    try {
      const { data } = await authorizedApi.post<{ success: boolean }>(
        `/catchup/confirm-voted-event/${currentBookingId}/${currentTimeSlot}`,
      );

      return data.success;
    } catch (error) {
      return thunkApi.rejectWithValue((error as Error).message);
    }
  },
);

export const cancelPendingMeeting = createAsyncThunk<
  boolean,
  Pick<PendingBookingState, 'currentBookingId'>,
  {
    rejectValue: string;
  }
>(
  'pendingBookings/cancelPendingMeeting',
  async ({ currentBookingId }, thunkApi) => {
    if (typeof currentBookingId === 'undefined')
      throw new Error('Invalid data');

    try {
      const userId = (authorizedApi.defaults.headers?.id as string) ?? '';

      const { data } = await authorizedApi.delete<{ success: boolean }>(
        `/users/${userId}/catchups/polls/${currentBookingId}`,
      );

      if (!data.success) {
        throw new Error('Poll deletion failed');
      }

      return true;
    } catch (error) {
      return thunkApi.rejectWithValue((error as Error).message);
    }
  },
);

// REDUCER

export const pendingBookingsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCurrentBooking, (self, action) => {
      self.currentBookingId = action.payload;
    })
    .addCase(resetCurrentBooking, (self) => {
      self.currentBookingId = undefined;
    })
    .addCase(setCurrentTimeSlot, (self, action) => {
      self.currentTimeSlot = action.payload;
    })
    .addCase(resetCurrentTimeSlot, (self) => {
      self.currentTimeSlot = undefined;
    })
    .addCase(openCancellationModal, (self) => {
      self.isPollCancellationModalOpen = true;
    })
    .addCase(closeCancellationModal, (self) => {
      self.isPollCancellationModalOpen = false;
    })
    .addCase(openDetailsModal, (self) => {
      self.isPollDetailsModalOpen = true;
      self.isPollConfirmationModalOpen = false;
    })
    .addCase(closeDetailsModal, (self) => {
      self.isPollDetailsModalOpen = false;
    })
    .addCase(openConfirmationModal, (self) => {
      self.isPollConfirmationModalOpen = true;
      self.isPollDetailsModalOpen = false;
    })
    .addCase(closeConfirmationModal, (self) => {
      self.isPollConfirmationModalOpen = false;
    })
    .addCase(fetchPendingBookings.pending, (self) => {
      self.areBookingsLoading = true;
      self.nextPageUrl = null;
      self.previousPageUrl = null;
    })
    .addCase(fetchPendingBookings.fulfilled, (self, action) => {
      self.areBookingsLoading = false;
      self.bookings = action.payload.success;
      self.nextPageUrl = action.payload.meta.nextPageUrl;
      self.previousPageUrl = action.payload.meta.previousPageUrl;
      self.currentPageUrl = action.payload.currentUrl;
    })
    .addCase(fetchPendingBookings.rejected, (self) => {
      self.areBookingsLoading = false;
    })
    .addCase(fetchPendingBookingsNextPage.pending, (self) => {
      self.isFetchingPage = true;
    })
    .addCase(fetchPendingBookingsNextPage.fulfilled, (self, action) => {
      self.isFetchingPage = false;
      self.bookings = action.payload.success;
      self.nextPageUrl = action.payload.meta.nextPageUrl;
      self.previousPageUrl = action.payload.meta.previousPageUrl;
      self.currentPageUrl = action.payload.currentUrl;
    })
    .addCase(fetchPendingBookingsNextPage.rejected, (self) => {
      self.isFetchingPage = false;
    })
    .addCase(fetchPendingBookingsPreviousPage.pending, (self) => {
      self.isFetchingPage = true;
    })
    .addCase(fetchPendingBookingsPreviousPage.fulfilled, (self, action) => {
      self.isFetchingPage = false;
      self.bookings = action.payload.success;
      self.nextPageUrl = action.payload.meta.nextPageUrl;
      self.previousPageUrl = action.payload.meta.previousPageUrl;
      self.currentPageUrl = action.payload.currentUrl;
    })
    .addCase(fetchPendingBookingsPreviousPage.rejected, (self) => {
      self.isFetchingPage = false;
    })
    .addCase(fetchPendingBookingsCurrentPage.pending, (self) => {
      self.isFetchingPage = true;
    })
    .addCase(fetchPendingBookingsCurrentPage.fulfilled, (self, action) => {
      self.isFetchingPage = false;
      self.bookings = action.payload.success;
      self.nextPageUrl = action.payload.meta.nextPageUrl;
      self.previousPageUrl = action.payload.meta.previousPageUrl;
      self.currentPageUrl = action.payload.currentUrl;
    })
    .addCase(fetchPendingBookingsCurrentPage.rejected, (self) => {
      self.isFetchingPage = false;
    })
    .addCase(bookPendingMeeting.pending, (self) => {
      self.isPendingMeetingBooking = true;
    })
    .addCase(bookPendingMeeting.fulfilled, (self, action) => {
      self.isPendingMeetingBooking = false;
      self.bookings = self.bookings.filter(
        ({ catchup_id }) => catchup_id !== action.meta.arg.currentBookingId,
      );
      delete self.bookingDetails[action.meta.arg.currentBookingId as number];
    })
    .addCase(bookPendingMeeting.rejected, (self) => {
      self.isPendingMeetingBooking = false;
    })
    .addCase(fetchPendingMeetingDetails.pending, (self, action) => {
      self.bookingDetails[action.meta.arg] = {
        data: undefined,
        error: undefined,
        isFetching: true,
      };
    })
    .addCase(fetchPendingMeetingDetails.fulfilled, (self, action) => {
      self.bookingDetails[action.meta.arg] = {
        data: action.payload,
        error: undefined,
        isFetching: false,
      };
    })
    .addCase(fetchPendingMeetingDetails.rejected, (self, action) => {
      self.bookingDetails[action.meta.arg] = {
        data: undefined,
        error: action.error as AxiosError,
        isFetching: false,
      };
    })
    .addCase(cancelPendingMeeting.pending, (self) => {
      self.isPendingMeetingBooking = true;
    })
    .addCase(cancelPendingMeeting.fulfilled, (self, action) => {
      self.isPendingMeetingBooking = false;
      self.bookings = self.bookings.filter(
        ({ catchup_id }) => catchup_id !== action.meta.arg.currentBookingId,
      );
    })
    .addCase(cancelPendingMeeting.rejected, (self) => {
      self.isPendingMeetingBooking = false;
    });
});

// SELECTORS

export const useCurrentBooking = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.pending.bookings,
      (state: RootState) => state.bookings.pending.currentBookingId,
      (bookings, currentBookingId) =>
        bookings.find(({ catchup_id }) => catchup_id === currentBookingId),
    ),
  );

export const useCurrentBookingDetails = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.pending.bookingDetails,
      (state: RootState) => state.bookings.pending.currentBookingId,
      (bookingDetails, currentBookingId) =>
        typeof currentBookingId === 'undefined'
          ? {}
          : bookingDetails[currentBookingId],
    ),
  );

export const useCurrentBookingId = () =>
  useSelector((state: RootState) => state.bookings.pending.currentBookingId);

export const useIsPollDetailsModalOpen = () =>
  useSelector(
    (state: RootState) => state.bookings.pending.isPollDetailsModalOpen,
  );

export const useIsPollCancellationModalOpen = () =>
  useSelector(
    (state: RootState) => state.bookings.pending.isPollCancellationModalOpen,
  );

export const useIsPollConfirmationModalOpen = () =>
  useSelector(
    (state: RootState) => state.bookings.pending.isPollConfirmationModalOpen,
  );

export const useAreBookingsLoading = () =>
  useSelector((state: RootState) => state.bookings.pending.areBookingsLoading);

export const useIsPendingMeetingBooking = () =>
  useSelector(
    (state: RootState) => state.bookings.pending.isPendingMeetingBooking,
  );

export const useBookings = () =>
  useSelector((state: RootState) => state.bookings.pending.bookings);

export const useCurrentTimeSlot = () =>
  useSelector((state: RootState) => state.bookings.pending.currentTimeSlot);

export const useCurrentTimeSlotValue = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.pending.currentTimeSlot,
      (state: RootState) => state.bookings.pending.currentBookingId,
      (state: RootState) => state.bookings.pending.bookingDetails,
      (currentTimeSlot, currentBookingId, bookingDetails) => {
        if (typeof currentBookingId === 'undefined') return undefined;

        return bookingDetails[currentBookingId]?.data?.events?.find(
          ({ uid }) => uid === currentTimeSlot,
        );
      },
    ),
  );

export const useCurrentTimeSlotVotes = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.pending.bookingDetails,
      (state: RootState) => state.bookings.pending.currentBookingId,
      (state: RootState) => state.bookings.pending.currentTimeSlot,
      (bookingDetails, currentBookingId, currentTimeSlot) => {
        if (typeof currentBookingId === 'undefined') return [];

        const currentBookingDetails = bookingDetails[currentBookingId]?.data;

        if (!currentBookingDetails) return [];

        return currentBookingDetails.events.filter(
          ({ uid }) => uid === currentTimeSlot,
        );
      },
    ),
  );

export const useCurrentBookingDuration = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.pending.bookingDetails,
      (state: RootState) => state.bookings.pending.currentBookingId,
      (bookingDetails, currentBookingId) => {
        if (typeof currentBookingId === 'undefined') return 0;

        const currentBookingDetails = bookingDetails[currentBookingId];

        if (!currentBookingDetails?.data) return 0;

        const [firstEvent] = currentBookingDetails?.data?.events ?? [];

        const eventDuration =
          (firstEvent?.to_timestamp ?? 0) - (firstEvent?.from_timestamp ?? 0);

        return Number.isNaN(eventDuration)
          ? 0
          : Duration.fromObject({ seconds: eventDuration }).toMillis();
      },
    ),
  );

export const useCurrentBookingLocation = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.pending.bookingDetails,
      (state: RootState) => state.bookings.pending.currentBookingId,
      (bookingDetails, currentBookingId) => {
        if (typeof currentBookingId === 'undefined') return '';

        const currentBookingDetails = bookingDetails[currentBookingId];

        if (!currentBookingDetails?.data) return '';

        const [locationHeader, ...locationDetails] =
          currentBookingDetails.data?.location?.location?.split(/:\s*/) ?? [];

        if (/^Custom|^Location/.test(locationHeader)) {
          return currentBookingDetails.data?.location?.location;
        }

        return locationDetails.length > 0
          ? locationDetails.join(': ')
          : locationHeader;
      },
    ),
  );

export const useHasNextPageUrl = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.pending.nextPageUrl,
      (nextPageUrl) => Boolean(nextPageUrl),
    ),
  );

export const useHasPreviousPageUrl = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.bookings.pending.previousPageUrl,
      (previousPageUrl) => Boolean(previousPageUrl),
    ),
  );

export const useIsFetchingPage = () =>
  useSelector((state: RootState) => state.bookings.pending.isFetchingPage);
