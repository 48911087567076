import { captureException } from '@sentry/react';
import { DateTime } from 'luxon';
import { types } from 'mobx-state-tree';

import { autoFlow } from '@/stores/storeUtils';
import { authorizedApi } from '@/utils/apiUtils';

export const AppSumoTiers = {
  1: 'catchappbookings_tier1',
  2: 'catchappbookings_tier2',
  3: 'catchappbookings_tier3',
  4: 'catchappbookings_tier4',
  5: 'catchappbookings_tier5',
};

export const Subscription = types
  .model({
    amount: types.maybeNull(types.number),
    AppSumoPlan: types.maybeNull(types.string),
    created: types.maybeNull(types.integer),
    currency: types.maybeNull(types.string),
    current_period_end: types.maybeNull(types.integer),
    id: types.string,
    interval: types.maybeNull(types.string),
    isTrial: types.boolean,
    status: types.maybeNull(types.string),
    duration: types.maybeNull(types.string),
    percentOff: types.maybeNull(types.number),
    quantity: types.maybeNull(types.number),
  })
  .views((self) => ({
    get isTrialing() {
      return self.status === 'trialing';
    },
  }));

export const defaultSubscriptionInterval = 'month';

const SubscriptionStore = types
  .model('SubscriptionStore', {
    subscription: Subscription,
  })
  .actions((self) =>
    autoFlow({
      *getSubscription() {
        const userId = authorizedApi.defaults.headers.id;

        try {
          let response;

          if (import.meta.env.DEV) {
            const [testPromise, nonTestPromise] = yield Promise.allSettled([
              authorizedApi.get(`/appointments/users/${userId}/subscription`, {
                params: {
                  stripeTest: true,
                },
              }),
              authorizedApi.get(`/appointments/users/${userId}/subscription`),
            ]);

            if (testPromise.status === 'fulfilled') {
              response = testPromise.value;
            } else {
              response = nonTestPromise.value;
            }
          } else {
            response = yield authorizedApi.get(
              `/appointments/users/${userId}/subscription`,
            );
          }

          const data = response.data?.subscription;

          const [{ plan }] = data?.items?.data ?? [{}];

          const AppSumoPlan = plan?.id?.includes('catchappbookings_tier')
            ? data.plan.id
            : null;

          if (plan?.id !== 'no-price-id') {
            self.subscription = {
              id: data.id,
              created: DateTime.fromSeconds(data.created).toMillis(),
              current_period_end: DateTime.fromSeconds(
                data.current_period_end,
              ).toMillis(),
              isTrial: false,
              amount: plan.amount,
              currency: plan.currency,
              interval: plan.interval,
              status: data.status,
              AppSumoPlan,
              duration: data.discount?.coupon?.duration,
              percentOff: data.discount?.coupon?.percent_off,
              quantity: data.quantity,
            };
          } else {
            self.subscription = {
              id: data.id,
              created: DateTime.fromSeconds(data.created).toMillis(),
              currency: plan.currency,
              current_period_end: DateTime.fromSeconds(
                data.current_period_end,
              ).toMillis(),
              isTrial: true,
            };
          }
        } catch (error) {
          self.subscription = {
            id: 'no-subscription-id',
            created: 0,
            current_period_end: 0,
            isTrial: true,
          };

          if (!error?.response) {
            captureException(error);
          }
        }
      },
      setinterval(interval) {
        self.subscription.interval = interval;
      },
      *cancelSubscription(reason) {
        const userId = authorizedApi.defaults.headers.id;

        yield authorizedApi.post(
          `/appointments/users/${userId}/cancel-subscription`,
          { cancellationReason: reason },
        );
      },
    }),
  );

const subscriptionStore = SubscriptionStore.create({
  subscription: {
    id: '',
    isTrial: false,
  },
});

export default subscriptionStore;
