import {
  Box,
  Button,
  Flex,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import { func } from 'prop-types';
import { useFormContext } from 'react-hook-form-6';
import { useTranslation } from 'react-i18next';

import UsersForm from '@/components/pages/Users/organisms/AddUsersModal/organisms/UsersForm';

function AddUsersStepHeader() {
  const { t } = useTranslation();

  return (
    <ModalHeader px={4} w="100%">
      <Text
        borderBottomColor="gray.300"
        borderBottomWidth={1}
        letterSpacing="wide"
        pb={4}
        textTransform="uppercase"
        variant="black"
      >
        {t('add_users')}
      </Text>
    </ModalHeader>
  );
}

function AddUsersStepBody() {
  const { t } = useTranslation();

  const { errors } = useFormContext();

  return (
    <>
      <Text mb={4}>{t('add_users_emails_explainer')}:</Text>
      {errors.users && (
        <Text color="red.400" fontWeight="bold" mb={4}>
          {errors.users.message}
        </Text>
      )}
      <UsersForm />
    </>
  );
}
function AddUsersStepFooter({ onCancelClick, onNextClick }) {
  const { t } = useTranslation();

  const {
    formState: { isValid },
  } = useFormContext();

  return (
    <ModalFooter as={Flex} direction="row" justifyContent="space-between">
      <Button variant="cancel" onClick={onCancelClick}>
        {t('cancel')}
      </Button>
      <Box mr={2} />
      <Button
        variant={isValid ? 'submit' : 'cancel'}
        disabled={!isValid}
        onClick={onNextClick}
      >
        {t('next')}
      </Button>
    </ModalFooter>
  );
}

AddUsersStepFooter.propTypes = {
  onCancelClick: func.isRequired,
  onNextClick: func.isRequired,
};

export default {
  Header: AddUsersStepHeader,
  Body: AddUsersStepBody,
  Footer: AddUsersStepFooter,
  key: Symbol('AddUsersStep'),
};
