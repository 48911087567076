import { authorizedApi } from '@/utils/apiUtils';
import i18n from '@/utils/i18n';

export const confirmInvitation = async ({ hash }) => {
  const loggedInUserId = authorizedApi.defaults.headers.id;

  if (loggedInUserId && hash) {
    const response = await authorizedApi.post('/business/business-invite', {
      token: hash,
      user_id: loggedInUserId,
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return;
  }

  if (!loggedInUserId || !hash) {
    return;
  }

  throw new Error(i18n.t('failure_unknown_error'));
};
