/* eslint-disable react/jsx-props-no-spreading */

import { wrapCreateBrowserRouter } from '@sentry/react';
import { Fragment, lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import App from '@/App';
import ProgressFallback from '@/components/molecules/ProgressFallback';
import MainLayout from '@/components/templates/MainLayout';

import AuthenticatedRoute from './AuthenticatedRoute';
import AvailableForUpgradeRoute from './AvailableForUpgradeRoute';
import RedirectDuringOnboardingRoute from './RedirectDuringOnboardingRoute';
import RedirectToOnboardingRoute from './RedirectToOnboardingRoute';
import {
  anyUsersRoutes,
  nonLoggedInRoutes,
  onboardingRoutes,
  publicRoutes,
  subscribedUsersRoutes,
  upgradeRoutes,
} from './routes';
import SubscribedUserRoute from './SubscribedUserRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

const NotFound = lazy(() => import('@/components/NotFound'));

const createRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = createRouter([
  {
    element: <App />,
    errorElement: (
      <Suspense fallback={<ProgressFallback />}>
        <NotFound />
      </Suspense>
    ),
    children: [
      ...publicRoutes.map(({ path, page: Page, guard: Guard = Fragment }) => ({
        path,
        element: (
          <Guard>
            <Suspense fallback={<ProgressFallback />}>
              <Page />
            </Suspense>
          </Guard>
        ),
      })),
      ...nonLoggedInRoutes.map(
        ({ path, page: Page, layout: Layout = Fragment, layoutProps }) => ({
          path,
          element: (
            <UnauthenticatedRoute>
              <Layout key="layout" {...layoutProps}>
                <Suspense fallback={<ProgressFallback />}>
                  <Page />
                </Suspense>
              </Layout>
            </UnauthenticatedRoute>
          ),
        }),
      ),
      ...onboardingRoutes.map(({ path, page: Page }) => ({
        path,
        element: (
          <AuthenticatedRoute>
            <RedirectDuringOnboardingRoute>
              <Suspense fallback={<ProgressFallback />}>
                <Page />
              </Suspense>
            </RedirectDuringOnboardingRoute>
          </AuthenticatedRoute>
        ),
      })),
      ...upgradeRoutes.map(({ path, page: Page }) => ({
        path,
        element: (
          <AuthenticatedRoute>
            <AvailableForUpgradeRoute>
              <Suspense fallback={<ProgressFallback />}>
                <Page />
              </Suspense>
            </AvailableForUpgradeRoute>
          </AuthenticatedRoute>
        ),
      })),
      ...anyUsersRoutes.map(({ path, page: Page }) => ({
        path,
        element: (
          <AuthenticatedRoute>
            <RedirectToOnboardingRoute>
              <MainLayout
                Content={
                  <Suspense fallback={<ProgressFallback />}>
                    <Page />
                  </Suspense>
                }
              />
            </RedirectToOnboardingRoute>
          </AuthenticatedRoute>
        ),
      })),
      ...subscribedUsersRoutes.map(
        ({ path, page: Page, guard: Guard = Fragment }) => ({
          path,
          element: (
            <AuthenticatedRoute>
              <Guard>
                <RedirectToOnboardingRoute>
                  <SubscribedUserRoute>
                    <MainLayout
                      Content={
                        <Suspense fallback={<ProgressFallback />}>
                          <Page />
                        </Suspense>
                      }
                    />
                  </SubscribedUserRoute>
                </RedirectToOnboardingRoute>
              </Guard>
            </AuthenticatedRoute>
          ),
        }),
      ),
    ],
  },
]);

export default router;
