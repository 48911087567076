import { Flex, Image, Select } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ChevronDownIcon from '@/assets/Icons _ Arrows .svg';
import LanguagesIcon from '@/assets/Icons _ Languages.svg';

export default function LanguageSwitcher() {
  const { i18n, t } = useTranslation();

  const languageOptions = useMemo(
    () =>
      Object.keys(i18n.options.resources ?? {}).reduce<
        Array<{ label: string; value: string }>
      >((accumulatedLanguages, language) => {
        const resolvedLanguageData = i18n.getDataByLanguage(language);

        if (!resolvedLanguageData) return accumulatedLanguages;

        return accumulatedLanguages.concat({
          value: language,
          label: t(`language_${language}`),
        });
      }, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n, i18n.options, i18n.resolvedLanguage, t],
  );

  return (
    <Flex
      alignItems="center"
      direction="row"
      columnGap={2}
    >
      <Image src={LanguagesIcon} />
      <Select
        fontSize="sm"
        h={6}
        icon={<Image src={ChevronDownIcon} />}
        minW="100px"
        onChange={(event) => {
          void i18n.changeLanguage(event.target.value);
        }}
        value={i18n.resolvedLanguage}
        variant="unstyled"
      >
        {languageOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </Flex>
  );
}
