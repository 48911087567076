import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { DateTime, Duration, DurationUnit } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import WarningIcon from '@/assets/Icons_WhiteWarning.svg';
import useBreakpoint from '@/hooks/useBreakpoint';

import TrialModal from './TrialModal';

interface TrialBannerProperties {
  currentPeriodEndTimestamp: number;
}

const formats: DurationUnit[] = ['days', 'hours', 'minutes', 'seconds'];

export default function TrialBanner({
  currentPeriodEndTimestamp,
}: TrialBannerProperties) {
  const { t } = useTranslation();

  const isLargerThanBreakpoint = useBreakpoint();

  const currentPeriodEndHours = DateTime.fromMillis(
    currentPeriodEndTimestamp,
  ).startOf('hour');

  const timeUntilSubscriptionEnd = useMemo(() => {
    const subscriptionPeriodDurationHours =
      currentPeriodEndHours.diffNow(formats);

    const formatToDisplay = formats.find(
      (format) => subscriptionPeriodDurationHours.get(format) > 0,
    );

    if (!formatToDisplay) {
      return undefined;
    }

    return Duration.fromObject({
      [formatToDisplay]: subscriptionPeriodDurationHours.get(formatToDisplay),
    }).toHuman();
  }, [currentPeriodEndHours]);

  return (
    <Flex
      backgroundColor="red.400"
      color="white"
      w="100%"
      py={2.5}
      fontSize="lg"
      direction={isLargerThanBreakpoint ? 'row' : 'column'}
      justify="space-around"
      align="center"
    >
      <Flex
        mb={isLargerThanBreakpoint ? 0 : 2}
        whiteSpace="break-spaces"
        flexWrap="nowrap"
      >
        {timeUntilSubscriptionEnd ? (
          <>
            <Image src={WarningIcon} height="100%" marginRight={2} my="auto" />
            <Box>
              <Text variant="bold">
                {timeUntilSubscriptionEnd}{' '}
                {t('subscription_avoid_loss_expired_6')}
              </Text>
              <Text fontSize="xs">
                {t('subscription_avoid_loss_expired_2')}{' '}
                {t('subscription_avoid_loss_expired_3')}
              </Text>
            </Box>
          </>
        ) : (
          <>
            <Image src={WarningIcon} height="100%" marginRight={2} my="auto" />
            <Box>
              <Text variant="bold" fontSize={{ base: 'md', sm: 'xl' }}>
                {t('subscription_avoid_loss_expired_1')}
              </Text>
              <Text fontSize="xs">
                {t('subscription_avoid_loss_expired_2')}{' '}
                {t('subscription_avoid_loss_expired_4')}
              </Text>
            </Box>
          </>
        )}
      </Flex>
      <TrialModal />
    </Flex>
  );
}
