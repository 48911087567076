import { useBreakpointValue } from '@chakra-ui/react';

export const defaultBreakpoint = 'xl';

export default function useBreakpoint() {
  const isLargerThanBreakpoint = useBreakpointValue({
    base: false,
    [defaultBreakpoint]: true,
  }, defaultBreakpoint);

  return isLargerThanBreakpoint || false;
}
