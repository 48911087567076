import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import { Duration } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardSection } from '@/components/molecules/CardSection';
import { usePaymentStore } from '@/stores/RootStore';

export const CheckoutForm = observer(({ showAuthErrorMessage = true }) => {
  const { t } = useTranslation();

  const paymentStore = usePaymentStore();

  const [promoCode, setPromoCode] = useState('');

  const {
    init,
    payment: {
      authError,
      authErrorMessage,
      couponDiscount,
      couponDuration,
      couponError,
      processing,
      showCoupon,
      successCoupon,
    },
  } = paymentStore;

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction="column" pt={4} className="full-height">
      {authError ? (
        showAuthErrorMessage && (
          <Box textAlign="center">
            <Text className="text-danger">{authErrorMessage}</Text>
            <Box mb={4} />
            <Button
              variant="submit"
              onClick={() => {
                window.location.reload();
              }}
            >
              {t('try_again')}
            </Button>
          </Box>
        )
      ) : (
        <Box
          className="full-height"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <fieldset
            disabled={!!processing}
            className="full-height"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Flex
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              className="full-height"
            >
              <Box w="100%" textAlign="center">
                <CardSection />

                <Box mb={4} />

                {successCoupon ? (
                  <Text color="green.500">{t('discount_applied')}</Text>
                ) : (
                  <>
                    <Flex
                      mb={2}
                      style={{ cursor: 'pointer', textAlign: 'left' }}
                      onClick={() => paymentStore.showHideCoupon()}
                      gridColumnGap={1}
                      wrap="nowrap"
                    >
                      <Text as="strong">{t('have_a_promo_code_question')}</Text>
                      <Text
                        fontWeight={600}
                        color="orange.500"
                        textTransform="uppercase"
                      >
                        {t('enter_promo_code')}
                      </Text>
                    </Flex>

                    <Flex mb={4}>
                      {showCoupon && (
                        <>
                          <Input
                            aria-label={t('promo_code')}
                            borderRadius="0"
                            errorBorderColor="red.400"
                            isInvalid={couponError}
                            value={promoCode}
                            onChange={(event) => {
                              setPromoCode(event.target.value);
                            }}
                            placeholder={t('promo_code')}
                            type="text"
                            _focus={{
                              border: couponError ? '1px solid' : '2px solid',
                              borderColor: 'gray.300',
                            }}
                            _hover={{ borderColor: undefined }}
                          />
                          <Button
                            borderRadius={0}
                            bg="gray.200"
                            onClick={() => {
                              paymentStore.validateCoupon(promoCode);
                            }}
                            px="1.5rem"
                            textTransform="uppercase"
                            _focus={{
                              border: 0,
                            }}
                            _hover={{ bg: 'gray.300' }}
                          >
                            {t('apply')}
                          </Button>
                        </>
                      )}
                    </Flex>
                  </>
                )}
              </Box>

              <Box w="100%">
                {successCoupon && couponDiscount === 100 && (
                  <Box textAlign="center">
                    {couponDuration == null ? (
                      <Text as="small" variant="bold" color="black">
                        {t('card_charge_never')}
                      </Text>
                    ) : (
                      <Text as="small">
                        {t('card_charge_not_for_months')}{' '}
                        {Duration.fromObject({
                          months: couponDuration,
                        }).toHuman()}
                      </Text>
                    )}
                  </Box>
                )}
              </Box>
            </Flex>
          </fieldset>
        </Box>
      )}
    </Flex>
  );
});
