export const EventLocationOptions = {
  Skype: 'skype',
  Zoom: 'zoom',
  'Microsoft Teams': 'Microsoft Teams',
  // TODO: unify this!
  'Google Meet': 'Google Meet',
  'Google meet': 'Google Meet',
  Call: 'call',
  Location: 'custom',
  AttendeeLocation: 'attendee-location',
  Custom: 'custom-location',
};
