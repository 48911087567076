import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useProfileStore, useSubscriptionStore } from '@/stores/RootStore';

import routePaths from './routePaths';

function RedirectToOnboardingRoute({ children }: PropsWithChildren) {
  const profileStore = useProfileStore();
  const subscriptionStore = useSubscriptionStore();

  const lastOnboardingPage =
    Number(profileStore.profile?.lastOnboardingPage) || 0;

  if (
    profileStore.profile.isBusinessAdmin &&
    subscriptionStore.subscription?.id &&
    subscriptionStore.subscription.id === 'no-subscription-id' &&
    lastOnboardingPage < 3
  ) {
    return <Navigate to={routePaths.calendars} replace />;
  }

  if (!profileStore.profile.isBusinessAdmin && lastOnboardingPage < 2) {
    return <Navigate to={routePaths.calendars} replace />;
  }

  return <>{children}</>;
}

export default observer(RedirectToOnboardingRoute);
