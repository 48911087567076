import { Box, Center, Text } from '@chakra-ui/react';
import React from 'react';

export default function CustomToast({ message, status }) {
  return (
    <Center>
      <Box
        w="fit-content"
        py="0.5rem"
        px="0.75rem"
        borderColor={status === 'success' ? 'teal.200' : 'red.400'}
        borderWidth="2px"
        borderRadius="5px"
        boxShadow="md"
        bg="white"
        mb="1.5rem"
      >
        <Text
          fontSize="sm"
          color={status === 'success' ? 'teal.200' : 'red.400'}
          textAlign="center"
          fontWeight="600"
        >
          {message}
        </Text>
      </Box>
    </Center>
  );
}
