import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Input,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { FormEventHandler, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BrandingLogoWithText from '@/assets/Branding _ Logo with text.svg';
import BillingInformationBox from '@/components/Onboarding/BillingInformationBox';
import StepIndicator from '@/components/Onboarding/StepIndicator';
import { inputStyles } from '@/components/pages/AddEditMyEvent/atoms/inputCommon';
import addUsersModalStore from '@/components/pages/Users/organisms/AddUsersModal/addUsersModalStore';
import BillingInformationDiscountBanner from '@/components/signUpOnboarding/BillingInformation/BillingInformationDiscountBanner';
import routePaths from '@/config/routePaths';
import getStripePromise from '@/config/stripe';
import useBreakpoint, { defaultBreakpoint } from '@/hooks/useBreakpoint';
import useSuccessToast from '@/hooks/useSuccessToast';
import useUpgradeApi from '@/hooks/useUpgradeApi';
import {
  usePaymentStore,
  useProfileStore,
  useUsersStore,
} from '@/stores/RootStore';
import DesktopUltraWideLayout from '@/ui/layout/DesktopUltraWideLayout';
import { MobileLayout } from '@/ui/layout/MobileLayout';

interface CalculateCostReturnData {
  cost_per_seat: string;
  currency: string;
  total_cost: string;
}

interface OnboardingBillingProps {
  type?: 'onBoarding' | 'modal';
  onClose?: () => void;
}

const defaultProps: OnboardingBillingProps = {
  type: 'onBoarding',
  onClose: undefined,
};

function OnboardingBilling({
  type = 'onBoarding',
  onClose,
}: OnboardingBillingProps) {
  const { t } = useTranslation();

  const onboardingCoupon = 'SUB24OFFER60';

  const isOnBoarding = type === 'onBoarding';

  const isLargerThanBreakpoint = useBreakpoint();
  const xlLayout = isOnBoarding ? DesktopUltraWideLayout : MobileLayout;

  const showSuccessToast = useSuccessToast();

  const Wrapper = useBreakpointValue(
    {
      base: MobileLayout,
      xl: xlLayout,
    },
    'xl',
  );

  const navigate = useNavigate();
  const stripePromise = useMemo(() => getStripePromise(), []);

  const upgrade = useUpgradeApi();

  const paymentStore = usePaymentStore();
  const profileStore = useProfileStore();
  const usersStore = useUsersStore();

  const [billingType, setBillingType] = useState<'year' | 'month'>('year');
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState(12);
  const [yearlyPrice, setYearlyPrice] = useState(10);
  const [totalPrice, setTotalPrice] = useState<string | undefined>();
  const [isPromoCodeVisible, setPromoCodeVisible] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [cardFocused, setCardFocused] = useState(false);
  const [cancelController, setCancelController] = useState(
    new AbortController(),
  );

  const isOnUpgradePage = window.location.pathname === routePaths.upgrade;

  // destructuring for detection in conditional branches of render
  const {
    authError,
    authErrorMessage,
    cardComplete,
    couponDiscount,
    couponDuration,
    couponError,
    couponErrorMessage,
    successCoupon,
  } = paymentStore.payment;

  const isAnnually = billingType === 'year';
  const isMonthly = billingType === 'month';

  const { userSeats } = useUsersStore();
  const activeSeats = userSeats.filter(
    (seat) => seat.status === 'Active',
  ).length;

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat(window.navigator?.language ?? 'en-GB', {
        style: 'currency',
        currency: profileStore.profile.stripeCurrency || 'GBP',
      }),
    [profileStore.profile.stripeCurrency],
  );

  const totalPriceFormatted = useMemo(() => {
    if (!totalPrice) {
      return '';
    }

    return currencyFormatter.format(Number.parseFloat(totalPrice));
  }, [currencyFormatter, totalPrice]);

  const discountedTotalPriceFormatted = useMemo(() => {
    if (!totalPrice || !couponDiscount) {
      return '';
    }

    const parsedTotalPrice = Number.parseFloat(totalPrice);
    const discountedPrice = (parsedTotalPrice * (100 - couponDiscount)) / 100;

    return currencyFormatter.format(discountedPrice);
  }, [couponDiscount, currencyFormatter, totalPrice]);

  const discountLabel = useMemo(() => {
    if (!successCoupon) return '';

    if (couponDuration == null) return t('free_forever');

    const discountPercentage =
      couponDiscount === 100
        ? t('free')
        : t('discount_off', {
            discount: couponDiscount,
          });

    if (couponDuration === 1)
      return t('first_month_discounted', {
        discountPercentage,
      });

    return t('first_months_discounted', {
      couponDuration,
      discountPercentage,
    });
  }, [couponDiscount, couponDuration, successCoupon, t]);

  useEffect(() => {
    if (promoCode === '') {
      paymentStore.resetCouponError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCode]);

  useEffect(() => {
    if (successCoupon) {
      setPromoCodeVisible(false);
    }
  }, [successCoupon]);

  useEffect(
    () => {
      setIsLoading(true);
      setTotalPrice('');

      cancelController?.abort?.();

      const newCancelController = new AbortController();

      setCancelController(newCancelController);

      if(false) {
        // Auto-apply 12-month 50% off coupon
        setPromoCode(onboardingCoupon);
        void paymentStore.validateCoupon(onboardingCoupon);
      }
      
      void Promise.allSettled([
        usersStore
          .calculateUserSeatCost({
            signal: newCancelController.signal,
            currency: profileStore.profile.stripeCurrency,
            period: 'monthly',
            idle: 1,
          })
          .then((data: CalculateCostReturnData) => {
            let monthlyCost = parseFloat(data.cost_per_seat);
            if(paymentStore.payment.couponDiscount < 100 && paymentStore.payment.couponDiscount > 0) {
              monthlyCost -= monthlyCost * paymentStore.payment.couponDiscount / 100;
            }
            setMonthlyPrice(monthlyCost);
            if (isMonthly) {
              setTotalPrice(data.total_cost);
            }

            return data;
          }),
        usersStore
          .calculateUserSeatCost({
            signal: newCancelController.signal,
            currency: profileStore.profile.stripeCurrency,
            period: 'yearly',
            idle: 1,
          })
          .then((data: CalculateCostReturnData) => {
            let yearlyCost = parseFloat(data.cost_per_seat)
            if(paymentStore.payment.couponDiscount < 100 && paymentStore.payment.couponDiscount > 0) {
              yearlyCost -= yearlyCost * paymentStore.payment.couponDiscount / 100;
            }
            setYearlyPrice(yearlyCost / 12);
            if (isAnnually) {
              setTotalPrice(data.total_cost);
            }

            return data;
          }),
      ]).then((results) => {
        if (results.every((result) => result.status === 'fulfilled')) {
          const [monthlyResult, annualResult] = results as [
            PromiseFulfilledResult<CalculateCostReturnData>,
            PromiseFulfilledResult<CalculateCostReturnData>,
          ];

          const newUserSeatCost =
            billingType === 'year' ? annualResult.value : monthlyResult.value;

          usersStore.setNewUserSeatCost(newUserSeatCost);
          usersStore.assignUserSeatCostCurrency(newUserSeatCost.currency);

          setIsLoading(false);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [billingType],
  );

  const onContinue = () => {
    void profileStore.updateLastOnboardingPage(3);
  };

  const onPromoCodeApply: FormEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    if (promoCode) {
      void paymentStore.validateCoupon(promoCode);
    }
  };

  if (!Wrapper) {
    return <div />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro',
          },
        ],
      }}
    >
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <Wrapper data-testid="onboarding-billing">
            {!isLargerThanBreakpoint && isOnBoarding && (
              <Flex w="100%" justifyContent="center">
                <Image w="213px" src={BrandingLogoWithText} />
              </Flex>
            )}
            <Flex direction="column" width="100%" alignItems="center">
              {!isOnUpgradePage && isOnBoarding && (
                <Flex
                  alignItems="center"
                  flexDirection="column"
                  w={{ base: '100%', md: '550px' }}
                >
                  <StepIndicator />
                </Flex>
              )}

              <Flex
                alignItems="stretch"
                flexDirection="column"
                gridRowGap={2}
                mt={isOnBoarding ? 4 : 0}
                mb={0}
                w={{ base: '100%', md: '630px' }}
              >
                {isOnBoarding && (
                  <>
                    <Text
                      fontSize={{ base: 'xl', [defaultBreakpoint]: '3xl' }}
                      variant="semibold"
                      align="left"
                    >
                      {t('which_plan_suits_you_best_question')}
                    </Text>
                    <Text fontSize="sm" lineHeight="normal" align="left" mb={1}>
                      {t('2_weeks_trial_info')}
                    </Text>
                  </>
                )}
                <BillingInformationDiscountBanner />
                <Grid templateColumns="1fr 1fr" columnGap={4}>
                  <BillingInformationBox
                    isDisabled={isLoading}
                    changeStatus={() => setBillingType('month')}
                    currency={profileStore.profile.stripeCurrency ?? 'GBP'}
                    isActive={isMonthly}
                    price={monthlyPrice}
                    topLabel={<span>{t('cancel_anytime')}</span>}
                    bottomLabel={t('paid_monthly')}
                  />

                  <BillingInformationBox
                    isDisabled={isLoading}
                    changeStatus={() => setBillingType('year')}
                    currency={profileStore.profile.stripeCurrency ?? 'GBP'}
                    isActive={isAnnually}
                    price={yearlyPrice}
                    topLabel={<Text as="strong">{t('save')} 20%</Text>}
                    bottomLabel={t('paid_annually')}
                  />
                </Grid>
                {!isOnBoarding && (
                  <Flex>
                    <Box mt={4} lineHeight={1}>
                      <Text fontSize="xl" variant="semibold">
                        {t('team_size')}
                      </Text>
                      <Flex alignItems="center">
                        <Text>
                          {`${t('you_have')} ${userSeats.length} `}
                          {userSeats.length > 1 ? 'seats' : 'seat'}{' '}
                          {`(${activeSeats} active)`}
                        </Text>
                        <Button
                          ml={2}
                          variant="text"
                          textTransform="uppercase"
                          textAlign="center"
                          onClick={() => {
                            onClose?.();
                            addUsersModalStore.open();
                          }}
                        >
                          <Text
                            variant="regular"
                            color="orange.400"
                            fontSize="md"
                            _focus={{
                              outline: 'none',
                            }}
                          >
                            {t('add_more_seats')}
                          </Text>
                        </Button>
                      </Flex>
                    </Box>
                  </Flex>
                )}
                <Flex direction="column" pb={3}>
                  <Text fontSize="xl" variant="semibold">
                    {t('total_payment_due')}:{' '}
                    {isLoading && <Spinner size="xs" />}
                    {!isLoading && !successCoupon && totalPriceFormatted}
                    {!isLoading && successCoupon && (
                      <>
                        <Box as="s">{totalPriceFormatted}</Box>{' '}
                        <Box as="span" color="green.400">
                          {discountedTotalPriceFormatted}
                        </Box>
                      </>
                    )}
                  </Text>
                  <Text fontSize="sm" lineHeight="normal" align="left">
                    {t('not_billed_14_day_trial_message')}
                  </Text>
                </Flex>
                <Flex direction="column">
                  <Text
                    fontSize="md"
                    variant="semibold"
                    color="gray.500"
                    align="left"
                  >
                    {t('enter_your_card_details')}
                  </Text>
                  <Grid
                    alignContent="center"
                    alignSelf="stretch"
                    border={isOnBoarding ? '1px solid' : ''}
                    borderColor={cardFocused ? 'orange.400' : 'gray.450'}
                    h="40px"
                    templateColumns="100%"
                    templateRows="max-content"
                    px={4}
                  >
                    <CardElement
                      onReady={() => paymentStore.completeForm(false)}
                      onFocus={() => setCardFocused(true)}
                      onBlur={() => setCardFocused(false)}
                      onChange={(element) =>
                        paymentStore.completeForm(element.complete)
                      }
                      options={{
                        classes: {
                          base: 'StripeElementOnboarding',
                          focus: 'StripeElementOnboarding--focus',
                          invalid: 'StripeElementOnboarding--invalid',
                          webkitAutofill: 'StripeElementOnboarding--webkit-autofill', 
                        },
                        style: {
                          base: {
                            color: '#1A202C', 
                            fontFamily: 'Source Sans Pro',
                            fontSize: '16px',
                            fontWeight: '300',
                            '::placeholder': {
                              color: '#C8C8C8',
                              fontWeight: '300',
                            },
                          },
                        },
                      }}
                    />
                  </Grid>
                </Flex>
                <Flex gridColumnGap={2} alignItems="center">
                  {successCoupon && (
                    <>
                      <Text fontSize="sm" variant="bold">
                        {t('promo_code_applied')}
                      </Text>
                      <Text fontSize="sm" variant="semibold" color="green.500">
                        {discountLabel}
                      </Text>

                    </>
                  )}
                  {!successCoupon && (
                    <>
                      <Text fontSize="sm" variant="bold">
                        {t('have_a_promo_code_question')}
                      </Text>
                      <Button
                        onClick={() =>
                          setPromoCodeVisible(
                            (previousPromoCodeVisible) =>
                              !previousPromoCodeVisible,
                          )
                        }
                        textDecoration="none"
                        type="button"
                        variant="link"
                        _hover={{
                          textDecoration: 'none',
                        }}
                      >
                        <Text
                          fontSize="sm"
                          variant="semibold"
                          color="orange.500"
                          textTransform="uppercase"
                        >
                          {t('enter_promo_code')}
                        </Text>
                      </Button>
                    </>
                  )}
                </Flex>
                <Flex>
                {successCoupon && (
                                        <Button
                                        onClick={() =>
                                          setPromoCodeVisible(
                                            (previousPromoCodeVisible) =>
                                              !previousPromoCodeVisible,
                                          )
                                        }
                                        textDecoration="none"
                                        type="button"
                                        variant="link"
                                        _hover={{
                                          textDecoration: 'none',
                                        }}
                                      >
                                        <Text
                                          fontSize="sm"
                                          variant="semibold"
                                          color="orange.500"
                                          textTransform="uppercase"
                                        >
                                          {t('enter_promo_code')}
                                        </Text>
                                      </Button>
                )}
                </Flex>
                {isPromoCodeVisible && (
                  <Grid
                    as="form"
                    onSubmit={onPromoCodeApply}
                    alignItems="stretch"
                    columnGap={4}
                    templateColumns="1fr auto"
                  >
                    <Input
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...inputStyles}
                      textAlign="center"
                      onChange={(event) => {
                        setPromoCode(event.target.value);
                      }}
                      value={promoCode}
                      borderColor="gray.450"
                      type="text"
                      height="40px"
                      id="promoCode"
                      isInvalid={couponError}
                      name="promoCode"
                      placeholder={t('placeholder_promo_code') ?? ''}
                      textTransform="uppercase"
                      _placeholder={{
                        // eslint-disable-next-line no-underscore-dangle
                        ...inputStyles._placeholder,
                        textTransform: 'none',
                        textAlign: 'center',
                      }}
                    />
                    <Button
                      disabled={!promoCode}
                      type="submit"
                      variant="submitDark"
                      h="auto"
                      py={0}
                    >
                      <Text
                        variant="semibold"
                        fontSize="sm"
                        pointerEvents="none"
                        textTransform="uppercase"
                      >
                        {t('apply')}
                      </Text>
                    </Button>
                  </Grid>
                )}
                <Flex direction="column" gridRowGap={1} alignItems="center">
                  <Text color="red.400" variant="semibold" align="center">
                    {couponError &&
                      (couponErrorMessage || t('invalid_promo_code_entered'))}
                  </Text>
                  <Text color="red.400" variant="semibold" align="center">
                    {authError && authErrorMessage}
                  </Text>
                </Flex>
                <Grid
                  flexDirection="column"
                  justifyContent="center"
                  rowGap={2}
                  templateColumns="min-content"
                  templateRows="2rem 2rem"
                >
                  <Button
                    disabled={
                      !billingType ||
                      isPaymentProcessing ||
                      !cardComplete ||
                      couponError
                    }
                    py={0}
                    h="auto"
                    isLoading={isPaymentProcessing || isLoading}
                    type="button"
                    variant="submitDark"
                    onClick={(event) => {
                      event.preventDefault();

                      if (
                        !billingType ||
                        isPaymentProcessing ||
                        !cardComplete
                      ) {
                        return;
                      }

                      setPaymentProcessing(true);

                      upgrade({ elements, stripe, billingType })
                        .then(() => {
                          if (isOnBoarding) {
                            navigate(
                              isOnUpgradePage
                                ? routePaths.upgradeSuccess
                                : routePaths.transactionCompleted,
                              { replace: true },
                            );

                            setPaymentProcessing(false);
                          } else {
                            showSuccessToast(t('success_transaction_complete'));
                            window.location.reload();
                          }
                        })
                        .catch(
                          (error?: {
                            data?: { error: string };
                            message?: string;
                          }) => {
                            paymentStore.failedAuthentication(
                              error?.data?.error ?? t('failure_payment'),
                            );

                            setPaymentProcessing(false);
                          },
                        );
                    }}
                  >
                    {isOnBoarding && (
                      <Text
                        variant="semibold"
                        fontSize="sm"
                        pointerEvents="none"
                        textTransform="uppercase"
                      >
                        {isOnUpgradePage
                          ? t('upgrade_now')
                          : t('complete_account_setup')}
                      </Text>
                    )}
                    {!isOnBoarding && (
                      <Text
                        variant="semibold"
                        fontSize="sm"
                        pointerEvents="none"
                        textTransform="uppercase"
                      >
                        {t('pay_with_credit_or_debit_card')}
                      </Text>
                    )}
                  </Button>
                  <Button
                    disabled={isPaymentProcessing}
                    py={0}
                    isLoading={isPaymentProcessing || isLoading}
                    type="button"
                    variant="ghost"
                    borderRadius={0}
                    _focus={{
                      border: 'none',
                    }}
                    onClick={() => {
                      if (!isOnBoarding) {
                        onClose?.();
                      } else if (isOnUpgradePage) {
                        window.location.assign(routePaths.myEvents);
                      } else {
                        onContinue();
                      }
                    }}
                  >
                    <Text
                      variant="semibold"
                      fontSize="sm"
                      pointerEvents="none"
                      textTransform="uppercase"
                    >
                      {isOnBoarding ? t('skip_for_now') : t('cancel')}
                    </Text>
                  </Button>
                </Grid>
              </Flex>
            </Flex>
          </Wrapper>
        )}
      </ElementsConsumer>
    </Elements>
  );
}

OnboardingBilling.defaultProps = defaultProps;

export default observer(OnboardingBilling);
