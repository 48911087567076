import paymentStore from '@/stores/PaymentStore';
import subscriptionStore, {
  defaultSubscriptionInterval,
} from '@/stores/SubscriptionStore';
import usersStore from '@/stores/UsersStore/UsersStore';
import { authorizedApi } from '@/utils/apiUtils';

import i18n from '../i18n';

export const serverPayment = async ({ cardToken, onError }) => {
  try {
    const { subscription } = subscriptionStore;
    const { payment } = paymentStore;
    const { userSeatCost, userSeatsCounters } = usersStore;

    const payload = {
      paymentMethodId: cardToken?.paymentMethod?.id || cardToken?.id,
      billingPeriod: `${
        subscription?.interval ?? defaultSubscriptionInterval
      }ly`,
      currency: userSeatCost?.currency.toLowerCase(),
      couponName: payment?.couponInput,
      costDetails: `additional ${
        userSeatCost?.seatCount
      } seats, total monthly cost of ${
        userSeatCost?.totalCost
      } ${userSeatCost?.currency.toLowerCase()}`,
      totalSeatCount:
        (userSeatCost?.seatCount || 0) + (userSeatsCounters?.total || 0),
      ...(import.meta.env.DEV ? { stripeTest: true } : {}),
    };

    await authorizedApi.post(`/business/payments`, payload);

    return { success: true };
  } catch (error) {
    onError(`serverPayment error: ${error?.message}`);
    const { cancelProcessing, failedAuthentication } = paymentStore;
    cancelProcessing();
    failedAuthentication(
      error?.data?.error ||
        error?.data?.stripeTest?.[0] ||
        i18n.t('something_went_wrong'),
    );

    return {
      success: false,
      error: {
        action: 'await authorizedApi payment',
        message:
          error?.data?.error ||
          error?.data?.stripeTest?.[0] ||
          i18n.t('something_went_wrong'),
      },
    };
  }
};
