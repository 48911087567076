const routePaths = {
  root: '/',

  // onboarding flow
  billing: '/onboard/billing',
  calendars: '/onboard/calendars',
  conferencing: '/onboard/conferencing',
  transactionCompleted: '/onboard/transaction-completed',
  onboard: '/onboard',

  // dashboard routes
  connectMonday: '/connect/monday',
  integrations: '/integrations',
  links: '/links',
  manageSubscription: '/manage-subscription',
  manageUsers: '/manage-users',
  myAvailability: '/my-availability',
  myBookings: '/my-bookings',
  myEvents: '/my-events',
  myEventsAdd: '/my-events/add',
  myEventsEdit: '/my-events/:id',
  settings: '/settings',
  upgrade: '/upgrade',
  upgradeSuccess: '/upgrade/success',

  // verification flow
  verificationSuccess: '/verification/success',
  verificationDoubled: '/verification/doubled',
  verificationResend: '/verification/resend/:id',

  // redirections
  affiliate: '/affiliate/:affiliate',
  businessInvite: '/business-invite',

  // authentication flow
  addPassword: '/add-password',
  authenticate: '/onboard/authenticate',
  forgotPassword: '/forgot-password',
  forgotPasswordConfirmation: '/forgot-password-confirmation',
  resetPassword: '/reset-password',
  login: '/login',
  loginEmail: '/login/email',
  signUp: '/sign-up',
  signUpProfile: '/sign-up-profile',
} as const;

export default routePaths;
