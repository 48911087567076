import { array, object, string } from 'yup';

import profileStore from '@/stores/ProfileStore/ProfileStore';
import i18n from '@/utils/i18n';

const schema = object().shape({
  users: array(
    object().shape({
      email: string().email(),
    }),
  )
    .test(
      'has-at-least-one-user-email',
      i18n.t('validation_1_email_min'),
      (users) => users?.filter(({ email }) => email)?.length > 0,
    )
    .test({
      name: 'Unique-value',
      test: (users, { createError }) => {
        const emails = users
          ?.filter(({ email }) => email)
          .map(({ email }) => email);

        if (new Set(emails).size === emails?.length) {
          return true;
        }

        return createError({
          path: 'email',
          message: i18n.t('validation_duplicate_emails'),
        });
      },
    })
    .test({
      name: 'host-email',
      test: (users, { createError }) => {
        const emails = users
          ?.filter(({ email }) => email)
          .map(({ email }) => email);

        if (emails.includes(profileStore.profile.homeEmail)) {
          return createError({
            message: i18n.t('validation_host_email_present'),
          });
        }

        return true;
      },
    }),
});

export default schema;
