import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useSubscriptionStore } from '@/stores/RootStore';

import routePaths from './routePaths';

function AvailableForUpgradeRoute({ children }: PropsWithChildren) {
  const subscriptionStore = useSubscriptionStore();

  if (subscriptionStore.subscription.id === 'no-subscription-id') {
    return <>{children}</>;
  }

  return <Navigate to={routePaths.root} replace />;
}

export default observer(AvailableForUpgradeRoute);
