import { Box, Progress } from '@chakra-ui/react';

import useBreakpoint from '@/hooks/useBreakpoint';

export default function ProgressFallback() {
  const isLargerThanBreakpoint = useBreakpoint();

  return (
    <Box
      pl={isLargerThanBreakpoint ? '11.5rem' : 6}
      pr={isLargerThanBreakpoint ? '12.5rem' : 6}
      mt={isLargerThanBreakpoint ? '100px' : 8}
    >
      <Progress isIndeterminate size="sm" colorScheme="orange" />
    </Box>
  );
}
