import { Flex, useToken } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const bubbleVariants = {
  visible: {
    opacity: 1,
    y: 0,
    ease: 'backOut',
    transition: {
      duration: 0.2,
      bounce: 0.5,
      damping: 8,
      type: 'spring',
    },
  },
  hidden: { opacity: 0, y: 70 },
  exit: { opacity: 0 },
  hover: { scale: 1.05, transition: { ease: 'backOut' } },
};

export default function WelcomeBackBubble() {
  const { t } = useTranslation();

  const [gray450] = useToken('colors', ['gray.450']) as [string];

  const queryParams = new URLSearchParams(window.location.search);

  return (
    <Flex
      as={motion.div}
      bottom="380px"
      color="gray.500"
      h="134px"
      position="fixed"
      right="220px"
      transformOrigin="bottom right"
      zIndex={3}
      variants={bubbleVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      whileHover="hover"
    >
      <Flex
        alignItems="center"
        bg="white"
        borderColor="gray.450"
        borderRadius="1rem"
        borderStyle="solid"
        borderWidth={1}
        color="gray.500"
        fontFamily="Poppins"
        fontSize="xl"
        fontWeight="semibold"
        h="60px"
        justifyContent="center"
        lineHeight={1.2}
        pointerEvents="none"
        px="24px"
      >
        {queryParams.get('sso') === 'link'
          ? t('lets_join_forces')
          : t('welcome_back')}
      </Flex>
      <Flex
        as="svg"
        position="absolute"
        h="88px"
        w="25px"
        viewBox="0 0 25 88"
        right="-1px"
        bottom={0}
        zIndex={4}
      >
        <path
          fill="white"
          stroke={gray450}
          strokeWidth="1"
          d="M 0 14 L 16 66 C 16 66 20 76 24 66 L 24 0"
        />
      </Flex>
    </Flex>
  );
}

WelcomeBackBubble.displayName = 'WelcomeBackBubble';
