import { useCallback, useEffect } from 'react';

import { useProfileStore } from '@/stores/RootStore';

const useAppcuesTracking = () => {
  const profileStore = useProfileStore();

  useEffect(() => {
    if (document.querySelector('#appcues-script')) {
      return () => {};
    }

    const script = document.createElement('script');

    script.src = import.meta.env.VITE_APPCUES_SCRIPT_SRC_URL;
    script.setAttribute('id', 'appcues-script');
    script.async = true;

    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  const identify = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const date = new Date(profileStore.profile?.firstLogin)?.getTime();

        if (!window.Appcues || Number.isNaN(date)) {
          reject();
        }

        window.Appcues.identify(profileStore.profile.id, {
          createdAt: date,
          accountId: profileStore.profile.id,
          firstName: profileStore.profile.firstName,
          email: profileStore.profile.homeEmail,
        });

        resolve();
      }),
    [
      profileStore.profile.firstLogin,
      profileStore.profile.firstName,
      profileStore.profile.homeEmail,
      profileStore.profile.id,
    ],
  );

  const trackEvent = useCallback(
    (eventTrack) => {
      identify()
        .then(() => {
          window.Appcues.track(eventTrack);
        })
        .catch(() => {});
    },
    [identify],
  );

  const trackPage = useCallback(() => {
    identify()
      .then(() => {
        window.Appcues.page();
      })
      .catch(() => {});
  }, [identify]);

  return { identify, trackEvent, trackPage };
};

export default useAppcuesTracking;
