

// eslint-disable-next-line import/prefer-default-export
export const inputStyles = {
  borderColor: 'gray.200',
  borderRadius: 0,
  boxShadow: 'none',
  h: '3.125rem',
  textAlign: 'center',
  type: 'text',
  _focus: {
    boxShadow: 'none',
    borderColor: 'orange.400',
    color: 'gray.500',
  },
  _hover: {
    borderColor: 'gray.300',
  },
  _placeholder: {
    color: 'gray.300',
  },
};
export const fakeInputStyles = {
  errorBorderColor: 'white'
}