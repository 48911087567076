import {
  Button,
  Grid,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import errorImg from '@/assets/error-occured.png';
import routePaths from '@/config/routePaths';
import DesktopUltraWideLayout from '@/ui/layout/DesktopUltraWideLayout';
import { DesktopWideLayout } from '@/ui/layout/DesktopWideLayout';
import { MobileLayout } from '@/ui/layout/MobileLayout';

export default function ErrorOccurred(): JSX.Element {
  const { t } = useTranslation();

  const Wrapper = useBreakpointValue({
    base: MobileLayout,
    md: DesktopWideLayout,
    xl: DesktopUltraWideLayout,
  }, "xl");

  if (!Wrapper) {
    return <div />;
  }

  const onGoToHomePage = () => {
    window.location.assign(routePaths.root);
  };

  return (
    <Wrapper data-testid="error-occured">
      <Grid w="100%" justifyItems="center" rowGap={8}>
        <Image w="45%" minW="300px" src={errorImg} />
        <Text align="center" fontWeight="600" fontSize="3xl">
          {t('sorry_something_gone_wrong')}
        </Text>
        <Text fontWeight="600" fontSize="xl" align="center">
          <div>{t('our_team_has_been_notified')}</div>
          <div>{t('we_got_all_hands_on_deck')}</div>
        </Text>
        <Text align="center">
          <div>{t('button_below_goto_home_page')}</div>
        </Text>
        <Button variant="submitDark" type="button" onClick={onGoToHomePage}>
          <Text fontSize="sm" variant="semibold">
            {t('back_to_homepage')}
          </Text>
        </Button>
      </Grid>
    </Wrapper>
  );
}
