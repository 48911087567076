/* eslint-disable react/jsx-props-no-spreading, no-unused-vars */

import { Input } from '@chakra-ui/react';
import { bool, func, number, string } from 'prop-types';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useFormContext } from 'react-hook-form-6';

const UserRowInput = forwardRef(
  (
    {
      autoFocus,
      name,
      onDownKeyPress,
      onUpKeyPress,
      onEnterKeyPress,
      onPaste,
      placeholder,
      defaultValue,
      tabIndex
    },
    ref,
  ) => {
    const inputRef = useRef();

    const { register } = useFormContext();

    const keyActionsMap = useMemo(
      () => ({
        Enter: onEnterKeyPress,
        ArrowUp: onUpKeyPress,
        ArrowDown: onDownKeyPress,
      }),
      [onEnterKeyPress, onUpKeyPress, onDownKeyPress],
    );

    const onKeyDown = useCallback(
      (event) => {
        const eventAction = keyActionsMap[event.key];

        if (eventAction) {
          event.preventDefault();
          event.stopPropagation();
          eventAction();
        }
      },
      [keyActionsMap],
    );

    useImperativeHandle(ref, () => ({
      focus() {
        inputRef.current.focus();
      },
    }));

    return (
      <Input
        autoComplete="chrome-off"
        id={name}
        autoFocus={autoFocus}
        border={0}
        defaultValue={defaultValue}
        m={0}
        name={name}
        outline="none"
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        pl={0}
        placeholder={placeholder}
        ref={(element) => {
          register()(element);
          inputRef.current = element;
        }}
        tabIndex={tabIndex}
        type="text"
        _focus={{
          outline: 'none',
        }}
      />
    );
  },
);

UserRowInput.displayName = 'UserRowInput';

UserRowInput.propTypes = {
  autoFocus: bool.isRequired,
  name: string.isRequired,
  onDownKeyPress: func.isRequired,
  onUpKeyPress: func.isRequired,
  onEnterKeyPress: func.isRequired,
  onPaste: func.isRequired,
  placeholder: string.isRequired,
  defaultValue: string.isRequired,
  tabIndex: number.isRequired,
};

export default UserRowInput;
