import paymentStore from '@/stores/PaymentStore';
import { serverPayment } from '@/utils/stripe/serverPayment';

export const serverToken = async ({ cardToken, onError }) => {
  try {
    const serverPaymentResponse = await serverPayment({ cardToken, onError });

    return { ...serverPaymentResponse };
  } catch (error) {
    onError(`serverPayment token error: ${error?.message}`);
    paymentStore.cancelProcessing();

    return {
      success: false,
      error: { action: 'await serverPayment', message: error },
    };
  }
};
