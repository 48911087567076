import { Button, Center, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Panel from '@/components/atoms/Panel';
import routePaths from '@/config/routePaths';
import useBreakpoint from '@/hooks/useBreakpoint';

import PageLayout from '../templates/PageLayout';

export default function InactiveSubscription() {
  const isLargerThanBreakpoint = useBreakpoint();
  const { t } = useTranslation();

  return (
    <PageLayout>
      <Panel>
        <Center>
          <Text variant="semibold" font="xl">
            {t('account_activation_reminder')}
          </Text>
        </Center>
        <Center pt={isLargerThanBreakpoint ? 12 : 6}>
          <Button
            flexGrow={isLargerThanBreakpoint ? undefined : 1}
            onClick={() => {
              window.location.assign(routePaths.upgrade);
            }}
            variant="submit"
            minW={isLargerThanBreakpoint ? '18rem' : 0}
          >
            {t('subscribe_now')}
          </Button>
        </Center>
      </Panel>
    </PageLayout>
  );
}
