import { Flex, Grid, Spinner, Text } from '@chakra-ui/react';
import { ReactElement, useMemo } from 'react';

interface BillingInformationBoxProperties {
  bottomLabel: string;
  changeStatus: () => void;
  currency?: string;
  isActive: boolean;
  isDisabled: boolean;
  price: number;
  topLabel: ReactElement | string;
}

export default function BillingInformationBox({
  bottomLabel,
  changeStatus,
  currency,
  isActive,
  isDisabled,
  price,
  topLabel,
}: BillingInformationBoxProperties) {
  const currencyFormatter = new Intl.NumberFormat(
    window.navigator?.language ?? 'en-GB',
    {
      style: 'currency',
      currency: currency || 'GBP',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
  );

  const monthNoun = useMemo(
    () =>
      new Intl.NumberFormat(window.navigator.language ?? 'en-GB', {
        style: 'unit',
        unitDisplay: 'long',
        unit: 'month',
      })
        .formatToParts(1)
        .find(({ type }) => type === 'unit')?.value,
    [],
  );

  const backgroundColor = isActive ? 'orange.500' : undefined;
  const textColor = isActive ? 'white' : 'orange.500';

  const transition = 'all 0.2s ease';

  return (
    <Grid
      data-testid="billing-information-box"
      cursor="pointer"
      onClick={() => {
        if (!isDisabled) {
          changeStatus();
        }
      }}
      alignContent="center"
      alignItems="center"
      borderColor="orange.500"
      borderWidth={1}
      backgroundColor={backgroundColor}
      transition={transition}
      justifyItems="center"
      templateRows="1rem 1fr 1rem"
      py={6}
      h="11rem"
      w="100%"
    >
      <Text color={textColor} transition={transition}>
        {topLabel}
      </Text>
      {price ? (
        <Flex
          justifyContent="center"
          alignItems="baseline"
          py={2}
          wrap="nowrap"
        >
          <Text
            lineHeight={1}
            fontWeight={700}
            color={textColor}
            fontSize={{ base: '3xl', sm: '6xl' }}
            transition={transition}
          >
            {currencyFormatter.format(price)}/
          </Text>
          <Text
            lineHeight={1}
            fontWeight={700}
            color={textColor}
            fontSize={{ base: 'md', sm: '3xl' }}
            transition={transition}
          >
            {monthNoun}
          </Text>
        </Flex>
      ) : (
        <Spinner
          data-testid="billing-information-box-spinner"
          color={textColor}
          size="lg"
          speed="0.65s"
          alignSelf="center"
          thickness="4px"
          my={3}
        />
      )}
      <Text
        color={textColor}
        fontWeight={700}
        fontSize="xl"
        textAlign="center"
        transition={transition}
        textTransform="uppercase"
      >
        {bottomLabel}
      </Text>
    </Grid>
  );
}

BillingInformationBox.defaultProps = {
  currency: undefined,
};
