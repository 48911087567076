import { captureException } from '@sentry/react';
import { DateTime } from 'luxon';
import { observable } from 'mobx';
import { types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';

import { eventLocationTypeToRawLocationType } from '@/stores/EventStore/eventLocationTypeToRawLocationType';
import { authorizedApi, fetchStatus, leadUser } from '@/utils/apiUtils';
import i18n from '@/utils/i18n';

import profileStore from './ProfileStore/ProfileStore';
import { autoFlow } from './storeUtils';

export const TimeEntry = types.model('TimeEntry', {
  from: types.integer,
  to: types.integer,
  id: types.identifier,
  catchupId: types.integer,
  invitedUsers: types.array(
    types.model({
      id: types.identifierNumber,
      target: types.maybeNull(types.string),
    }),
  ),
  eventName: types.string,
  fundamentalType: types.string,
  flexible: types.boolean,
  customLocationName: types.string,
  googlePlacesId: types.string,
  timeBlocks: types.maybeNull(types.array(types.array(types.string))),
});

function mapEntriesToUnixTimestamps({ entries }) {
  return entries.map(({ from, to }) => [
    DateTime.fromMillis(from).toSeconds(),
    DateTime.fromMillis(to).toSeconds(),
  ]);
}

function convertEntriesTimezone({ entries, date, displayedTimezone }) {
  return entries.map(({ from, to, ...otherValues }) => {
    const [fromHour, fromMinute] = from.split(':');
    const [toHour, toMinute] = to.split(':');

    return {
      ...otherValues,
      from: date
        .setZone(displayedTimezone, {
          keepLocalTime: true,
        })
        .set({
          hour: Number.parseInt(fromHour, 10),
          minute: Number.parseInt(fromMinute, 10),
        })
        .toMillis(),
      to: date
        .setZone(displayedTimezone, {
          keepLocalTime: true,
        })
        .set({
          hour: Number.parseInt(toHour, 10),
          minute: Number.parseInt(toMinute, 10),
        })
        .toMillis(),
    };
  });
}

export const AvailabilityStore = types
  .model('AvailabilityStore', {
    entries: types.array(TimeEntry),
    fetchStatus: types.enumeration(Object.values(fetchStatus)),
    displayedTimezone: types.maybeNull(types.string),
  })
  .views((self) => ({
    entriesForDate(timestamp) {
      return self.entriesForDateWithTimezoneOffset(timestamp, 0);
    },

    entriesForDateWithTimezoneOffset(timestamp, timezoneOffsetInMilliseconds) {
      const date = DateTime.fromMillis(timestamp);

      if (!date.isValid) {
        return observable([]);
      }

      return self.entries.filter(({ from }) => {
        const fromDate = DateTime.fromMillis(from).plus({
          milliseconds: timezoneOffsetInMilliseconds,
        });

        return (
          fromDate.hasSame(date, 'year') &&
          fromDate.hasSame(date, 'month') &&
          fromDate.hasSame(date, 'day')
        );
      });
    },

    get hasFetchedAtLeastOnce() {
      return self.fetchStatus !== fetchStatus.noStatus;
    },

    get isFetching() {
      return self.fetchStatus === fetchStatus.pending;
    },

    get isDoneFetching() {
      return self.fetchStatus === fetchStatus.done;
    },
  }))
  .actions((self) =>
    autoFlow({
      addEntry(entryData) {
        self.entries.push(entryData);
      },

      editEntry({ id, from, to }) {
        const entryIndex = self.entries.findIndex(
          ({ id: entryId }) => String(entryId) === String(id),
        );

        if (entryIndex !== -1) {
          self.entries[entryIndex].from = from ?? self.entries[entryIndex].from;
          self.entries[entryIndex].to = to ?? self.entries[entryIndex].to;
        }
      },

      removeEntry({ id }) {
        self.entries = self.entries.filter(
          ({ id: entryId }) => String(entryId) !== String(id),
        );
      },

      *fetchEntries({ appointmentId, userId: argumentUserId }) {
        const options = {
          headers: { token: authorizedApi.defaults.headers.token },
        };

        const userId = encodeURIComponent(
          argumentUserId ?? authorizedApi.defaults.headers.id,
        );

        self.fetchStatus = fetchStatus.pending;

        // In case we ask for someone else events
        if (userId !== authorizedApi.defaults.headers.id) {
          try {
            const response = yield authorizedApi.get(
              `/appointments/users/${userId}/requestToken`,
            );
            if (!response.data?.success) {
              throw new Error(i18n.t('user_not_found'));
            }

            options.headers.token = response.data.token;
          } catch (error) {
            if (!error?.response) {
              captureException(error);
            }
          }
        }

        const requestUrlSuffix = appointmentId ? `/${appointmentId}` : '';

        try {
          const response = yield authorizedApi.get(
            `/users/${userId}/catchups/outgoing${requestUrlSuffix}`,
            { ...options },
          );

          if (self.entries.length > 0) {
            self.entries = [];
          }

          const mappedEntries =
            response.data?.success?.flatMap(
              ({
                catchup_id: catchupId,
                invited: invitedUsers,
                catchup_name: eventName,
                type: fundamentalType,
                flexible,
                catchup_location_name: customLocationName,
                google_places_id: googlePlacesId,
                time_blocks: timeBlocks,
                events,
              }) => {
                const [firstEvent] = events ?? [];

                const referenceEventDate = firstEvent?.from
                  ? DateTime.fromSeconds(firstEvent.from)
                  : DateTime.now();

                return timeBlocks.map(([from, to]) => {
                  const [fromHourRaw, fromMinuteRaw] = from.split(':');
                  const [toHourRaw, toMinuteRaw] = to.split(':');

                  return {
                    id: nanoid(),
                    catchupId,
                    invitedUsers: invitedUsers.map(({ id, mobile }) => ({
                      id,
                      target: mobile,
                    })),
                    eventName,
                    fundamentalType,
                    flexible: Boolean(flexible),
                    customLocationName,
                    googlePlacesId,
                    from: referenceEventDate
                      .setZone(profileStore.profile.tzIdentifier)
                      .set({
                        hour: Number.parseInt(fromHourRaw, 10),
                        minute: Number.parseInt(fromMinuteRaw, 10),
                        second: 0,
                      })
                      .toMillis(),
                    to: referenceEventDate
                      .setZone(profileStore.profile.tzIdentifier)
                      .set({
                        hour: Number.parseInt(toHourRaw, 10),
                        minute: Number.parseInt(toMinuteRaw, 10),
                        second: 0,
                      })
                      .toMillis(),
                  };
                });
              },
            ) ?? [];

          self.entries = mappedEntries;
        } catch (error) {
          self.entries = [];
        } finally {
          self.fetchStatus = fetchStatus.done;
        }
      },

      setDisplayedTimezone({ timezone }) {
        self.displayedTimezone = timezone;
      },

      *deleteCatchup({ removedEntries }) {
        const [{ id: firstEntryId }] = removedEntries;

        const { catchupId } =
          self.entries.find(({ id }) => String(id) === String(firstEntryId)) ??
          {};

        if (!catchupId) {
          throw new Error(i18n.t('failure_slot_remove'));
        }

        yield authorizedApi.delete(`/catchup/delete/${catchupId}`);

        removedEntries.forEach(self.removeEntry);
      },

      *addCatchup({ entries, selectedAppointmentId, selectedAppointment }) {
        const mappedEntries = mapEntriesToUnixTimestamps({
          entries,
        });

        const { location, customLocationName } = selectedAppointment;

        yield authorizedApi.post('/catchup/create', {
          appointmentId: selectedAppointmentId,
          appointment_types_id: selectedAppointmentId,
          customLocationName:
            eventLocationTypeToRawLocationType({
              location: { value: location },
              customLocationName,
            }) ?? '',
          eventName: selectedAppointment.name,
          flexible: false,
          fundamentalType: 'one-on-one',
          googlePlacesId: '',
          googlePlacesName: '',
          invitedUsers: [leadUser],
          location: 0,
          message: selectedAppointment.notes,
          times: mappedEntries,
        });
      },

      *editCatchup({ entries, dateOverride, selectedAppointmentId }) {
        const mappedEntries = mapEntriesToUnixTimestamps({
          entries,
        });

        const { id: firstExistingEntryId } = self.entries.find(({ from }) =>
          dateOverride
            ? DateTime.fromMillis(from).toISODate() === dateOverride
            : DateTime.fromMillis(from).toISODate() ===
              DateTime.fromISO(entries[0].fromFull).toISODate(),
        );

        const {
          catchupId,
          invitedUsers,
          eventName,
          fundamentalType,
          flexible,
          customLocationName,
          googlePlacesId,
        } = self.entries.find(
          ({ id }) => String(firstExistingEntryId) === String(id),
        );

        yield authorizedApi.post(`/catchup/edit/${catchupId}`, {
          times: mappedEntries,
          invitedUsers,
          eventName,
          fundamentalType,
          flexible,
          customLocationName,
          googlePlacesId,
          appointmentId: selectedAppointmentId,
          appointment_types_id: selectedAppointmentId,
        });
      },

      *handleSameDayAvailability({
        entriesWithDefault,
        removedEntriesWithDefault,
        allEntriesNew,
        selectedAppointmentId,
        selectedAppointment,
        selectedDate,
      }) {
        if (
          entriesWithDefault.length === 0 &&
          removedEntriesWithDefault.length > 0
        ) {
          return yield self.deleteCatchup({
            removedEntries: removedEntriesWithDefault,
          });
        }

        const entries = convertEntriesTimezone({
          entries: entriesWithDefault,
          displayedTimezone: self.displayedTimezone,
          date: DateTime.fromISO(selectedDate),
        });

        if (
          entriesWithDefault.length > 0 &&
          allEntriesNew &&
          removedEntriesWithDefault.length === 0
        ) {
          return yield self.addCatchup({
            entries,
            selectedAppointmentId,
            selectedAppointment,
          });
        }

        if (
          entriesWithDefault.length > 0 &&
          allEntriesNew &&
          removedEntriesWithDefault.length > 0
        ) {
          return yield self.editCatchup({
            entries,
            selectedAppointmentId,
          });
        }

        if (entriesWithDefault.length > 0 && !allEntriesNew) {
          return yield self.editCatchup({
            entries,
            selectedAppointmentId,
          });
        }

        return null;
      },

      handleOtherDayAvailability({
        entriesWithDefault,
        newDate,
        selectedAppointmentId,
        selectedAppointment,
      }) {
        const parsedNewDate = DateTime.fromISO(newDate);

        const entriesForDate = self.entriesForDate(parsedNewDate.valueOf());

        const [{ catchupId }] =
          entriesForDate.length > 0 ? entriesForDate : [{}];

        const entries = convertEntriesTimezone({
          entries: entriesWithDefault,
          displayedTimezone: self.displayedTimezone,
          date: parsedNewDate,
        });

        if (catchupId) {
          if (entries.length === 0) {
            self.deleteCatchup({
              removedEntries: entriesForDate,
            });
          } else {
            self.editCatchup({
              entries,
              dateOverride: newDate,
              selectedAppointmentId,
            });
          }
        } else {
          self.addCatchup({
            entries,
            selectedAppointmentId,
            selectedAppointment,
          });
        }
      },

      *bulkSave({
        entries,
        removedEntries,
        selectedDays,
        selectedEditDateString,
        selectedAppointment,
      }) {
        const entriesWithDefault = entries ?? [];
        const removedEntriesWithDefault = removedEntries ?? [];
        const allEntriesNew = entriesWithDefault.every(
          ({ isNew }) => isNew === 'true',
        );

        const selectedAppointmentId = selectedAppointment.id;

        return yield Promise.all(
          [...selectedDays.values()].map((isoDate) => {
            if (isoDate === selectedEditDateString) {
              return self.handleSameDayAvailability({
                entriesWithDefault,
                removedEntriesWithDefault,
                allEntriesNew,
                selectedAppointmentId,
                selectedAppointment,
                selectedDate: selectedEditDateString,
              });
            }

            return self.handleOtherDayAvailability({
              entriesWithDefault,
              removedEntriesWithDefault,
              newDate: isoDate,
              selectedAppointmentId,
              selectedAppointment,
            });
          }),
        );
      },
    }),
  );

const availabilityStore = AvailabilityStore.create({
  entries: [],
  fetchStatus: fetchStatus.noStatus,
});

export default availabilityStore;
