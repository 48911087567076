const INIT_STATE = {
  isLoaded: false,
  userProfile: {},
};

const userProfileReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_USER_PROFILE_NOT_LOADED':
      return {
        ...state,
        isLoaded: false,
      };
    case 'FETCH_USER_PROFILE_SUCCESS':
      return {
        ...state,
        userProfile: action.payload,
        isLoaded: true,
      };
    case 'DISCONNECT_CALENDAR':
      return {
        ...state,
        userProfile: { ...state.userProfile, connectedCalendarProvider: null, connectedCalendarEmail: null },
        isLoaded: true,
      };
    case 'DISCONNECT_ZOOM':
      return {
        ...state,
        userProfile: { ...state.userProfile, zoomConnectionId: null },
        isLoaded: true,
      };
    case 'DISCONNECT_TEAMS':
      return {
        ...state,
        userProfile: { ...state.userProfile, teamsConnectionId: null, teamsConnectionEmail: null },
        isLoaded: true,
      };
    case 'DISCONNECT_MEET':
      return {
        ...state,
        userProfile: { ...state.userProfile, googleMeetConnectionId: null, googleMeetConnectionEmail: null },
        isLoaded: true,
      };
    default:
      return state;
  }
};

export default userProfileReducer;
