import { useToast } from '@chakra-ui/react';
import React from 'react';

import CustomToast from '@/components/molecules/CustomToast';

export function useSuccessToast() {
  const showToast = useToast();

  return (message) => {
    showToast({
      position: 'bottom',
      duration: 3000,
      render: () => <CustomToast message={message} status="success" />,
    });
  };
}

export function useErrorToast() {
  const showToast = useToast();

  return (message) => {
    showToast({
      position: 'bottom',
      duration: 5000,
      render: () => <CustomToast message={message} status="error" />,
    });
  };
}
