import { Flex, Grid, Image, Show, Text } from '@chakra-ui/react';
import {
  type AnimatePresenceProps,
  AnimatePresence as OriginalAnimatePresence,
  motion,
} from 'framer-motion';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import Evan1X from '@/assets/Evan.png';
import Evan2X from '@/assets/Evan@2x.png';
import Evan3X from '@/assets/Evan@3x.png';
import Globe from '@/assets/Globe teal.svg';
import RadialDots1X from '@/assets/radialdots.png';
import RadialDots2X from '@/assets/radialdots@2x.png';
import RadialDots3X from '@/assets/radialdots@3x.png';
import LanguageSwitcher from '@/components/AuthFlow/LanguageSwitcher';
import { defaultBreakpoint } from '@/hooks/useBreakpoint';

const AnimatePresence: FC<PropsWithChildren<AnimatePresenceProps>> =
  OriginalAnimatePresence;

interface AuthLayoutProperties {
  RightAdornment?: FC;
  animationLayout?: 'signup' | 'reset';
}

const globeBubbleData = [
  {
    data: '55%',
    translationLabel: 'increase_in_sales',
    alignSelf: 'flex-start',
    justifySelf: 'flex-start',
  },
  {
    data: '77%',
    translationLabel: 'percentage_of_people_productivity_increase',
    alignSelf: 'flex-start',
    justifySelf: 'flex-end',
  },
  {
    data: '10k +',
    translationLabel: 'deals_closed_using_catchapp',
    alignSelf: 'flex-end',
    justifySelf: 'flex-start',
  },
  {
    data: '1,150',
    translationLabel: 'five_star_review_count',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
];

export default function AuthLayout({
  children,
  animationLayout = 'signup',
  RightAdornment,
}: PropsWithChildren<AuthLayoutProperties>) {
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(window.location.search);

  return (
    <Flex
      alignItems="center"
      direction="column"
      h="100%"
      minH="100%"
      overflowY="auto"
      position="relative"
      py={{ base: 0, [defaultBreakpoint]: 6 }}
      rowGap={2}
      w="100%"
    >
      <AnimatePresence exitBeforeEnter>
        {animationLayout === 'signup' && (
          <Flex
            as={motion.div}
            h="398px"
            isolation="isolate"
            key="signup"
            left="calc((50% - 254px) / 2)"
            pointerEvents={{ base: 'none', [defaultBreakpoint]: 'auto' }}
            transform="translateX(-50%)"
            position="fixed"
            top="40px"
            w="min(480px, 33.33vw)"
            zIndex={2}
          >
            <Show above={defaultBreakpoint}>
              <Image
                as={motion.img}
                initial={{
                  opacity: 0,
                  y: 10,
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    ease: 'easeOut',
                    duration: 0.2,
                  },
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.4,
                    bounce: 0.75,
                    damping: 7.5,
                    type: 'spring',
                  },
                }}
                position="absolute"
                inset={0}
                zIndex={1}
                src={Globe}
              />
              <Grid
                m="52px 19px 117px 31px"
                zIndex={2}
                templateColumns="repeat(2, minmax(0, 1fr))"
                templateRows="repeat(2, minmax(0, 1fr))"
                w="100%"
              >
                {globeBubbleData.map(
                  (
                    { data, translationLabel, alignSelf, justifySelf },
                    index,
                  ) => (
                    <Flex
                      as={motion.div}
                      alignSelf={alignSelf}
                      justifySelf={justifySelf}
                      initial={{
                        opacity: 0,
                        y: 20,
                      }}
                      exit={{
                        opacity: 0,
                        transition: {
                          ease: 'easeOut',
                          duration: 0.2,
                        },
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          delay: index * 0.1 + 0.5,
                          duration: 0.4,
                          bounce: 0.75,
                          damping: 7.5,
                          type: 'spring',
                        },
                      }}
                      whileHover={{
                        scale: 1.05,
                        transition: {
                          ease: 'backOut',
                        },
                      }}
                      bg="white"
                      borderColor="teal.06"
                      borderRadius="1rem"
                      borderStyle="solid"
                      borderWidth={1}
                      direction="column"
                      h="min-content"
                      key={translationLabel}
                      maxW="182px"
                      p="0.75rem 0.875rem"
                      w="max-content"
                    >
                      <Text
                        color="teal.05"
                        fontSize="2rem"
                        fontFamily="Poppins"
                        fontWeight="bold"
                        letterSpacing="-1.6px"
                        lineHeight={1}
                        userSelect="none"
                      >
                        {data}
                      </Text>
                      <Text
                        color="gray.secondary"
                        fontFamily="Poppins"
                        fontSize="0.625rem"
                        lineHeight={1.2}
                        letterSpacing="normal"
                        userSelect="none"
                      >
                        {t(translationLabel)}
                      </Text>
                    </Flex>
                  ),
                )}
              </Grid>
            </Show>
          </Flex>
        )}
        {animationLayout === 'reset' && (
          <Flex
            key="reset"
            animate={{ opacity: 1 }}
            as={motion.div}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            position="fixed"
            inset={0}
            pointerEvents="none"
            zIndex={1}
          >
            <Image
              position="fixed"
              left={0}
              bottom={0}
              top={0}
              src={RadialDots1X}
              srcSet={`${RadialDots3X} 3x, ${RadialDots2X} 2x, ${RadialDots1X} 1x`}
            />
            <Image
              position="fixed"
              right={0}
              bottom={0}
              top={0}
              transform="scaleX(-1)"
              src={RadialDots1X}
              srcSet={`${RadialDots3X} 3x, ${RadialDots2X} 2x, ${RadialDots1X} 1x`}
            />
          </Flex>
        )}
      </AnimatePresence>
      <Flex
        bg="white"
        boxShadow={{
          base: 'none',
          [defaultBreakpoint]: '0 6px 20px 4px rgba(228, 228, 227, 0.5)',
        }}
        borderRadius={{ base: 0, [defaultBreakpoint]: 8 }}
        maxW={{ base: 'unset', [defaultBreakpoint]: '508px' }}
        w="100%"
        zIndex={1}
      >
        {children}
      </Flex>
      <LanguageSwitcher />
      <AnimatePresence exitBeforeEnter>
        {animationLayout === 'signup' && (
          <Flex
            key="signup"
            animate={{ opacity: 1 }}
            as={motion.div}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            pointerEvents={{ base: 'none', [defaultBreakpoint]: 'auto' }}
          >
            <Show above={defaultBreakpoint}>
              <Image
                position="fixed"
                right={0}
                bottom={0}
                src={Evan1X}
                srcSet={`${Evan3X} 3x, ${Evan2X} 2x, ${Evan1X} 1x`}
                zIndex={2}
                w="min(480px, 33.33vw)"
                pointerEvents="none"
              />
              <AnimatePresence exitBeforeEnter>
                {RightAdornment && (
                  <Flex
                    as={motion.div}
                    animate={{
                      opacity: 1,
                      transition: {
                        duration: 0.25,
                      },
                    }}
                    exit={{
                      opacity: 0,
                      transition: {
                        duration: 0.25,
                      },
                    }}
                    initial={{ opacity: 0 }}
                    key={RightAdornment.displayName + String(queryParams.get('sso') === 'link')}
                    position="fixed"
                    right={0}
                    top={0}
                    bottom={0}
                    zIndex={3}
                  >
                    <RightAdornment />
                  </Flex>
                )}
              </AnimatePresence>
            </Show>
          </Flex>
        )}
      </AnimatePresence>
    </Flex>
  );
}

AuthLayout.defaultProps = {
  RightAdornment: undefined,
  animationLayout: 'signup',
};

AuthLayout.whyDidYouRender = true;
